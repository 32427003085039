export default function deleteAddonCategoryReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { type, processing, success, error, id } = action;
    switch (action.type)
    {
        case 'DELETE_ADDON_CATEGORY_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'DELETE_ADDON_CATEGORY_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'DELETE_ADDON_CATEGORY_PROCESSING':
            return { ...state, ...{ processing, id  }};

        default: return state;
    }
}