import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../common/table/Table";
import AddonsListItem from "./AddonListItem";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import getAddons from "../../../../ajax/actions/addon/addons_get";
import {setIsList, setPaginationData} from "../../branches/specific/BranchInfo-Action";
import {toggleAddonListFilter} from "./AddonList-Action";
import TableDataLoader from "../../../common/loaders/TableDataLoader";
import NoDataAvailable from "../../../common/NotFound/NoDataAvailable";
import AddonFilter from "./AddonFilter";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const Addons = () => {
    let [currency, setCurrency] = useState(``);
    let [branchID, setBranchID] = useState(``);
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {getRestaurantBranch, addonList, addonsGet, addonDelete} = reduxState;
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Addons`);
    useEffect(() => {
        let details = {
            title: 'Addons',
            icon: 'addons',
            action: true,
            filter:true,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch(setIsList(true));
    }, [])

    useEffect(() => {
        if (getRestaurantBranch.success) {
            let data = getRestaurantBranch?.success?.data?.data;
            setCurrency(data.currency);
            setBranchID(data.id);
            getRestaurantBranch.success && dispatch(getAddons());
        }
    }, [getRestaurantBranch.success])

    useEffect(() => {
        dispatch(setPaginationData(addonsGet, toggleAddonListFilter));
    }, [addonsGet.success])


    let list = addonsGet?.success?.data?.data;

    const {fields, params} = addonList.data;

    return (
        <>
            <div className="table-block table-responsive pl-50">
                <Table reducer={addonsGet}
                       action={toggleAddonListFilter}
                       params={params}
                       columns={fields}>
                    <tbody>
                    {addonsGet.processing ? <TableDataLoader colspan={5}/> :
                        list && list.length ? list.map(addon => {
                                return (
                                    <AddonsListItem key={addon.id}
                                                    addon={addon}
                                                    branchID={branchID}
                                                    url={url}
                                                    reducer={addonDelete}
                                                    currency={currency}/>
                                )
                            }
                        ) : <NoDataAvailable colspan={5}/>}
                    </tbody>
                </Table>
            </div>
            <AddonFilter/>
        </>
    )
}

export default memo(Addons);