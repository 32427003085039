import { isString } from './DataUtils'

export const slugify = (text) => {
  if ( text ) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
};

export const unslufigy = string =>
{
  if (string.includes(`-`)) {
    let part = string.split(`-`);
    let str = '';
    part.map(p => str += `${ucFirst(p)} `);
    return str.trim();
  }
  else if (string.includes(`_`)) {
    let part = string.split(`_`);
    let str = '';
    part.map(p => str += `${ucFirst(p)} `);
    return str.trim();
  }
  return ucFirst( string );
}

export const ucFirst = (string) =>
{
  if ( string && isString( string ) ) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
};

export const ucFirstAll = ( string, separator ) =>
{
  if ( string && isString( string )) {
    let part = string.trim().split(separator || ` `);
    if ( part.length === 1) return ucFirst( string );
    let str = ``;
    part.map( p => str += `${ ucFirst( p ) } `);
    return str.trim();
  }
};

export const names = (...classNames) =>
{
  const className = classNames.reduce(( _this, _that ) => `${ _this ||  '' } ${ _that || '' }`);
  return className || ''
}


export const updateOrCreate = ( value ) => {
  return value ? `update` : `create`;
}
export const equalWhenStringified = (data1, data2) => {
  return JSON.stringify(data1) === JSON.stringify(data2)
}

export const isValidEmail = (string) =>
{
  return string && string.length > 5 && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
};

export const isValidDomain = string =>
{
  return string.length > 5 && string.includes(`.`)
}

export const hasOnlyDigits = (string) =>
{
  return /^\d+$/.test(string);
};

export const hasOnlyDigitsWithDecimal = (string) =>
{
  return /^\d+(\.\d{1,2})?$/.test(string);
};