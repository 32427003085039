import {currentMonth, currentYear, quarterHr24TimeTable} from "../../../../utils/DateTimeUtils";

const DefaultScheduleListState = {
    params: {
        hour_mode: 'quarter',
        branch_id:``,
        year:currentYear(),
        month:currentMonth() <= 9 ? '0'+currentMonth() : currentMonth(),
        schedule:[],
        hourOption:quarterHr24TimeTable(),
        filterData:``,
        day:``,
        showInfoModal:false
    },
    schedule_filter_open:false,
}

export const ScheduleListState = {data: DefaultScheduleListState}