import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_RESERVATIONS_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_RESERVATIONS_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_RESERVATIONS_PROCESSING', processing }
}


function getReservations(params ) {

    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params: {
                limit: params.limit || 100,
                page:  params.page  || 1,
                user_id: params.user_id || null,
                sort_by:  params.sort_by || 'desc',
                // country_id: params.country_id || ``,
                // city_id: params.city_id || ``,
                // end_date: params.end_date || null,
                // admin_id: params.admin_id || null,
                branch_id: params.branch_id || null,
                // start_date: params.start_date || null,
                // device_type: params.device_type || null,
                // sort_column: params.sort_column || `id`,
                // order_status: params.order_status || null,
                // restaurant_id: params.restaurant_id || null,
                reservation_status: params.reservation_status || null,
                // payment_status: params.payment_status || null,
                customer_name:  params.customer_name  || null,
                // search:  params.search  || null,
                // payment_method: params.payment_method || null,
                // phone: params.phone || null,
                reserved_by: params.reserved_by || null
            }
        };
        if( !params.city_id ) {
            delete params.city_id
        }

       axios.get( apiBaseUrl(`reservation/v1/reservation/admin/index`), config)
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                dispatch(_processing(false));

            });
    }
}

export default getReservations;
