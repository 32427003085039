import React, { useEffect, memo, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../common/table/Table";
import FoodReviewListItem from "./FoodReviewListItem";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import {setIsList, setPaginationData} from "../../branches/specific/BranchInfo-Action";
import {toggleFoodListFilter} from "./FoodList-Action";
import getFoodsReview from "../../../../ajax/actions/foods/foods_review_get";
import TableDataLoader from "../../../common/loaders/TableDataLoader";
import NoDataAvailable from "../../../common/NotFound/NoDataAvailable";
import FoodFilter from "./FoodFilter";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const FoodsInReview = () => {
    let [ currency, setCurrency ] = useState(``);
    let [branchID, setBranchID] = useState(``);
    let [inventory, setInventory] = useState(``);
    let [breakfast, setBreakFast] = useState(``);
    // let [initialData, setInitialData] = useState(false);
    // let [list, setList] = useState(false);
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { getRestaurantBranch, foodList, foodsReviewGet, foodDelete } = reduxState;
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Foods`);

    useEffect(() => {
        let details = {
            title: 'Foods',
            icon: 'foods',
            action: false,
            filter: false,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch( setIsList( true ) );
    }, [])

    useEffect(() => {
        if( getRestaurantBranch.success ) {
            let data = getRestaurantBranch?.success?.data?.data;
            // console.log(` <| getRestaurantBRanch |> `, getRestaurantBranch )
            setCurrency(data.currency);
            setBranchID(data.id);
            setInventory(data.inventory);
            setBreakFast(data.breakfast_menu);
            dispatch(getFoodsReview());
        }
    }, [getRestaurantBranch.success])

    useEffect(() => {
        dispatch(setPaginationData( foodsReviewGet, toggleFoodListFilter ));
        // foodsGet.success && setInitialData(true);
    }, [foodsReviewGet])

    let list = foodsReviewGet?.success?.data?.data;

    const { fields, params } = foodList.data;

    let _fields = fields;
    if( inventory === 0 ) {
        _fields = _fields.filter( item => item.field !== 'qty');
    }

    // exclude prep time and image columns
    _fields = _fields.filter( item => ['image', 'preparation_time'].findIndex(x => x == item.field) == -1 );

    return (
        <>
            <div className="table-block table-responsive pl-50">
                <Table reducer={foodsReviewGet}
                       action={toggleFoodListFilter}
                       params={params}
                       columns={_fields}>
                    <tbody>

                    { foodsReviewGet.processing ? <TableDataLoader colspan={9} /> :
                        list && list.length ? list.map(food => {
                            return (
                                <FoodReviewListItem key={food.id}
                                               food={food}
                                               branchID={branchID}
                                               url={url}
                                               inventory={inventory}
                                               reducer={foodDelete}
                                               currency={ currency } />
                            )
                        }
                    ) : <NoDataAvailable colspan={9} /> }
                    </tbody>
                </Table>
            </div>
            <FoodFilter breakfast={breakfast}/>
        </>
    )
}

export default memo(FoodsInReview);