import React, {useEffect} from 'react';
import Modal from "react-modal"
import {useDispatch, useSelector} from "react-redux";
import Tags from "../../../../common/Tags/Tags";
import useAppState from "../../../../../hooks/useAppState";
import {setTimeslotModalClose, setTimeslotModalOpen} from "./TimeslotDay-Action";
import * as Yup from "yup";
import {useFormik} from "formik";
import NumberField from "../../../../common/form/NumberField";
import SelectField from "../../../../common/form/SelectField";
import {activeStatus, timeslotStatus} from "../../../../../utils/StaticDataUtils";
import GrowingLoader from "../../../../common/loaders/GrowingLoader";
import DateField from "../../../../common/form/DateField";
import createReservationDayTimeslot, {createReservationDayTimeslotReset} from "../../../../../ajax/actions/reservations/reservation_day_timeslot_post";
import updateReservationDayTimeslot, {updateReservationDayTimeslotReset} from "../../../../../ajax/actions/reservations/reservation_day_timeslot_put";
import getReservationTimeslots from "../../../../../ajax/actions/reservations/reservation_timeslots_get";
import getReservationTimeslotDay from "../../../../../ajax/actions/reservations/reservation_timeslot_day_get";
import {ucFirst} from "../../../../../utils/StringUtils";
import {time12Options, time24Options, timeMinsOptions} from "../../../../../utils/DateTimeUtils";
import getReservationTimeslotHours from "../../../../../ajax/actions/reservations/reservation_timeslot_hours_get";

const customStyles = {
    content: {
        width: `600px`,
        border: 'none',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};

const TimeslotDayForm = (props) => {
    let dispatch = useDispatch();

    const timeslotDay = useAppState(s => s.timeslotDay)
    const reservationDayTimeslotCreate = useAppState(s => s.reservationDayTimeslotCreate)
    const reservationDayTimeslotUpdate = useAppState(s => s.reservationDayTimeslotUpdate)
    const reservationTimeslotHoursGet = useAppState(s => s.reservationTimeslotHoursGet)
    let {timeslot_modal_open, mode, updateItem, hour_min_options} = timeslotDay && timeslotDay.data;

    function modalOpenClose() {
        dispatch(setTimeslotModalClose(false))
    }


    useEffect(() => {
        if (mode === 'update' || mode === 'view') {
            setFieldValue(`branch_id`, props.current_day.restaurant_branch_id);
            setFieldValue(`reservation_day_id`, props.current_day.id);
            setFieldValue(`time`, updateItem.time);
            // setFieldValue( `hour`, updateItem.time.split(':')[0] );
            // setFieldValue( `minute`, updateItem.time.split(':')[1] );
            setFieldValue(`pax`, updateItem.pax);
            setFieldValue(`blocked_slot`, updateItem.blocked_slot);
            setFieldValue(`base_price`, updateItem.base_price);
            setFieldValue(`currency`, props.currency);
            setFieldValue(`status`, updateItem.status);
        } else {
            resetForm()
        }
    }, [mode, updateItem])

    const validationSchema = Yup.object().shape({
        time: Yup.string().required('is required'),
        pax: Yup.number(` be number`).required('is required'),
        // status: Yup.bool().required('is required'),
        blocked_slot: Yup.number().typeError(' must be a number'),
        base_price: Yup.number().required(` is required`).typeError(' must be a number'),
    });

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: {
                branch_id: props.current_day.restaurant_branch_id, reservation_day_id: props.current_day.id, time: ``,
                pax: ``, blocked_slot: ``, base_price: ``, currency: props.currency, status: 0
            },
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                if (props.mode === 'add') {
                    dispatch(createReservationDayTimeslot(values))
                } else {
                    // console.log(` <|values  |> `, values)
                    dispatch(updateReservationDayTimeslot(values, updateItem.id))
                }

            }
        })

    const handleTimeChange = (e) => {
        let {value, name} = e.target;
        if( name === 'hour' || name === 'minute' ) {
            let _h = (name  === 'hour') ? value : values.hour
            let _m = (name  === 'minute') ? value : values.minute
            let time = _h +':'+ _m +':'+'00';
            setFieldValue( 'time', time );
            setFieldValue( name, value );
        }
    }

    function resetForm() {
        setFieldValue(`branch_id`, props.current_day.restaurant_branch_id);
        setFieldValue(`reservation_day_id`, props.current_day.id);
        setFieldValue(`time`, ``);
        // setFieldValue(`hour`, ``);
        // setFieldValue(`minute`, ``);
        setFieldValue(`pax`, ``);
        setFieldValue(`blocked_slot`, ``);
        setFieldValue(`base_price`, ``);
        setFieldValue(`currency`, props.currency);
        setFieldValue(`status`, 0);
    }

    useEffect( () => {
        dispatch( getReservationTimeslotHours( values.branch_id, values.reservation_day_id ) )
    },[] )

    useEffect(() => {
        if (reservationDayTimeslotCreate.success) {
            dispatch(createReservationDayTimeslotReset())
            dispatch(setTimeslotModalClose(false));
            values.branch_id && dispatch(getReservationTimeslots(values.branch_id))
            values.branch_id && dispatch(getReservationTimeslotDay(values.branch_id, values.reservation_day_id))
            resetForm();
        }
    }, [reservationDayTimeslotCreate.success])

    useEffect(() => {
        if (reservationDayTimeslotUpdate.success) {
            dispatch(updateReservationDayTimeslotReset())
            dispatch(setTimeslotModalClose(false));
            values.branch_id && dispatch(getReservationTimeslots(values.branch_id))
            values.branch_id && dispatch(getReservationTimeslotDay(values.branch_id, values.reservation_day_id))
            resetForm();
        }
    }, [reservationDayTimeslotUpdate.success])

    let processing = reservationDayTimeslotCreate.processing || reservationDayTimeslotUpdate.processing;
    let disabled = props.mode === 'view' || props.mode === 'update';
    let has_time_error = (errors && errors.time) && (touched && touched.time);

    // console.log(` <| values |> `, values)

    return (
        <Modal
            isOpen={timeslot_modal_open}
            style={customStyles}
            onRequestClose={modalOpenClose}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ucFirst(props.mode)} Time</h5>
                        <button type="button" className="close" onClick={() => modalOpenClose()}>
                            <span aria-hidden="true" className="icon-close"/>
                        </button>
                    </div>
                    <div className="modal-body pb-0">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">

                                <>

                                    {/*<DateField
                                        type="time"
                                        name="time"
                                        placeholder="Time"
                                        onChange={handleChange}
                                        value={values.time}
                                        disabled={disabled}
                                        error={errors && errors.time}
                                        touched={touched.time}
                                    />*/}
                                    <div className="col-sm-12">
                                        <SelectField
                                            name="time"
                                            options={hour_min_options}
                                            onChange={handleChange}
                                            label="Time"
                                            value={values.time}
                                            disabled={disabled}

                                            _label="time_formatted"
                                            _value="time"

                                            hasCase={true}

                                            error={errors && errors.time}
                                            touched={touched.time}
                                        />
                                    </div>
                                    {/*
                                    <div className="col-sm-6">
                                        <SelectField
                                            name="hour"
                                            options={timeMinsOptions()}
                                            onChange={handleTimeChange}
                                            label="Hour"
                                            value={values.hour}
                                            disabled={disabled}
                                        />
                                    </div><div className="col-sm-6">
                                        <SelectField
                                            name="minute"
                                            options={timeMinsOptions()}
                                            onChange={handleTimeChange}
                                            label="Minute"
                                            value={values.minute}
                                            disabled={disabled}
                                        />
                                    </div>*/}
                                    {/*<div className={`timeslot-day-form-error ${has_time_error ? "has-error" : null }`}>
                                        <span className={`label-text`}>{has_time_error ? 'Time ' + errors.time : null }</span>
                                    </div>*/}
                                </>
                                <div className="col-sm-12">
                                    <NumberField
                                        name="pax"
                                        placeholder="Pax"
                                        onChange={handleChange}
                                        value={values.pax}
                                        disabled={disabled}
                                        error={errors && errors.pax}
                                        touched={touched.pax}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <NumberField
                                        name="blocked_slot"
                                        placeholder="Blocked Slot"
                                        onChange={handleChange}
                                        value={values.blocked_slot}
                                        disabled={props.mode === 'view'}
                                        error={errors && errors.blocked_slot}
                                        touched={touched.blocked_slot}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <NumberField
                                        name="base_price"
                                        placeholder="Base Price"
                                        onChange={handleChange}
                                        value={values.base_price}
                                        disabled={props.mode === 'view'}
                                        error={errors && errors.base_price}
                                        touched={touched.base_price}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <SelectField
                                        name="status"
                                        options={timeslotStatus}
                                        onChange={handleChange}
                                        label="Status"
                                        value={values.status}
                                        disabled={disabled}
                                        _label="name"
                                        _value="value"

                                        error={errors && errors.status}
                                        touched={touched.status}
                                    />
                                </div>

                                {props.mode !== 'view' ?
                                <div className="col-sm-12">
                                    <button type="submit"
                                            className="btn-theme mb-5">{processing ?
                                        <GrowingLoader/> : `${props.mode === 'update' ? "Update" : "Create"}`}</button>
                                </div>
                                    : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TimeslotDayForm;