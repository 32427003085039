import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import {useParams,} from "react-router";
import {useFormik} from "formik";
import * as Yup from "yup";
import SelectField from "../../../common/form/SelectField";
import {activeStatus} from "../../../../utils/StaticDataUtils";
import {ucFirst} from "../../../../utils/StringUtils";
import createAddonCategory  from "../../../../ajax/actions/addon-categories/addon_category_post";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import updateAddonCategory from "../../../../ajax/actions/addon-categories/addon_category_put";
import AddonCategoryWorker from "./AddonCategoryWorker";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";
import { ArabicTranslate, EnglishTranslate } from '../../../../utils/GeneralUtils';
// import NumberField from "../../../common/form/NumberField";

const AddonCategoryForm = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { addonCategoryCreate,  addonCategoryForm, addonCategoryUpdate } = reduxState;
    let { id } = useParams();
    useTitle(Title.name + ` Addons Category`);
    let { min_addon, trans } = addonCategoryForm.data;

    let fields = { ...  addonCategoryForm.data }

    const { handleChange, handleSubmit, errors, touched, values, setFieldValue } = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: Yup.object().shape({
                max_addon: Yup.number().typeError(' must be a number').required('is required').moreThan(min_addon, ' must be greater'),
                min_addon: Yup.number().typeError(' must be a number').required('is required'),
                status: Yup.bool().required('is required'),
                translation: Yup.object().shape({
                    en:Yup.object().shape({
                        name:Yup.string().required( ` is required`),
                    }),
                    ar:Yup.object().shape({
                        name:Yup.string().required( ` is required`),
                    })
                })
            }),

            onSubmit: (values) => {
                if( id  ) {
                    dispatch( updateAddonCategory( values, id ) )
                } else {
                    dispatch( createAddonCategory( values ) )
                }
            }
        })

    const onAddonCategoryTranslationFormChange = e => {
        let { name, value } = e.target;
        let text = '';
        if (e.target.id === 'ar') {
            if (ArabicTranslate(e.target.value) === undefined) {
                return
            } else {
                text = ArabicTranslate(e.target.value)
            }
        } else {
            if (EnglishTranslate(e.target.value) === undefined) {
                return
            } else {
                
                text = EnglishTranslate(e.target.value);
            }
        }
        setFieldValue(name, text) //setFieldValue(name, value)
    }

    let processing = addonCategoryCreate.processing || addonCategoryUpdate.processing;
    let key_ctr = 0;
    return (
        <div className="plr-35 w-1000">
            <AddonCategoryWorker/>
            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                    { trans && trans.map( (t) => {
                        key_ctr++;
                         return (<div className="col-sm-6" key={key_ctr}>
                             <TextField
                                 name={`translation.${t.lang_code}.name`}
                                 id={t.lang_code}
                                 label={`Addon Category Name (${ucFirst(t.lang_code)})`}
                                 placeholder={`Enter Addon Category Name ${t.lang_code}`}
                                 onChange={onAddonCategoryTranslationFormChange}
                                 className={ t.lang_code === 'ar' ? 'text-translation' : ''}
                                 value={ values.translation && values.translation[t.lang_code] && values.translation[t.lang_code].name }
                                 error = {errors && errors.translation && errors.translation[t.lang_code] && errors.translation[t.lang_code].name}
                                 touched={ touched && touched.translation && touched.translation[t.lang_code] && touched.translation[t.lang_code].name }
                             />
                        </div>)
                    } )}

                    <div className="col-sm-6">
                        <TextField
                            name="min_addon"
                            placeholder="Min Addon"
                            onChange={handleChange}
                            value={ values.min_addon }
                            error = {errors && errors.min_addon}
                            touched={ touched.min_addon }
                        />

                    </div>
                    <div className="col-sm-6">
                        <TextField
                            name="max_addon"
                            placeholder="Max Addon"
                            onChange={handleChange}
                            value={ values.max_addon }
                            error = {errors && errors.max_addon}
                            touched={ touched.max_addon }
                        />
                    </div>
                    <div className="col-sm-12">
                        <SelectField
                                name="status"
                                options={activeStatus}
                                onChange={handleChange}
                                label="Status"
                                value={values.status}
                                _label="name"
                                _value="value"

                                error = {errors && errors.status}
                                touched={ touched.status }
                        />
                    </div>
                </div>
                <button type="submit"
                        className="btn-theme mt-10">{ processing ? <GrowingLoader /> : `${id ? "Update" : "Create"}` }</button>
            </form>
        </div>
)
}
export default AddonCategoryForm;