import {toggleURLQuery} from '../../../../../utils/URLUtils'
import getPartnerIntegrations from "../../../../../ajax/actions/partner/integration/partner_integrations_get";

export function togglePartnerIntegrationListFilter(e, history,) {
    return function ( dispatch, ) {
        let { name, value, sort } = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-PARTNER-INTEGRATION-LIST-FILTER', name, value, history});
        dispatch( getPartnerIntegrations( ));
    }
}

export function togglePartnerIntegrationForm() {
    return function ( dispatch, ) {
        dispatch({type: 'TOGGLE-PARTNER-INTEGRATION-MODAL',});
    }
}

export function onFormModeChangeUpdate( mode ) {
    return function ( dispatch, ) {
        dispatch({type: 'FORM-MODE-CHANGE-UPDATE', mode});
    }
}