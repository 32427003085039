import axios from 'axios/index';
import { apiBaseUrl } from '../../../../utils/URLUtils'
import { loginToken } from '../../../../utils/LocalStorageUtils'
import { handleError } from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'GET_RESTAURANT_CATEGOPRIES_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_RESTAURANT_CATEGOPRIES_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_RESTAURANT_CATEGOPRIES_PROCESSING', processing };
}

function getRestaurantCategories( restaurantID ) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            },
        }
        axios.get(apiBaseUrl(`vendor/v1/vendor/restaurant/${restaurantID}/parent/categories`), config)
            .then(function (res) {
                dispatch(_success(res));
            }).catch(function (error) {
                handleError(error);
                dispatch(_error(error));
            });
    }
}

export default getRestaurantCategories;