import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'UPDATE_RESERVATION_DAY_TIMESLOT_STATUS_SUCCESS', success }
}

function _error(error) {
    return { type: 'UPDATE_RESERVATION_DAY_TIMESLOT_STATUS_ERROR', error }
}

function _processing(processing) {
    return { type: 'UPDATE_RESERVATION_DAY_TIMESLOT_STATUS_PROCESSING', processing }
}


function updateReservationDayTimeslotStatus(data, id ) {

    let restaurantId = data.branch_id;
    let day_id = data.reservation_day_id;
    return dispatch => {
        dispatch(_processing(true));
        dispatch(_success(false));
        dispatch(_error(false));


        const getToken = localStorage.getItem('Bearer');

       axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurantId}/day/${day_id}/timeslot/${id}/status`),
            method: "put",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(data)),
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                handleSuccess( res );
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                handleError( error )
                dispatch(_processing(false));

            });
    }
}

export default updateReservationDayTimeslotStatus;
