import {toggleURLQuery} from '../../../utils/URLUtils'
import getRestaurantBranchList from "../../../ajax/actions/branch/get_restaurant_branch_list";

export function toggleBranchListFilter(e, history) {
    return function ( dispatch, getState ) {
        let { userDetails } = getState();
        let restaurantID = userDetails?.success?.restaurant_id;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-BRANCH-LIST-FILTER', name, value, history});
        dispatch( getRestaurantBranchList( restaurantID ));
    }
}