import axios from 'axios';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import {getCurrentDate} from "../../../utils/DateTimeUtils";
import { AppSource } from '../../../Config';

function _success(success)
{
  return { type: 'GET_RESTAURANT_REVIEW_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_RESTAURANT_REVIEW_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_RESTAURANT_REVIEW_PROCESSING', processing };
}

function getRestaurantReview( id )
{
  return ( dispatch, ) =>
  {
    dispatch(_error(null));
    dispatch(_success(null));
    dispatch(_processing(true));

    let config = {
      headers: { 'Authorization': 'Bearer ' + loginToken() },
      'x-app-source': AppSource.appSource

    };
    axios.get(apiBaseUrl(`vendor/v1/vendor/reviews/${id}`), config)
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));

      }).catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
      });
  }
}


export default getRestaurantReview;