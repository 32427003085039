import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const ReservationRestaurantDetails = ({reservation, processing}) => {
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Restaurant Detail</h6>
            { processing ? <GrowingLoader/> :
                <>
                <div className="single-info">
                    <div className="info-title">Restaurant Name</div>
                    <div className="info-detail">{reservation && reservation.restaurant_name}</div>
                </div>
                <div className="single-info">
                    <div className="info-title">Address</div>
                    <div className="info-detail">{reservation && reservation.city_name}</div>
                </div>
                <div className="single-info">
                    <div className="info-title">Mobile Number</div>
                    <div className="info-detail">{reservation && reservation.mobile_no}</div>
                </div>
                </>
            }
        </div>
    )
}

export default ReservationRestaurantDetails;