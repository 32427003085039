import axios from 'axios';
import {apiBaseUrl} from "../../../../utils/URLUtils";
import { AppSource } from '../../../../Config';

function _success(success) {
    return { type: 'GET_INTERNAL_RESERVATION_DATE_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_INTERNAL_RESERVATION_DATE_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_INTERNAL_RESERVATION_DATE_PROCESSING', processing }
}


function getInternalReservationDate( branchID ) {

    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params:{
                device_type:'web'
            }
        };

       axios.get( apiBaseUrl(`reservation/v1/public/branch/${branchID}`), config)
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                dispatch(_processing(false));

            });

    }
}

export default getInternalReservationDate;
