import React from 'react'
import Div from "../Div/Div";

const GrowingLoader = ({ red, blue, green }) =>
{
  const color = `text-` + red ? `danger`: blue ? `primary` : green ? `success` : `danger`
  return (

    <Div className="GrowingLoader">
      <Div className={[`spinner-grow spinner-grow-sm`, color ]} >
        <span className="sr-only">Loading...</span>
      </Div>
    </Div>

  )
};

export default GrowingLoader