import React, { memo } from "react";
import SearchBar from "../../layout/Header/SearchBar";
import THead from "./THead";
import TBody from "./TBody";

const Table = ( props ) =>
{
   let { columns, action, reducer, buttons, children, params } = props
  return (

      <table className="table table-striped  ">

        <THead columns={ columns }
               params = {params }
               action={ action }  />
          {children}
        {/*<TBody columns={ columns }
               reducer={ reducer }
               buttons={ buttons }/>*/}

      </table>
  )
};

export default Table