import axios from 'axios'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
  return { type: 'GET_RESTAURANT_BRANCH_SUCCESS', success }
}

function _error(error) {
  return { type: 'GET_RESTAURANT_BRANCH_ERROR', error }
}

function _processing(processing) {
  return { type: 'GET_RESTAURANT_BRANCH_PROCESSING', processing }
}
//parentID
function getBranch(id, role=null) {
  return (dispatch, getState ) => {
    let {  main } = getState();
    let { user_role } = main.data;
    dispatch(_processing(true))
      if( !role ) {
          role = user_role;
      }
    let url = `vendor/v1/vendor/branch/${id}/detail`;
    if( role === 'restaurant-admin' ) {
      url = `vendor/v1/vendor/branch/${id}/detail`
    } else if( role === `branch-admin` ) {
      url = `vendor/v1/vendor/branch/detail`
    }
      // console.log(` <| user_role |> `, user_role, role )

      axios({
      // url: apiBaseUrl(`restaurant/v1/restaurant/${parentID}/branch/${id}`),
      url: apiBaseUrl(url),
      method: 'get',
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
         'x-app-source': AppSource.appSource
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))

      }).catch(function(error) {
      dispatch(_error(error))
      dispatch(_processing(false))
      // handleError(error)

    })
  }
}


export default getBranch