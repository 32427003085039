import React, {useEffect } from 'react';
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useDispatch} from "react-redux";
import useAppState from "../../../../hooks/useAppState";
import {getOnlyDayFromDate, shortMonthsList} from "../../../../utils/DateTimeUtils";
import {fromRecord, inRecord} from "../../../../utils/DataUtils";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import CalanderInfo from "./CalanderInfo"
import "./Schedule.scss";
import {
    setBranchResrvationScheduleBranchID,
    setCurrentScheduleParams, setScheduleDateFilterChange,
    setScheduleFilterData,
    toggleScheduleFilterOpen
} from "./Schedule-Action";
import ScheduleFilter from "./ScheduleFilter";
import DateField from "../../../common/form/DateField";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const Schedule = () => {
    const dispatch = useDispatch();
    const reservationScheduleGet = useAppState(s => s.reservationScheduleGet);
    const getRestaurantBranch = useAppState(s => s.getRestaurantBranch);
    const scheduleReducer = useAppState(s => s.scheduleReducer);
    let {params} = scheduleReducer && scheduleReducer.data;
    let {schedule, hourOption, filterData, showInfoModal} = params;

    useEffect(() => {
        let details = {
            title: `Reservation Schedule`,
            icon: 'order',
            filter: true,
            action: false,
        }
        dispatch(setIsList(false));
        dispatch(setBranchHeaderDetails(details));

    }, [])

    useTitle(Title.name + ` Reservation Schedule`);

    useEffect(() => {
        if (getRestaurantBranch.success) {
            let branchID = getRestaurantBranch.success && getRestaurantBranch.success.data.data.id;
            dispatch(setBranchResrvationScheduleBranchID(branchID));
        }
    }, [getRestaurantBranch.success])

    const showInfo = (date, data) => {
        data.date = date;
        dispatch(setScheduleFilterData(data));
    }

    const scheduleFilterCurrentDay = () => {
        dispatch( setCurrentScheduleParams() );
    }

    const scheduleDateFilterChange = (e) => {
        dispatch( setScheduleDateFilterChange(e.target.name, e.target.value ) );
    }

    let date_value = params.year+'-'+params.month
    // console.log(` <| date_value |> `,date_value )
    return (
        <>
            <ScheduleFilter/>
            <div className="main-calander-container-wrapper">
                <div className="schedule-date-picker">
                    <DateField name="schedule_date" noLabel
                                type="month"
                               value={date_value}
                               onChange={scheduleDateFilterChange}/>
                    {/*<div>Current Year: {params.year} </div>
                    <span className="ml-5">Current Month: {shortMonthsList[params.month-1]}</span>*/}
                    <div className="d-inline-block pull-right div-today-button">
                        <a href="javascript:void(0)" className="btn-theme btn-outline ml-5 pull-right"
                           onClick={scheduleFilterCurrentDay}>Today</a>
                    </div>
                </div>
                <div className="main-calander-container" style={{marginTop: "10px"}}>
                    {reservationScheduleGet.processing ? <div className="text-center"><GrowingLoader/></div> :
                        !hourOption.length ? <p>No time available for the schedule.</p> :
                        schedule && schedule.length ? schedule.map((d, key) => {
                            return (
                                d.day === 0 ?
                                    <div className="div_calander_days calander_header" key={d.day + key}>
                                        <div className="div_days"/>
                                        <div className="div_calander_hrs">
                                            {hourOption.map(hr => {
                                                return (
                                                    <div key={hr} className="calander_hr">
                                                        <strong>{hr}  </strong>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div> :
                                    <div key={d.date + key} className="div_calander_days">
                                        <div className="div_days">
                                            <p><strong>{getOnlyDayFromDate(d.date)} </strong></p>
                                            {d.day}
                                        </div>
                                        <div className="div_calander_hrs">
                                            {hourOption.map(hr => {
                                                let pax = 0;
                                                let reserved = 0;
                                                let filterTime = ``
                                                if (d.time.length) {
                                                    if (inRecord(d.time, 'time', '===', hr)) {
                                                        filterTime = fromRecord(d.time, 'time', '===', hr)
                                                        pax = filterTime.pax;
                                                        reserved = filterTime.reserved
                                                    }
                                                    /*if (inRecord(d.time, 'time', '===', hr.toString() + ':00')) {
                                                        filterTime = fromRecord(d.time, 'time', '===', hr.toString() + ':00')
                                                        pax = filterTime.pax;
                                                        reserved = filterTime.reserved
                                                    }*/
                                                }
                                                let _has_filter_data = filterData.pax === pax && filterData.date === d.date && filterData.time === filterTime.time
                                                return (
                                                    <div key={d.day + d.date + hr}
                                                         className={`calander-date-time `}
                                                         onClick={() => (filterTime && pax > 0) && showInfo(d.date, filterTime)}>

                                                        <div className={`calander_hr bg-pax-green ${filterTime ? "pointer" : "cursor-default"} ${ (_has_filter_data) ? "selected-calander-div" : "" }`}
                                                             >{pax}</div>

                                                        <div className={`calander_hr bg-reserved-pink ${_has_filter_data ? "pointer selected-calander-div" : `cursor-default`}`}
                                                        >{reserved} </div>

                                                        {(showInfoModal && _has_filter_data)
                                                            ? <CalanderInfo /> : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                            )
                        }) : <p>No Schedule Available.</p>
                    }
                </div>
            </div>

        </>
    )
}
export default Schedule;