import axios from 'axios';
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'UPDATE_TIMESLOT_STATUS_SUCCESS', success }
}

function _error(error) {
    return { type: 'UPDATE_TIMESLOT_STATUS_ERROR', error }
}

function _processing(processing) {
    return { type: 'UPDATE_TIMESLOT_STATUS_PROCESSING', processing }
}


function updateTimeslotStatus(data ) {

    let restaurantId = data.restaurant_id;
    let day_id = data.reservation_day_id;
    return dispatch => {

        // dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurantId}/day/${day_id}`),
            method: "put",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(data)),
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                handleSuccess( res )
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                handleError( error )
                dispatch(_processing(false));

            });
    }
}

export default updateTimeslotStatus;
