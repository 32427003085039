import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {setAddonCategory, setBranchDataAddonCategory, setDefaultAddonCategoryForm} from "./AddonCategoryForm-Actions";
import getAddonCategory, {getAddonCategoryReset} from "../../../../ajax/actions/addon-categories/addon_category_get";
import {createAddonCategoryReset} from "../../../../ajax/actions/addon-categories/addon_category_post";
import {urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import {updateAddonCategoryReset} from "../../../../ajax/actions/addon-categories/addon_category_put";
import {setIsList} from "../../branches/specific/BranchInfo-Action";

const AddonCategoryWorker = () =>
{
    let dispatch = useDispatch();
    const history = useHistory()
    const reduxState = useSelector(state => state);
    const { addonCategoryCreate,  branchInfoReducer, addonCategoryGet, addonCategoryUpdate } = reduxState;
    let { id } = useParams();

    useEffect(() => {
        let details = {
            title:`${id ? "" : "Create"} Addon Category`,
            icon: 'addon_cat',
            id:id || ``,
            action: false,
        }
        dispatch(setIsList(false));
        if( !id || id === 'undefined' ) {
            dispatch( setDefaultAddonCategoryForm() );
        }
        dispatch(setBranchHeaderDetails(details));

        return () => {
            dispatch(getAddonCategoryReset())
        }
    }, [])

    useEffect(() => {
        if( branchInfoReducer ) {
            dispatch( setBranchDataAddonCategory( branchInfoReducer ) );

            if( id ) {
                let branch_id = branchInfoReducer && branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
                branch_id && dispatch( getAddonCategory( branch_id, id ) )
            }
        }
    }, [branchInfoReducer]);

    useEffect(() => {
        if ( addonCategoryCreate && addonCategoryCreate.success ) {
            dispatch( createAddonCategoryReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ addonCategoryCreate ])

    useEffect(() => {
        if ( addonCategoryUpdate && addonCategoryUpdate.success ) {
            dispatch( updateAddonCategoryReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ addonCategoryUpdate ])

    useEffect(() => {
        addonCategoryGet.success && dispatch(setAddonCategory( addonCategoryGet.success ))
    },[addonCategoryGet])

    return ( null)
}
export default AddonCategoryWorker;