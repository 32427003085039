import { isObject } from './DataUtils'
import React from 'react';
import {names, ucFirst, unslufigy} from "./StringUtils";

export const setInitialFormData  = payload =>
{
  if ( payload && isObject( payload )) {
      Object.keys( payload ).map(
        key => setFieldValue( key, payload[key])
      )
  }
}

export const setFieldValue = (id, value) =>
{
  let el = document.getElementById( id );
  if ( el ) el.value = value
}

export const dateDifference = (string) =>
{
  let newDate = new Date(string);
  let inputDate = newDate.getFullYear();
  let currentDate = new Date();
  let currentDateYearFormat = currentDate.getFullYear();
  let dateDifference = currentDateYearFormat - inputDate;
  if(dateDifference >= '18'){
    return dateDifference;
  }
};


export const placeholders = ( placeholder, name, label ) => {
  if ( placeholder ) return placeholder;
  if ( label ) return label;
  else {
    if ( name ) {
      let _label = unslufigy( name );
      if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
      return _label;
    }
  }
}
export const resetForm = ( formID ) =>
{
  if ( document.getElementById( formID ))
    document.getElementById( formID ).reset()
}
export const labels = ( name, label, errors ) =>
{
  let errorsPresent = errors && errors.length;
  if ( label ) return (
      <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
        { label } { errorsPresent ? errors[0].message + '.' : null }
      </label>
  );
  else {
    if ( name ) {
      let _label = unslufigy( name );
      if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
      return (
          <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
            { _label } { errorsPresent ? errors[0].message + '.' : null }
          </label>
      )
    }
  }
}