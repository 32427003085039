import React from 'react'
import {names, ucFirst, unslufigy} from '../../../utils/StringUtils'
import Div from "../Div/Div";
import GrowingLoader from "../loaders/GrowingLoader";

const CheckBoxFieldSingle = (props) =>
{

  const {
    name, size, refer, value, icon, onChange, id, noLabel, labelIcon, label, touched, defaultValue,
    addonAfter, disabled, className, error, required, options, onKeyUp, autoFocus, processing,key, checked
  } = props;

  const hasError = error && touched;

  const Label = () => {
    if (label) return (
      <Div className={names(`label-text`, hasError && `has-error`)}>
        {label} {hasError ? error + '.' : null}
      </Div>
    );
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return (
          <Div className={names(`label-text`)}>
            {_label} {hasError ? error + '.' : null}
          </Div>
        )
      }
    }
    return null
  }

    return (

        <div className={names(hasError && `has-error`)}>
          <div className="row">
            {processing ? <GrowingLoader/> :

              <div className="col-md-6 col-sm-6" >
                <Div className={names(`form-check form-group`)}>
                  <input type="checkbox"
                         name={name}
                         className="form-check-input"
                         value={ value }
                         checked={checked }
                         onChange={onChange}
                         id={id }/>
                  <label className="form-check-label" htmlFor={id}>{label}</label>
                </Div>
              </div>
                  }
          </div>
        </div>

    )
}
export default CheckBoxFieldSingle;