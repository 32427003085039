import axios from 'axios/index';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'GET_ADDON_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_ADDON_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_ADDON_PROCESSING', processing };
}

function getAddon( branchId, id )
{
    return ( dispatch, getState ) =>
    {
        let { main } = getState();
        let { user_role } = main.data;

      dispatch(_processing(true));
      dispatch(_success(null));
      dispatch(_error(null));

        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${branchId}/addon/${id}`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/${id}`
        }

      let config = {
        headers: {
              'Authorization': 'Bearer ' + loginToken(),
             'x-app-source': AppSource.appSource
        },
      }
        // axios.get(apiBaseUrl(`restaurant/v1/admin/restaurant/${branchId}/addon/${id}`), config)
        axios.get(apiBaseUrl(url), config)
            .then(function(res){
                dispatch(_success(res));
            }).catch(function(error){
                handleError(error);
                dispatch(_error(error));
            });
    }
}
export function getAddonReset()
{
    return dispatch => dispatch({ type: `GET_ADDON_RESET` })
}

export default getAddon;