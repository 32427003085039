export const AccountFormState = {
    data: {
        payload:{
            first_name:``,
            last_name:``,
            middle_name:``,
            mobile:``,
            email:``,
            country:``,
            city:``,
            address_line_1:``,
            old_password: ``,
            confirm_password: ``,
            new_password: ``
        },
        changePasswordFlag:false
    },

}