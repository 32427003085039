import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../common/table/Table";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import {setIsList, setPaginationData} from "../../branches/specific/BranchInfo-Action";
import RestaurantHeader from "../../../layout/Header/RestaurantHeader";
import SalesReportFilter from "./SalesReportFilter";
import orderSvg from "../../../../assets/images/total_order.svg";
import sarSvg from "../../../../assets/images/revenue.svg";
import taxSvg from "../../../../assets/images/tax.svg";
import BarChart from "../../../common/Charts/BarChart";
import {objectIsEmpty} from "../../../../utils/GeneralUtils";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import MonthlySalesReport from "./MonthlySalesReport";

const BranchSalesReport = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {salesReportGet, salesReport} = reduxState;
    let {params} = salesReport && salesReport.data;
    let {url} = useRouteMatch();
    useEffect(() => {
        let details = {
            title: 'Sales Report',
            icon: 'report',
            action: false,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch(setIsList(false));
        dispatch(getSalesReport(params, 1))

    }, []);

    let data = salesReportGet && salesReportGet.success && salesReportGet.success.data && salesReportGet.success.data.data;

    function datas(type) {
        let _data = {
            labels: ["iOS", "Web", "Android"],
            datasets: [{
                /*label: 'Device Type',
                strokeColor: "#bbb",
                fillColor: "#cc9527",
                highlightFill: "#cc9527",
                highlightStroke: "#eee",*/
                // pointColor: "yellow",
                // pointStrokeColor: "red",
                // pointHighlightFill: "blue",
                // pointHighlightStroke: "brown",
                // borderWidth: 1,
                label: "Device Type",
                backgroundColor: '#bbb',
                borderColor: '#000',
                borderWidth: 1,
                hoverBackgroundColor: '#bbb',
                hoverBorderColor: '#cc9527',
            }]
        };

        switch (type) {
            case `device_type`:
                _data.datasets[0].data = deviceTypeData();
                break
        }
        return _data
    }

    function deviceTypeData() {
        let array = [];
        if (!objectIsEmpty(data)) {
            array.push(data.device_type.ios);
            array.push(data.device_type.web);
            array.push(data.device_type.android);
            return array;
        }
    }

// console.log(` <| salesReportGet |> `, salesReportGet )
    return (
        <>
            <SalesReportFilter mode="branch"/>
            <div className="plr-35">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="box-colored box-colored-misc bg-light-green">
                                    <div className="d-flex align-items-end">
                                        <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                        <div className="single-info m-0">
                                            <div className="info-title">Total Order</div>
                                            <div className="info-detail">{data && data.order_count}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="box-colored box-colored-misc bg-light-green">
                                    <div className="d-flex align-items-end">
                                        <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                                        <div className="single-info m-0">
                                            <div className="info-title">Total Revenue</div>
                                            <div
                                                className="info-detail">{data && data.currency}{data && data.total_grand_total}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           {/* <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="box-colored box-colored-misc bg-light-green">
                                    <div className="d-flex align-items-end">
                                        <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                                        <div className="single-info m-0">
                                            <div className="info-title">Total Delivery Fee</div>
                                            <div
                                                className="info-detail">{data && data.currency}{data && data.total_delivery_fee}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="box-colored box-colored-misc bg-light-orange">
                                    <div className="d-flex align-items-end">
                                        <img className="mr-4 svg-img" src={taxSvg} alt=""/>
                                        <div className="single-info m-0">
                                            <div className="info-title">Total Tax</div>
                                            <div className="info-detail">{data && data.total_tax}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="box-colored box-colored-misc bg-light-orange">
                                    <div className="d-flex align-items-end">
                                        <img className="mr-4 svg-img" src={taxSvg} alt=""/>
                                        <div className="single-info m-0">
                                            <div className="info-title">Total Commission</div>
                                            <div className="info-detail">{data && data.total_commission}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/*<div className="col-md-6">
                        <div className="bar-chart">
                            <div className="title">Orders by Platform</div>
                            <BarChart
                                      data={datas(`device_type`)}/>
                        </div>
                    </div>*/}

                </div>
                <MonthlySalesReport/>
            </div>
        </>
    )
}

export default memo(BranchSalesReport);