export const clone = (data) => {
  if ( data ) return JSON.parse( JSON.stringify( data ));
};

export function isArray( variable ) {
  return variable && variable.constructor === Array;
}

export function isObject( variable ) {
  return variable && variable.constructor === Object;
}

export function isReactElement( variable ) {
  return variable && variable.hasOwnProperty(`$$typeof`) &&
    variable[`$$typeof`] === `Symbol(react.element)`
}

export function isString( variable ) {
  return variable && variable.constructor === String;
}

export function isNumber( variable ) {
  return variable && variable.constructor === Number;
}

export function emptyObject( object ) {
  return isObject( object ) && Object.keys( object ).length === 0

}

export const inRecord = (arrayOfObjects, field, comparision, value) =>
{
  let contains = false;
  arrayOfObjects &&  isArray(arrayOfObjects) &&
  arrayOfObjects.length && arrayOfObjects.map( item => {
    return matchIn(item) ? contains = true : false
  });
  return contains;

  function matchIn(item) {
    switch (comparision) {
      case `>`: return item[field] > value;
      case `<`: return item[field] < value;
      case `>=`: return item[field] >= value;
      case `<=`: return item[field] <= value;
      case `=`: return item[field] === value;
      case `===`: return item[field] === value;
      case `==`: return item[field] === value;
      case `!=`: return item[field] !== value;
      case `includes`: return item[field].includes(value);
      default: return false

    }
  }
};

/**
 * Check if an array of objects match a certain criteria and return matched object
 * @param arrayOfObjects
 * @param field | string -> field to match
 * @param comparision | string | the comparision
 * @param value | any | the value to match
 * @returns {object}
 */
export const fromRecord = (arrayOfObjects, field, comparision, value) =>
{
  if ( arrayOfObjects && field && comparision ) {
    let theItem = null;
    arrayOfObjects && arrayOfObjects.length && arrayOfObjects.map( _item => {
      return  matchIn( _item ) ? theItem = _item : null
    });
    return theItem;
  }
  function matchIn(item) {
    if ( item )
      switch (comparision) {
        case `>`: return item[field] > value;
        case `<`: return item[field] < value;
        case `>=`: return item[field] >= value;
        case `<=`: return item[field] <= value;
        case `=`: return item[field] === value;
        case `===`: return item[field] === value;
        case `==`: return item[field] === value;
        case `!=`: return item[field] !== value;
        case `includes`: return item[field].includes(value);
        default: return false
      }
  }
};

/**
 * Check if an array of objects match a certain criteria and return the array of matched object
 * @param arrayOfObjects
 * @param field | string -> field to match
 * @param comparision | string | the comparision
 * @param values
 * @returns [{object},{object},..]
 */
export const getItemsFromRecord = ( arrayOfObjects, field, comparision, values ) =>
{
  let items = [];
  arrayOfObjects &&
  arrayOfObjects.length &&
  arrayOfObjects.map( _item => {
    values && values.length && values.map( value => {
      if(matchIn(_item, value)) items.push( _item );
    })
  });
  return items;

  function matchIn(item, value)
  {
    switch (comparision) {
      case `>`: return item[field] > value;
      case `<`: return item[field] < value;
      case `>=`: return item[field] >= value;
      case `<=`: return item[field] <= value;
      case `=`: case `===`: return item[field] === value;
      case `!=`: return item[field] !== value;
      default: return false

    }
  }
};

export const cleanObject = (obj) =>
{
  for (let prop in obj) {
    if (
      obj[prop] === null ||
      obj[prop] === undefined ||
      obj[prop] === ``)
    {
      delete obj[prop];
    }
  }
  return obj;
}

export const reverseObject = (object) => {
  let newObject = {};
  let keys = [];
  for (let key in object) { keys.push(key) }
  for (let i = keys.length - 1; i >= 0; i--) { newObject[keys[i]]= object[keys[i]]; }
  return newObject;
};