import {AccountFormState} from "./AccountForm-State";

export default function AccountFormReducer(state = AccountFormState, action) {
    let {data} = {...state};
    let { user } = action;
    switch (action.type) {
        case 'SET-ACCOUNT-DETAILS':
            data.payload = {
                first_name: user && user.first_name,
                last_name: user && user.last_name,
                middle_name: user && user.middle_name,
                mobile: user && user.mobile,
                email: user && user.email,
                country: user && user.country,
                city: user && user.city,
                address_line_1: user && user.address_line_1,
                old_password: ``,
                confirm_password: ``,
                new_password: ``

            }
            return {...state, data};

        case 'SET-CHANGE-PASSWORD-VISIBLE':
           data.changePasswordFlag = data.changePasswordFlag === false;
            return {...state, data};

        case 'SET-DEFAULT-ACCOUNT-FORM':
            data.payload = {
                first_name: ``,
                last_name: ``,
                middle_name: ``,
                mobile: ``,
                email: ``,
                country:``,
                city:``,
                address_line_1:``,
                old_password: ``,
                confirm_password: ``,
                new_password: ``
            }
            return {...state, data};
        default:
            return state;
    }
}