import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleFilterSidebar} from "../../../layout/Main/Main-Actions";
import getPaymentStatuses from "../../../../ajax/actions/Orders/payment-status/payment_statuses_get";
import getDeliveryStatuses from "../../../../ajax/actions/Orders/delivery-status/delivery_statuses_get";
import getOrderStatuses from "../../../../ajax/actions/Orders/order-status/order_statuses_get";
import SelectField from "../../../common/form/SelectField";
import getRestaurantBranchList from "../../../../ajax/actions/branch/get_restaurant_branch_list";
import {useHistory, useRouteMatch} from "react-router";
import getShippingMethods from "../../../../ajax/actions/Orders/shipping-method/shipping_methods_get";
import getPaymentMethods from "../../../../ajax/actions/Orders/payment-method/payment_methods_get";
import {
    openCloseFaultReportFilterSidebar, setBranchIDForFaultReport, setCountryIDForFaultReport,
    setDefaultFaultReportParams,
    toggleFaultReportFilter
} from "./FaultReport-Action";
import getFaultReport from "../../../../ajax/actions/report/sales/sales_report_get";
import {apiBaseUrl, pushToPage} from "../../../../utils/URLUtils";
import DateRangeFieldNew from "../../../common/form/DateRangeFieldNew";
import moment from "moment";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import faultReportReducer from "./FaultReport-Reducer";

const FaultReportFilter = (props) => {
    const[  countryID, setCountryID ] = useState(``);
    const reduxState = useSelector(state => state);
    let history = useHistory();
    let {url} = useRouteMatch();
    const { main, faultReportReducer,
        userDetails, restaurantBranchList } = reduxState;
    let { params, report_filter_open, report_filter_mode } = faultReportReducer && faultReportReducer.data;
    let parent_restaurantID = userDetails && userDetails.success && userDetails.success.restaurant_id;
    let branch_id = ``;
    // console.log(` <| props |> `, props)
    if( props.mode === 'branch' ) {
        branch_id = userDetails && userDetails.success && userDetails.success.branch_id;
    }
    useEffect(() => {
        if(props.mode !== 'branch') {
            let filters = {
                limit : 1000
            }
            dispatch(getRestaurantBranchList(parent_restaurantID, filters));
        }
       if( props.mode === 'branch' ) {
           dispatch(setBranchIDForFaultReport( branch_id ));
       }
    }, []);

    useEffect(() => {
        if( restaurantBranchList.success ) {
            setCountryID( restaurantBranchList.success.data.data[0].country_id );
            dispatch( setCountryIDForFaultReport(restaurantBranchList.success.data.data[0].country_id) )
        }
    },[restaurantBranchList.success]);

    const branchList = restaurantBranchList?.success?.data?.data;

    let dispatch = useDispatch();
    const closeFilterSidebar = ( ) => {
        dispatch(openCloseFaultReportFilterSidebar())
    }

    const faultReportFilterToggle = (e) => {
        dispatch(toggleFaultReportFilter(e, history))
    }

    const orderFilterSubmit = () => {
        dispatch( getFaultReport( params, countryID ) );
        closeFilterSidebar();
    }
    const faultFilterSetDefault = () => {
        dispatch( setDefaultFaultReportParams( ) );
        pushToPage( history, url )
        closeFilterSidebar();
        // dispatch( getFaultReport( params, countryID ) );
    }

    return (<div className={`filter-sidebar ${report_filter_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Filter</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">
                    {/*<div className="calendar-block">
                        <DateRangeFieldNew handleEvent={handleEvent} enddate={params.end_date} startdate={params.start_date} onApply = {dateApply}/>
                        <span className="icon-calendar"/>
                    </div>*/}
                    <a href="javascript:void(0)" className="btn-theme btn-outline mt-40" onClick={faultFilterSetDefault}>Clear Filter</a>
                    <a href="javascript:void(0)" className="btn-theme mt-20" onClick={orderFilterSubmit }>Filter</a>
                </form>
            </div>
        </div>
    </div>)
}

export default FaultReportFilter;