import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../common/Tags/Tags";
import {ucFirst, ucFirstAll} from "../../../utils/StringUtils";
import {fullDate} from "../../../utils/DateTimeUtils";
import ReactTooltip from "react-tooltip";

const OrderListItem = ({order, url, reducer, branchAdmin}) => {
    const {id, order_date, branch_name, customer_name, main_restaurant_id, branch_id, currency_code, grand_total, delivery_type, delivery_status, payment_status, order_status, created_at} = order;
    return (
        <tr>
            <td>{id}</td>
            <td>{fullDate(order_date)}</td>
            <td>
                {/*<Link to={`/restaurant/${main_restaurant_id}/branch/${branch_id}/dashboard`}>
                    {ucFirst(branch_name)}
                </Link>*/}
                <Link to={`/branches/${branch_id}/info`}>
                    {ucFirstAll(branch_name)}
                </Link>
            </td>
            <td>{ucFirstAll(customer_name)}</td>
            <td>{currency_code} {grand_total}</td>
            <td>{ucFirstAll(delivery_type, '-')}</td>
            <td><Tags status={delivery_status}/></td>
            <td><Tags status={payment_status}/></td>
            <td><Tags status={order_status}/></td>

            <td className="buttons-block">
                {/*<div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link to={`/order/${id}/view`}>
                            <i className="fa-eye" data-tip="View"/>
                        </Link>
                    </div>
                </div>*/}
                <Link to={ branchAdmin ? `/branches/${branch_id}/order/${id}/view` : `/order/${id}/view`}>
                    <i className="fa-eye" data-tip="View"/>
                </Link>
            </td>
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>
    )
};

export default memo(OrderListItem);