export default function getRestaurantReviewsReducer
  (state = { error:null, success:null, processing:false }, action) {

  switch (action.type)
  {
    case 'GET_RESTAURANT_REVIEWS_SUCCESS':
      return {...state, ...{success: action.success }};

    case 'GET_RESTAURANT_REVIEWS_ERROR':
      return {...state, ...{ error: action.error }};

    case 'GET_RESTAURANT_REVIEWS_PROCESSING':
      return {...state, ...{ processing: action.processing }};

    default: return state;
  }
}