import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import Table from "../../../common/table/Table";
import AddonsCategoryListItem from "./AddonsCategoryListItem";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import {setIsList, setPaginationData} from "../../branches/specific/BranchInfo-Action";
import {toggleAddonCategoryListFilter} from "./AddonCategoryList-Action";
import TableDataLoader from "../../../common/loaders/TableDataLoader";
import NoDataAvailable from "../../../common/NotFound/NoDataAvailable";
import AddonCategoryFilter from "./AddonCategoryFilter";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const AddonsCategories = () => {

    let [branchID, setBranchID] = useState(``);
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {getRestaurantBranch, getAddonsCategoriesReducer, addonCategoryList, addonCategoryDelete} = reduxState;
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Addons Category`);

    useEffect(() => {
        let details = {
            title: 'Addons Category',
            icon: 'addon_cat',
            action: true,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch(setIsList(true));
    }, [])

    useEffect(() => {
        if (getRestaurantBranch.success) {
            dispatch(getAddonCategories());
            setBranchID(getRestaurantBranch?.success?.data?.data?.id)
        }
    }, [getRestaurantBranch.success])

    useEffect(() => {
        dispatch(setPaginationData(getAddonsCategoriesReducer, toggleAddonCategoryListFilter));
    }, [getAddonsCategoriesReducer])

    const {fields, params, list} = addonCategoryList.data
    return (
        <>
            <div className="table-block table-responsive pl-50">
                <Table reducer={getAddonsCategoriesReducer}
                       action={toggleAddonCategoryListFilter}
                       params={params}
                       columns={fields}>
                    <tbody>
                    {getAddonsCategoriesReducer.processing ? <TableDataLoader colspan={5}/> :
                        list && list.length ? list.map(category => {
                                return (
                                    <AddonsCategoryListItem key={category.id}
                                                            branchID={branchID}
                                                            reducer={addonCategoryDelete}
                                                            addonsCategory={category}
                                                            url={url}/>
                                )
                            }
                        ) : <NoDataAvailable colspan={5}/>}
                    </tbody>
                </Table>
            </div>
            <AddonCategoryFilter/>
        </>
    )
}

export default AddonsCategories;