import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import SelectField from "../../../common/form/SelectField";
import {useHistory, useRouteMatch} from "react-router";
import {
    hourModeChange, setCurrentScheduleParams,
    setDefaultScheduleParams,
    toggleScheduleFilterOpen,
    toggleScheduleListFilter,
} from "./Schedule-Action";
import {
    daysListInGivenMonth,
    getStartYearFromNow,
    hourMode, monthListArrayOptions,
} from "../../../../utils/DateTimeUtils";

const ScheduleFilter = () => {

    const reduxState = useSelector(state => state);
    let history = useHistory();
    const { userDetails, scheduleReducer } = reduxState;
    let {params, schedule_filter_open } = scheduleReducer && scheduleReducer.data;
    // let parent_restaurantID = userDetails && userDetails.success && userDetails.success.restaurant_id;
    let { url } = useRouteMatch()

    let dispatch = useDispatch();
    const closeFilterSidebar = () => {
        dispatch(toggleScheduleFilterOpen())
    }

    const scheduleListFilterToggle = (e) => {
        dispatch(toggleScheduleListFilter(e, history))
    }


    const scheduleFilterSetDefault = () => {
        dispatch( setDefaultScheduleParams() );
        closeFilterSidebar();
    }

    const scheduleFilterCurrentDay = () => {
        dispatch( setCurrentScheduleParams() );
        closeFilterSidebar();
    }

    const onChangeHourMode = (e) => {
        let {name, value} = e.target;
        dispatch( hourModeChange( name, value ) );
    }
    return (<div className={`filter-sidebar ${schedule_filter_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Filter</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">
                    <SelectField name="hour_mode"
                                 options={hourMode()}
                                 // onChange={onChangeHourMode}
                                 onChange={scheduleListFilterToggle}
                                 _value="value"
                                 _label="label"
                                 value={params.hour_mode}
                    />
                    {/*<SelectField name="year"
                                 options={getStartYearFromNow()}
                                 onChange={scheduleListFilterToggle}
                                 _value="value"
                                 _label="name"
                                 value={params.year}
                    />
                    <SelectField name="month"
                                 options={monthListArrayOptions()}
                                 onChange={scheduleListFilterToggle}
                                 _value="value"
                                 _label="label"
                                 value={params.month}
                    />*/}
                    <SelectField name="day"
                                 options={daysListInGivenMonth()}
                                 onChange={scheduleListFilterToggle}
                                 _value="value"
                                 _label="label"
                                 value={params.day}
                    />
                    <a href="javascript:void(0)" className="btn-theme btn-outline mt-40" onClick={scheduleFilterSetDefault}>Clear Filter</a>
                    {/*<a href="javascript:void(0)" className="btn-theme btn-outline mt-40" onClick={scheduleFilterCurrentDay}>Current Day</a>*/}
                </form>
            </div>
        </div>
    </div>)
}

export default ScheduleFilter;