import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useRouteMatch} from "react-router";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import useAppState from "../../../../hooks/useAppState";
import getReservation, {getReservationReset} from "../../../../ajax/actions/reservations/reservation_get";
import SpecificReservationHeader from "./specific-reservation-header";
import ReservationCustomerDetails from "./ReservationCustomerDetails";
import ReservationRestaurantDetails from "./ReservationRestaurantDetails";
import ReservationStatus from "./ReservationStatus";
import ReservationItems from "./ReservationItems";
import ReservationHistory from "./ReservationHistory";
import ReservationSpecialInstruction from "./ReservationSpecialInstruction";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";
import updateReservationStatusReducer from "../../../../ajax/reducers/reservations/reservation-status-put";
import {updateReservationStatusReset} from "../../../../ajax/actions/reservations/reservation_status_put";

const Reservation = () => {
    let dispatch = useDispatch();
    const userDetails = useAppState(state => state.userDetails);
    const reservationGet = useAppState(state => state.reservationGet);
    const updateReservationStatusReducer = useAppState(state => state.updateReservationStatusReducer);
    let {id} = useParams();
    let { url } = useRouteMatch();
    useTitle(Title.name + ` Reservation Details`);


    useEffect( () => {
        id && dispatch( getReservation( id ) );
        let details = {
            title: 'Reservation Details',
            icon: 'order',
            id,
            // action: true,
            linkUrl: `${url}`
        }
        dispatch( setIsList( false ) )
        dispatch(setBranchHeaderDetails(details))
        return () => {
            dispatch( getReservationReset() )
        }
    },[])

    useEffect( () => {
        if( updateReservationStatusReducer.success ) {
            dispatch( updateReservationStatusReset() );
            id && dispatch( getReservation( id ) );

        }
    },[updateReservationStatusReducer.success])

    let role = userDetails && userDetails.success && userDetails.success.roles && userDetails.success.roles[0].name;
    let { success, processing } = reservationGet;
    let details = success && success.data && success.data.data;

    return (<>
        {/*{role !== 'branch-admin' && (<SpecificReservationHeader id={id}/> ) }*/}
        <div className="plr-50">
            <div className="row">
                <div className="col-md-8">
                    <ReservationStatus processing={processing} reservation={details}/>

                    <ReservationItems processing={processing} reservation={details}/>
                    {/*<ReservationHistory id={id} />*/}
                    <ReservationSpecialInstruction processing={processing} reservation={details}/>
                </div>
                <div className="col-md-4">
                    <ReservationRestaurantDetails processing={processing} reservation={details}/>
                    <ReservationCustomerDetails processing={processing} reservation={details}/>

                </div>
            </div>
        </div>
    </>)
}
export default Reservation;