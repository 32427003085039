import {urlParamValue} from "../../../../utils/URLUtils";

const DefaultAddonsCategoryListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) ||``,
    },
    fields: [
        {field: `id`, label: `ID`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `name`, label: `Name`, sort_icon:false, sort: false},
        {field: `max_addon`, label: `Max Addon`,sort_icon:false, sort: true, sort_type:'desc'},
        {field: `min_addon`, label: `Min Addon`,sort_icon:false, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`,sort_icon:false, sort: false},
        // {field: `action`, label: `Action`, sort_icon:false,sort: false},
        // {field: ``, label: ``,sort_icon:false, sort: false},
    ],
    addon_category_filter_open:false,
    list:[]
}

export const AddonsCategoryListState = {data: DefaultAddonsCategoryListState}