import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {ucFirst, ucFirstAll} from "../../../../utils/StringUtils";

const OrderRestaurantDetails = ({orders, processing}) => {
    let branch = orders && orders.branch;
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Restaurant Detail</h6>
            { processing ? <GrowingLoader /> :
                <>
                    <div className="single-info">
                        <div className="info-title">Restaurant Name</div>
                        <div className="info-detail">{branch && ucFirst(branch.name)}</div>
                    </div>
                    <div className="single-info">
                        <div className="info-title">Address</div>
                        <div className="info-detail">{branch && ucFirstAll(branch.address)}</div>
                    </div>
                    <div className="single-info">
                        <div className="info-title">Mobile Number</div>
                        <div className="info-detail">{branch && branch.mobile_no}</div>
                    </div>
                </>
            }
        </div>
    )
}

export default OrderRestaurantDetails;