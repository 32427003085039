import React from "react";
import Tags from "../../../common/Tags/Tags";
import {ucFirst, ucFirstAll} from "../../../../utils/StringUtils";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const OrderStatus = ( { processing, orders } ) => {
    // let order = orders && orders.order;
    let order = orders;
    // console.log(` <| order |> `,orders )
    return (
        <div className="box-colored bg-light-gray pb-10">
            <div className="row">
                <div className="col-md-12">
                    <h6>Order Status </h6>
                </div>
                { processing ? <GrowingLoader/> : <>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-info">
                            <div className="info-title">Delivery Type</div>
                            <div className="info-detail text-theme">{order && order.delivery_type &&  ucFirstAll(order.delivery_type, '-')}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-info">
                            <div className="info-title">Delivery Status</div>
                            <div className="info-detail text-success">{order && order.delivery_status_label && <Tags status={order.delivery_status_label}/>}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-info">
                            <div className="info-title">Payment Status</div>
                            <div className="info-detail text-success">{order && order.payment_status_label && <Tags status={order.payment_status_label}/> }</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-info">
                            <div className="info-title">Order Status</div>
                            <div className="info-detail text-success">{ order && order.order_status_label && <Tags status={order.order_status_label}/> }</div>
                        </div>
                    </div>
                    </>}
            </div>
        </div>
    )
}

export default OrderStatus;