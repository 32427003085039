import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

const SpecificOrderHeader = ({ id }) => {
    return (<div className="heading-block">

        <div className="title-block">
            <div className="title-block-icon">
                <span className="icon-order"/>
            </div>
            <div className="title-block-content">
                <h2>Order</h2>
                <span><Link to={'/'}>Home</Link></span>
                <span>Orders</span>
                <span>Order #{id}</span>

            </div>
        </div>
    </div>)
}

export default SpecificOrderHeader;