import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../../utils/URLUtils'
import { AppSource } from '../../../../Config';

function _success(success) {
    return {type: 'FETCH_ORDER_STATUSES_SUCCESS', success}
}

function _error(error) {
    return {type: 'FETCH_ORDER_STATUSES_ERROR', error}
}

function _processing(processing) {
    return {type: 'FETCH_ORDER_STATUSES_PROCESSING', processing}
}

function getOrderStatuses() {
    return dispatch => {
        dispatch(_processing(true));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
        };
        // let request  = axios.get( apiBaseUrl(`order/v1/order/status`), config)
        axios.get(apiBaseUrl(`order/v1/order/status/pagination`), config)
            .then(res => {
                dispatch(_success(res));
            }).catch(error => {
            dispatch(_error(error));
        });
    }
}

export default getOrderStatuses;