import React from "react";
import {Link} from "react-router-dom";
import OrderHeader from "../../modules/order/OrderHeader";
import SalesReportHeader from "../../modules/report/sales/SalesHeader";
import TimeslotHeader from "../../modules/reservations/timeslot/day/TimeslotHeader";
import FaultHeader from "../../modules/report/fault/FaultHeader";

const RestaurantHeader = ({title, icon, header }) => {

    return (<div className="heading-block">
        <div className="title-block">
            <div className="title-block-icon">
                <span className={`icon-${icon}`}/>
            </div>
            <div className="title-block-content">
                <h2>{title}</h2>
                <span><Link to={'/'}>Home</Link></span>
                <span>{title}</span>
            </div>
        </div>
        {header === "order" ? <OrderHeader orderFilterMode="parent"/> : null }
        {header === "report" ? <SalesReportHeader filterMode="parent"/> : null }
        {header === "fault-report" ? <FaultHeader filterMode="parent"/> : null }
        {header === "timeslot" ? <TimeslotHeader /> : null }
        
    </div>)
}

export default RestaurantHeader;