import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fromRecord, inRecord } from "../utils/DataUtils";
import { useLocation } from "react-router";

const useCurrentPageTitle = () =>
{
  const location = useLocation()
  const [ currentLocation, setCurrentLocation ] = useState('')

  const currentPageTitle = useSelector( state =>
  {
    const { routes } = state
    const { list } = routes.data
    return inRecord(list, `path`, `=`, currentLocation ) ?
      fromRecord(list, `path`, `=`, currentLocation).title : ''
  })

  useEffect(() =>
  {
    if ( location ) setCurrentLocation( location.pathname )
    else setCurrentLocation('')

  }, [ location ])

  return currentPageTitle
}

export default useCurrentPageTitle;