import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import { togglePartnerIntegrationForm } from "./PartnerIntegrationList-Action";

const PartnerIntegrationHeader = () => {
    let dispatch = useDispatch();
    const toggle = () => {
        dispatch( togglePartnerIntegrationForm() )
    }

    return (<div className="heading-block">

        <div className="title-block">
            <div className="title-block-icon">
                <span className="icon-review"/>
            </div>
            <div className="title-block-content">
                <h2>Partner Integration</h2>
                <span><Link to={'/'}>Home</Link></span>
                <span>Partner Integration</span>
            </div>
        </div>
        <div className="button-wrapper">
            <a href="javascript:void(0)" onClick={toggle} className="btn-theme" id="btn-filter">Add Partner Integration</a>
        </div>
    </div>)
}

export default PartnerIntegrationHeader;