import React, { memo, cloneElement } from 'react'
import LoadingTableRow from "../loaders/LoadingTableRow";
import ErrorTableRow from "../ErrorTableRow/ErrorTableRow";


const TBody = ({ columns, reducer, buttons: Buttons }) =>
{
  const { processing, success, error } = reducer
  const list = success && success.data.payload.data

  return (

    <tbody>

    { !success &&  processing && <LoadingTableRow colSpan={ columns.length } /> }
    {  error   && !processing && <ErrorTableRow   colSpan={ columns.length } /> }
    {  success && !error      && list.map( data =>

      <tr key={ data.id }>
        { columns.map( column => {
          const { render, key } = column
          return (

            <td key={ key } className={ key === `actions` && `button-cluster` }>
              { render ? render( data ) : key === `actions` ? <Buttons id={ data.id } /> : data[ key ]}
            </td>
          )
        })}
      </tr>

    )}

    </tbody>

  )
};

export default memo( TBody )