import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {toggleFoodFilterOpen, toggleFoodListFilter} from "./FoodList-Action";
import TextField from "../../../common/form/TextField";
import CommonHeader from "../../common-filter-header/CommonHeader";

const FoodHeader = ({orderFilterMode}) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const reduxState = useSelector(state => state);
    const { branchSubHeaderReducer, foodList } = reduxState;
    let {params } = foodList && foodList.data;
    const toggleFilter = () => {
        dispatch(toggleFoodFilterOpen())
    }

    const toggleListFilter = (e) => {
        dispatch(toggleFoodListFilter(e, history))
    }
    return (
        <CommonHeader toggleFilter={toggleFilter}
                      param={params}
                      toggleListFilter={(e) => toggleListFilter(e)}

        />
    )
}

export default FoodHeader;