import axios from 'axios/index';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'POST_ADDON_CATEGORY_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ADDON_CATEGORY_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ADDON_CATEGORY_PROCESSING', processing };
}

function createAddonCategory( data )
{
    return ( dispatch, getState ) =>
    {
        let { main } = getState();
        let { user_role } = main.data;
      dispatch(_processing(true));

        delete data.trans;
        // console.log(` <| data |> `, data)
        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${data.restaurantID}/addon/category`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/category`
        }
      axios({
        // url:apiBaseUrl(`restaurant/v1/restaurant/${data.restaurantID}/addon-category`),
        url:apiBaseUrl(url),
        method: "post",
        dataType: 'json',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + loginToken(),
             'x-app-source': AppSource.appSource
        }
      })
      .then(function(res){
          dispatch(_success(res));
          handleSuccess(res);
      }).catch(function(error){
          handleError(error);
          dispatch(_error(error));
      });
    }
}
export function createAddonCategoryReset()
{
    return dispatch => dispatch({ type: `POST_ADDON_CATEGORY_RESET` })
}

export default createAddonCategory;