export default function getRestaurantReviewReducer
  (state = { error:null, success:null, processing:false }, action) {

  switch (action.type)
  {
    case 'GET_RESTAURANT_REVIEW_SUCCESS':
      return {...state, ...{success: action.success }};

    case 'GET_RESTAURANT_REVIEW_ERROR':
      return {...state, ...{ error: action.error }};

    case 'GET_RESTAURANT_REVIEW_PROCESSING':
      return {...state, ...{ processing: action.processing }};

    default: return state;
  }
}