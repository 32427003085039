import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'GET_RESTAURANT_BRANCH_DASHBOARD_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_RESTAURANT_BRANCH_DASHBOARD_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_RESTAURANT_BRANCH_DASHBOARD_PROCESSING', processing };
}

function getRestaurantBranchDashboard()
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true));
        const config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource

                // 'Access-Control-Allow-Origin':'*'
            }
        };
        const url= apiBaseUrl(`order/v1/vendor/orders/dashboard`);
        axios.get(url, config)
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
              // handleError( error )
            });
    }
}
export default getRestaurantBranchDashboard;