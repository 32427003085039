import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_PROCESSING', processing };
}

function getRestaurantAdminDashboardStatistics()
{
    return ( dispatch, getState ) =>
    {
        const {getRestaurantBranch} = getState();
        let branch_id = getRestaurantBranch.success && getRestaurantBranch.success?.data?.data?.id
        dispatch(_processing(true));
        const config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            },
            params:{ branch_id:branch_id }
        };
        const url= apiBaseUrl(`order/v1/restaurant/admin-dashboard-stats`);
        axios.get(url, config)
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
              // handleError( error )

            });
    }
}

export default getRestaurantAdminDashboardStatistics;