import React, {memo} from 'react';
import {Bar} from 'react-chartjs-2';

const BarChart = props => {
    // console.log(` <| props |> `, props )
    return (
        <Bar
            data={props.data}
            width={100}
            // height={200}
            redraw={props.redraw}
            options={{
                maintainAspectRatio: false,
                animateRotate: true,responsive:true
            }}
        />
    )
}

export default memo(BarChart)