export default function getFoodsReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { processing, success, error } = action;
    switch (action.type)
    {
        case 'GET_FOODS_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'GET_FOODS_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'GET_FOODS_PROCESSING':
            return { ...state, ...{ processing }};

        default: return state;
    }
}