export const AddonFormState = {
    data: {
        translation:{},
        status:``,
        price:``,
        calorie:0,
        trans:null,
        addon_categories:[],
        selected_addon_categories:[],
        restaurantID:``,
        parentID:``
    }
}