import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
// import ReviewHeader from "./ReviewHeader";
import Table from "../../../../common/table/Table";
import PaginationButtons from "../../../../common/buttons/PaginationButtons";
import getPartnerIntegrations from "../../../../../ajax/actions/partner/integration/partner_integrations_get";
import PartnerIntegrationItem from "./PartnerIntegrationItem";
import {togglePartnerIntegrationListFilter} from "./PartnerIntegrationList-Action";
// import ReviewDetails from "./modal/ReviewDetails";
// import RestaurantHeader from "../../../../layout/Header/RestaurantHeader";
import TableDataLoader from "../../../../common/loaders/TableDataLoader";
import NoDataAvailable from "../../../../common/NotFound/NoDataAvailable";
import {useTitle} from "react-use";
import { Title } from "../../../../../Config";
import './_partnerIntegration.scss'
import PartnerIntegrationHeader from './PartnerIntegrationHeader';
import PartnerIntegrationForm from '../form/PartnerIntegrationForm';

const PartnerIntegrations = () => {
    
    const dispatch = useDispatch();

    const reduxState = useSelector(state => state);
    const { getPartnerIntegrationsReducer, partnerIntegrationListReducer,deletePartnerIntegrationReducer } = reduxState;
    const { success } = getPartnerIntegrationsReducer;
    
    const list = success && success.data && success.data.payload;
    let current_page = success && success.data && success.data.meta && success.data.meta.current_page;
    let last_page = success && success.data && success.data.meta && success.data.meta.last_page;
    let total = success && success.data && success.data.meta && success.data.meta.total;
    let pages = { current_page, last_page, total };

    let { fields, params, partner_modal_open } = partnerIntegrationListReducer && partnerIntegrationListReducer.data;

    useTitle(Title.name + ` Partner Integration`);

    useEffect(() => {
        dispatch( getPartnerIntegrations() )
    }, [])
    
    useEffect(() => {
        if( deletePartnerIntegrationReducer.success ) dispatch( getPartnerIntegrations() )
    },[deletePartnerIntegrationReducer])
    
    return (
        <>
            {/* <RestaurantHeader title="Partner Integration" icon="🧑‍🤝‍🧑" /> */}
            <PartnerIntegrationHeader/>
            <div className="table-block table-responsive pl-50">
                <Table reducer={ getPartnerIntegrationsReducer }
                       action={togglePartnerIntegrationListFilter}
                       params={params}
                   columns={fields}>
                    <tbody>
                    { getPartnerIntegrationsReducer.processing ? <TableDataLoader colspan={6} /> :
                        list && list.length ? list.map( review => {
                        return  (
                            <PartnerIntegrationItem key={ review.id } partners={ review } />
                        )}
                    ) : <NoDataAvailable colspan={6}/>}
                    </tbody>
                </Table>
            </div>
            {/* <div className="fixed-footer">
                    <PaginationButtons pages={pages }
                                   fetchAction={ togglePartnerIntegrationListFilter }
                                   processing={ getPartnerIntegrationsReducer && getPartnerIntegrationsReducer.processing }/>
            </div> */}
            {/* <ReviewDetails /> */}
            {partner_modal_open ? <PartnerIntegrationForm /> : null}
        </>
    )
}

export default PartnerIntegrations;