import axios from 'axios/index';
import {apiBaseUrl,} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'PUT_ADDON_CATEGORY_SUCCESS', success};
}

function _error(error) {
    return {type: 'PUT_ADDON_CATEGORY_ERROR', error};
}

function _processing(processing) {
    return {type: 'PUT_ADDON_CATEGORY_PROCESSING', processing};
}

function updateAddonCategory( data, id ) {
    return (dispatch, getState) => {
        let {main} = getState();
        let {user_role} = main.data;
        dispatch(_processing(true));

        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${data.restaurantID}/addon/category/${id}`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/category/${id}`
        }

        axios({
            // url: apiBaseUrl(`restaurant/v1/restaurant/${data.restaurantID}/addon-category/${id}`),
            url: apiBaseUrl(url),
            method: "put",
            dataType: 'json',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                handleSuccess(res);
            }).catch(function (error) {
                // console.log(` <| error |> `,{error} )
                handleError(error);
                dispatch(_error(error));
            });
    }
}

export function updateAddonCategoryReset()
{
    return dispatch => dispatch({ type: `PUT_ADDON_CATEGORY_RESET` })
}

export default updateAddonCategory;