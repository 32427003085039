import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import {toggleURLQuery} from "../../../../utils/URLUtils";

export function toggleAddonCategoryListFilter(e, history) {
    return function ( dispatch, ) {
        let { name, value, sort } = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-ADDON-CATEGORY-LIST-FILTER', name, value,});
        if( name === 'sort_column' || name === 'page' ) {
            dispatch( getAddonCategories());
        }
    }
}

export function toggleAddonCategoryFilterOpen( ) {
    return function ( dispatch ) {
        dispatch({ type:'ADDON-CATEGORY-FILTER-OPEN-CLOSE', });
    }
}

export function setDefaultAddonCategoryParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-ADDON-CATEGORY-PARAMS', });
    }
}

export function setAddonCategorySearchReset( history ) {
    return function ( dispatch,) {
        toggleURLQuery( 'q', `` , history)
        dispatch({type: 'SET-ADDON-CATEGORY-SEARCH-RESET', });
    }
}