import React from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

// let ctr = 1;
const SortableItem = SortableElement(({value, index}) => {
    return (
        <li className="row">
            <div className="col-md-6 name-col">
                <span className="sort-item-id">. </span>{ value.name }
            </div>
            <div className="col-md-1 offset-md-5">
                <span className="icon-drag mr-5 d-block text-right"/>
            </div>
        </li>
    )
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul className="sortable-ul">
            { items && items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const AddonCategoryDND = ( props ) =>
{
    const onSortEnd = ({ oldIndex, newIndex }) => {
        props.onDragEnd(arrayMove( props.list, oldIndex, newIndex));
    };

    return (
        <div className="sortable-list">
            <p className="plr-35">Drag the list to sort the items.</p>
            <SortableList
                lockAxis="y"
                helperClass="dragging"
                transitionDuration={300}
                items={ props.list }
                onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)}
            />
        </div>
    );
}

export default AddonCategoryDND;