import React, { memo } from 'react';

const ClearInputButton = (props) =>
{
    let { offsetBottom, reset } = props;
    return (

        <span
            className="input-clear input-icon"
            onClick={reset}
            style={{ bottom: offsetBottom || null }}
        >
            {/*<span className="fa fa-times search-reset-button" />*/}
            <span className="icon-close search-reset-button"/>
        </span>

    )
}


export default memo(ClearInputButton);