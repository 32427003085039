export default function getFoodCategoriesSortReducer
  (state = { error:'', success:'', processing:false}, action)
{
    const { processing, success, error } = action;
    switch (action.type) {
        case 'GET_FOOD_CATEGORIES_SORT_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'GET_FOOD_CATEGORIES_SORT_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'GET_FOOD_CATEGORIES_SORT_PROCESSING':
            return {...state, ...{ processing }};

        default: return state;
    }
}