import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../common/Tags/Tags";
import ReactTooltip from "react-tooltip";
import {ucFirst, ucFirstAll} from "../../../utils/StringUtils";

const BranchListItem = ({branch}) => {
    const {id, branch_name, email_address, phone_no, status, district, is_open, city_name} = branch;

    return (

        <tr>
            <td>{id}</td>
            <td><Link to={`/branches/${id}/info`}>{ucFirstAll(branch_name)}</Link></td>
            <td>{email_address}</td>
            <td>{phone_no}</td>
            <td>{status ? <Tags status="active"/> : <Tags status="inactive"/>} / {is_open ?
                <Tags status="open"/> : <Tags status="closed"/>}
            </td>
            <td>{ucFirstAll(district)}</td>
            {/*<td>{city_name}</td>*/}
            <td className="buttons-block">
                <Link to={`/branches/${id}/info`}>
                    <i className="fa-eye" data-tip="Dashboard"/> </Link>
            </td>
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>

    )
};

export default memo(BranchListItem);