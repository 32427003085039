import React, { memo, useState } from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";

const Thead = ({ columns, action, params   }) =>
{
  let history = useHistory();
  let [ sort, setSort ] = useState( 'desc' )

let dispatch = useDispatch()
  function onClick(column) {

    if( sort === 'desc' ) {
      setSort('asc')
    } else {
      setSort('desc')
    }
    let e = {target: {name: `sort_column`, value: column,sort }};
    action && dispatch( action( e, history ))
  }
  return (

    <thead>
    <tr>
      { columns.map(( column, i ) =>
          column.sort ?
          <th key={ i }
              onClick={ () => onClick(column.field) }
              className={ `pointer ${!column.title ? `blank` : undefined}` } >
            { column.label } {column.sort_icon ?
              /*<span className={`icon-${sort === 'desc' ? 'ups' : 'downs'}`}/> :*/
              <span className={`icon-${column.sort_type === 'desc' ? 'ups' : 'downs'}`}/> :
              null}
          </th>
        :
              <th key={ i }
                  className={ !column.title ? `blank` : undefined } >
                { column.label }{column.sort_icon ? <span className="icon-ups"/> : null}
              </th>
      )}
    </tr>
    </thead>

  )
};

export default memo( Thead )