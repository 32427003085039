import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import getRestaurantAdminDashboardStatistics
    from "../../../../ajax/actions/dashboard-statistics/restaurant_admin_dashboard_statistics_get";
import orderSvg from "../../../../assets/images/total_order.svg";
import sarSvg from "../../../../assets/images/revenue.svg";
import taxSvg from "../../../../assets/images/tax.svg";
import useAppState from "../../../../hooks/useAppState";


const InfoStats = ( ) => {
    let dispatch = useDispatch();
    const restaurantAdminDashboardStats = useAppState( s => s.restaurantAdminDashboardStats );
    const getRestaurantBranch = useAppState( s => s.getRestaurantBranch );

    useEffect(() => {
        if (getRestaurantBranch.success) {
            dispatch(getRestaurantAdminDashboardStatistics())
        }
    }, [getRestaurantBranch.success]);

    let data = restaurantAdminDashboardStats &&
        restaurantAdminDashboardStats.success &&
        restaurantAdminDashboardStats.success.data &&
        restaurantAdminDashboardStats.success.data.data;

    return (
        <div className="plr-35">
            <div className="row">
                <div className="col-lg-12 ">
                    <h5>Statistic From: { data.date_string }</h5>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="box-colored box-colored-misc bg-light-green">
                        <div className="d-flex align-items-end">
                            <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                            <div className="single-info m-0">
                                <div className="info-title">Total Order</div>
                                <div className="info-detail">{data.total_orders}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="box-colored box-colored-misc bg-light-green">
                        <div className="d-flex align-items-end">
                            <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                            <div className="single-info m-0">
                                <div className="info-title">Total Revenue</div>
                                <div className="info-detail">{data.currency}{data.revenue}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="box-colored box-colored-misc bg-light-orange">
                        <div className="d-flex align-items-end">
                            <img className="mr-4" src={taxSvg} alt=""/>
                            <div className="single-info m-0">
                                <div className="info-title">Total Branches</div>
                                <div className="info-detail">{data.total_branch}</div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}
export default InfoStats;