import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _processing(processing) {
    return{
        type: 'SORT_FOOD_POST_PROCESSING',
        processing
    };
}
function _success(success) {
    return{
        type: 'SORT_FOOD_POST_SUCCESS',
        success
    };
}
function _error(error) {
    return{
        type: 'SORT_FOOD_POST_ERROR',
        error
    };
}

function sortFoodPost(foodDetail, branch_id, category_id)
{
    return dispatch =>
    {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let _foods = {
            foods : foodDetail
        }

        axios({
            url: apiBaseUrl(`restaurant/v1/branch/${branch_id}/category/${category_id}/food/sorting`),
            method: "post",
            dataType: 'json',
            data: _foods,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('Bearer'),
                'x-app-source': AppSource.appSource
            }
        })
        .then(function(res){
            dispatch(_processing(false));
            dispatch(_success(res));
            handleSuccess( res );
        }).catch(function(error){
                dispatch(_processing(false));
                dispatch( _error(error));
                handleError( error )
        });
    }
}
export default sortFoodPost;
