import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_FOOD_BY_CATEGORY_SUCCESS', success }
}
function _error(error) {
    return { type:'GET_FOOD_BY_CATEGORY_ERROR', error }
}
function _processing(processing) {
    return { type: 'GET_FOOD_BY_CATEGORY_PROCESSING', processing }
}

function getFoodsByCategory( branch_id, category_id )
{
    return dispatch => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let config = {
            headers: { 'Authorization': 'Bearer ' + loginToken() },
            'x-app-source': AppSource.appSource
        };

        axios.get(apiBaseUrl(`restaurant/v1/branch/${ branch_id }/category/${category_id}/food/sorting`), config)
        .then(res => {
            dispatch(_processing(false));
            dispatch(_success(res));

        }).catch(error => {
            // dispatch(_error(error && error.response.data.message));
            dispatch(_error(error ));
            dispatch(_processing(false));
        });
    }
}
export default getFoodsByCategory;