import {beginningOfThisMonth, getCurrentDate} from "../../../utils/DateTimeUtils";
import {urlParamValue} from "../../../utils/URLUtils";

const DefaultOrderListState = {
    params: {
        limit: urlParamValue(`limit`) || 15,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) || ``,
        restaurant_id:urlParamValue(`restaurant_id`) || ``,
        delivery_status:urlParamValue(`delivery_status`) || ``,
        payment_status:urlParamValue(`payment_status`) || ``,
        country_id:urlParamValue(`country_id`) || ``,
        city_id: urlParamValue(`city_id`) || ``,
        end_date: urlParamValue(`end_date`) || getCurrentDate(),
        branch_id: urlParamValue(`branch_id`) || ``,
        start_date: urlParamValue(`start_date`) || beginningOfThisMonth(),
        order_status: urlParamValue(`order_status`) || `accepted`,
        customer_name:  urlParamValue(`customer_name `) || ``,
        payment_method: urlParamValue(`payment_method`) || ``,
        delivery_type: urlParamValue(`delivery_type`) || ``,
        phone: urlParamValue(`phone`) || ``
    },
    fields: [
        {field: `id`, label: `Order#`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `order_date`, label: `Date`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `restaurant_name`, label: `Branch`, sort: true, sort_type:'desc'},
        {field: `customer_name`, label: `Customer`, sort: true, sort_type:'desc'},
        {field: `amount`, label: `Amount`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `delivery_type`, label: `Delivery Type`, sort: false},
        {field: `delivery_status`, label: `Delivery Status`, sort: false,},
        {field: `payment_status`, label: `Payment Status`, sort: false},
        {field: `order_status`, label: `Order Status`, sort: false},
        {field: `action`, label: `Action`, sort: false},
    ],
    branchID:``,
}

export const OrderListState = {data: DefaultOrderListState}