import axios from 'axios/index';
import { apiBaseUrl } from '../../../../utils/URLUtils'
import { loginToken } from '../../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'POST_PARTNER_CATEGORY_MAP_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_PARTNER_CATEGORY_MAP_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_PARTNER_CATEGORY_MAP_PROCESSING', processing };
}

function createPartnerCategoryMap( payload )
{
    return ( dispatch, getState ) =>
    {
      dispatch(_processing(true));
      dispatch(_success(null));
      dispatch(_error(null));
        
         axios({
            url: apiBaseUrl(`vendor/v3/vendor/partner/categories/map`),
            method: "post",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){
                dispatch(_success(res));
                handleSuccess(res)
            }).catch(function(error){
                handleError(error);
                dispatch(_error(error));
            });
    }
}
export function createPartnerCategoryMapReset()
{
    return dispatch => dispatch({ type: `POST_PARTNER_CATEGORY_MAP_RESET` })
}

export default createPartnerCategoryMap;