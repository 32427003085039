import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import {scrollToError} from '../../../utils/ScrollUtils'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'POST_ADDON_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_ADDON_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_ADDON_PROCESSING', processing};
}

function createAddon(data) {
    return (dispatch, getState) => {
        let {main} = getState();
        let {user_role} = main.data;
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        delete data.trans;
        if(data.calorie === "" ) {
            data.calorie = 0;
        }

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${data.restaurantID}/addon`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/addon`
        }

        axios({
            // url:apiBaseUrl(`restaurant/v1/restaurant/${data.restaurantID}/addon`),
            url: apiBaseUrl(url),
            method: "post",
            dataType: 'json',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                handleSuccess(res)

            })
            .catch(function (error) {
                handleError(error);
                dispatch(_error(error));
                /*if ( error.response && error.response.status === 422 ) {
                  // store.dispatch( addonFormServerValidationErrors( error.response.data.message ))
                  scrollToError();
                }*/
            });
    }
}

export function createAddonReset() {
    return dispatch => dispatch({type: `POST_ADDON_RESET`})
}

export default createAddon;