import React from 'react';
import Div from "../../common/Div/Div";

const SearchBar = () =>
{
  return (

    <Div className="SearchBar">
      <Div className="right-block">
        <Div className="search-bar">
          <input type="text" className="form-control" placeholder="Search Product, Orders, Clients "/>
          <span className="icon-search" />
        </Div>
      </Div>
    </Div>

  )
};


export default SearchBar;