import axios from 'axios';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import {getCurrentDate} from "../../../utils/DateTimeUtils";
import { AppSource } from '../../../Config';

function _success(success)
{
  return { type: 'GET_RESTAURANT_REVIEWS_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_RESTAURANT_REVIEWS_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_RESTAURANT_REVIEWS_PROCESSING', processing };
}

function getRestaurantReviews( )
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));

    let {reviewList} = getState();
    let filters = reviewList.data.params;
// console.log(` <| filters |> `, filters)
    let config = {
      headers: { 'Authorization': 'Bearer ' + loginToken(), 'x-app-source': AppSource.appSource },
        params:{
            limit: urlParamValue(`limit`) ? urlParamValue(`limit`) : filters && filters.limit || 10,
            page: urlParamValue(`page`) ? urlParamValue(`page`) : filters && filters.page || 1,
            q: urlParamValue(`q`) ? urlParamValue(`q`) : filters && filters.q || ``,
            sort_column: urlParamValue(`sort_column`) ? urlParamValue(`sort_column`) : filters && filters.sort_column || 'id',
            sort_by: urlParamValue(`sort_by`) ? urlParamValue(`sort_by`) : filters && filters.sort_by || 'desc'
        }
    };
    // axios.get(apiBaseUrl(`restaurant/v1/admin/restaurant/review/pagination`), config)
    axios.get(apiBaseUrl(`vendor/v1/vendor/reviews`), config)
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));

      }).catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
      });
  }
}


export default getRestaurantReviews;