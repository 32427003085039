import {SalesReportState} from './SalesReport-State'
import {beginningOfThisMonth, getCurrentDate} from "../../../../utils/DateTimeUtils";
import {urlParamValue} from "../../../../utils/URLUtils";

export default function salesReportReducer(state = SalesReportState, action) {

    let {data} = {...state};
    let { name, value, start_date, end_date, mode, branch_id, parentID, countryID } = action;
    switch (action.type) {

        case 'TOGGLE-SALES-REPORT-FILTER':
            data.params[name] = value;
            return {...state, data};

        case 'ON-SALES-REPORT-DATE-CHANGE':
            data[name] = value;
            // let _current_date = value.split(' ');
            // data.month = _current_date[0]
            // data.year = _current_date[1]
            return {...state, data};

        case 'TOGGLE-SALES-REPORT-FILTER-SIDER':
            data.report_filter_mode = mode;
            data.report_filter_open = !data.report_filter_open;
            return {...state, data};

        case 'OPEN-CLOSE-SALES-REPORT-FILTER-SIDER':
            data.report_filter_open = !data.report_filter_open;
            return {...state, data};

        case 'SET-BRANCH-ID-FOR-REPORT':
            data.params.branch_id = branch_id;
            return {...state, data};

        case 'SET-BRANCH-ID-FOR-MONTHLY-REPORT':
            data.branch_id = branch_id;
            return {...state, data};

        case 'TOGGLE-SALES-REPORT-DATE-CHANGE':
            data.params.start_date = start_date;
            data.params.end_date = end_date;
            return {...state, data};

        case 'SET-PARENT-RESTAURANT-ID-FOR-REPORT':
            data.params.restaurant_id = parentID;
            return {...state, data};

        case 'SET-COUNTRY-ID-FOR-REPORT':
            data.params.country_id = countryID;
            return {...state, data};

        case 'SET-DEFAULT-SALES-REPORT-PARAMS':
            data = {
                params : {
                    start_date:  beginningOfThisMonth(),
                    end_date:getCurrentDate(),
                    branch_id: '',
                    order_status:  `accepted`,
                    payment_status: ``,
                    delivery_status:  ``,
                    delivery_method:  ``,
                    payment_method:  ``,
                    restaurant_id:  ``,
                    country_id: ``,
                    customer_info: false,
                    city_id:``,
                },
                report_filter_open:true,
                report_filter_mode:`parent`
            };
            return {...state, data};

        default:
            return state
    }
}
