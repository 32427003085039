import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import getRestaurant from "../../../ajax/actions/branch/restaurant_get";
import getRestaurantBranchDashboard from "../../../ajax/actions/dashboard-statistics/restaurant_branch_dashboard_get";
import orderSvg from "../../../assets/images/total_order.svg";
import fulfilOrderSvg from "../../../assets/images/fulfilled_order.svg";
import sarSvg from "../../../assets/images/revenue.svg";
import branchSvg from "../../../assets/images/branches.svg";
import taxSvg from "../../../assets/images/tax.svg";
import RestaurantHeader from "../../layout/Header/RestaurantHeader";
import GrowingLoader from "../../common/loaders/GrowingLoader";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const Home = () => {
    const reduxState = useSelector(state => state);
    const {userDetails, restaurantGet, restaurantBranchDashboardGet} = reduxState;
    let parent_restaurantID = userDetails?.success?.restaurant_id;
    const dispatch = useDispatch();

    useTitle(Title.name + ` Dashboard`);

    useEffect(() => {
        dispatch(getRestaurantBranchDashboard());
    }, [])

    useEffect(() => {
        parent_restaurantID && dispatch(getRestaurant(parent_restaurantID))
    }, [parent_restaurantID]);
    // console.log(` <| restaurantBranchDashboardGet |> `, restaurantBranchDashboardGet)

    let data = restaurantBranchDashboardGet && restaurantBranchDashboardGet.success && restaurantBranchDashboardGet.success.data.data;
    let _processing = restaurantBranchDashboardGet.processing;
    return (
        <>
            <RestaurantHeader title="Dashboard" icon="dashboard"/>
            <div className="plr-35">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Order</div>
                                    <div className="info-detail">{_processing ? <GrowingLoader /> : data.total_orders}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={fulfilOrderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Fulfilled Order</div>
                                    <div className="info-detail">{_processing ? <GrowingLoader /> : data.fulfilled_orders}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Revenue</div>
                                    <div className="info-detail">{ _processing ? <GrowingLoader /> : <>{data.currency}{data && data.total_revenue.toFixed(2)} </>}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-orange">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={branchSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Branches</div>
                                    <div className="info-detail">{_processing ? <GrowingLoader /> : data.restaurant_branch}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};

export default Home;