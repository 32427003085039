export default function getRestaurantBranchDashboardReducer(state = {success:'', error:'', processing:''},action)
{
 switch(action.type) {
   case 'GET_RESTAURANT_BRANCH_DASHBOARD_SUCCESS':
     return {...state, ...{success:action.success}}

   case 'GET_RESTAURANT_BRANCH_DASHBOARD_ERROR':
     return {...state, ...{error:action.error}}

   case 'GET_RESTAURANT_BRANCH_DASHBOARD_PROCESSING':
     return {...state, ...{processing:action.processing}}
   default: return state;  
 }
}