import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_RESERVATION_TIMESLOTS_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_RESERVATION_TIMESLOTS_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_RESERVATION_TIMESLOTS_PROCESSING', processing }
}


function getReservationTimeslots(restaurant_id ) {

    return (dispatch) => {

        dispatch(_processing(true));
        dispatch(_error(false));
        // dispatch(_success(false));

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurant_id}/day`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function(error){

                dispatch(_error(error));
                dispatch(_processing(false));
            });
    }
}

export default getReservationTimeslots;
