import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import sortFoodCategories from "../../../../ajax/actions/food-category/food_categories_sort_post_old";
import getFoodCategoriesSort from "../../../../ajax/actions/food-category/food_categories_sort_get";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import AddonCategoryDND from "./AddonCategoryDND";
import useAppState from "../../../../hooks/useAppState";
import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import createAddonCategorySort, {createAddonCategorySortReset} from "../../../../ajax/actions/addon-categories/addon_category_sort_post";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const AddonCategorySort = () => {
    let dispatch = useDispatch();
    const getRestaurantBranch = useAppState( s => s.getRestaurantBranch )
    const getAddonsCategoriesReducer = useAppState( s => s.getAddonsCategoriesReducer )
    const addonCategorySortPost = useAppState( s => s.addonCategorySortPost )
    const addonCategoryList = useAppState( s => s.addonCategoryList )
    const [branchID, setBranchID] = useState( `` )
    const [initialLoad, setInitialLoad] = useState( true )
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Addons Category Sort`);

    const {list} = addonCategoryList.data
    useEffect(() => {
        let details = {
            title: 'Addons Categories Sort',
            icon: 'addon_cat',
            action: false,
            url: `${url}`
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch( setIsList( false ) );
    }, [])

    useEffect(() => {
        addonCategorySortPost.success && dispatch(getAddonCategories(1000));
        dispatch( createAddonCategorySortReset() );
        setInitialLoad( false );
    }, [ addonCategorySortPost.success ])

    useEffect(() => {
        getRestaurantBranch.success && dispatch(getAddonCategories(1000));
        let branchID = getRestaurantBranch?.success?.data?.data?.id;
        setBranchID( branchID );
    }, [getRestaurantBranch.success])

    function onDragEnd(results) {
        if (!results) return;
        dispatch(createAddonCategorySort(results, branchID))
    }

    let {processing} = addonCategorySortPost;

    return (
        <>
            <div className={`col-sm-4 ${processing ? `item-disabled` : ``}`}>

                { ( getAddonsCategoriesReducer.processing && initialLoad ) && <div className="text-center"> <GrowingLoader/> </div>  }
                {list ?
                        <AddonCategoryDND list={list} onDragEnd={res => onDragEnd(res)}/> :
                        <p>Addons Categories not available</p>
                }
            </div>

        </>
    )
}

export default memo(AddonCategorySort);