import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import {useParams,} from "react-router";
import {useFormik} from "formik";
import * as Yup from "yup";
import SelectField from "../../../common/form/SelectField";
import {activeStatus} from "../../../../utils/StaticDataUtils";
import createAddon from "../../../../ajax/actions/addon/addon_post";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import updateAddon from "../../../../ajax/actions/addon/addon_put";
import AddonWorker from "./AddonWorker";
import MultiSelectField from "../../../common/form/MultiSelectField";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";
import { ArabicTranslate, EnglishTranslate } from '../../../../utils/GeneralUtils';

const AddonForm = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { addonCreate, addonForm, addonUpdate, getAddonsCategoriesReducer } = reduxState;
    let {id} = useParams();

    useTitle(Title.name + ` Addons`);

    let { trans} = addonForm.data;
    let fields = {...addonForm.data}

    const validationSchema = Yup.object().shape({
        price: Yup.number().typeError(' must be a number').required('is required'),
        calorie: Yup.number().typeError(' must be a number'),
        status: Yup.bool().required('is required'),
        addon_categories: Yup.array().required(` is required`),

        translation: Yup.object().shape({
            en: Yup.object().shape({
                name: Yup.string().required(` is required`),
                // description: Yup.string().required(` is required`),
            }),
            ar: Yup.object().shape({
                name: Yup.string().required(` is required`),
                // description: Yup.string().required(` is required`),
            })
        })
    });

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                if (id) {
                    dispatch(updateAddon(values, id))
                } else {
                    dispatch(createAddon(values))
                }

            }
        })

    const onSelectChange = (selectedOption) => {
        let value = [];
        let data = [];
        if (selectedOption) {
            selectedOption.map(s => {

                if (s.value) {
                    value.push(s.value)
                    data.push({id: s.value, name: s.label})
                }
            })
        }
        setFieldValue(`selected_addon_categories`, data);
        setFieldValue(`addon_categories`, value);
    };

    const onAddonTranslationFormChange = e => {

        let text = '';
        if (e.target.id === 'ar') {
            if (ArabicTranslate(e.target.value) === undefined) {
                return
            } else {
                text = ArabicTranslate(e.target.value)
            }
        } else {
            if (EnglishTranslate(e.target.value) === undefined) {
                return
            } else {
                
                text = EnglishTranslate(e.target.value);
            }
        }
        let {value, id, name} = e.target;
        /*setFieldValue({
            ...values,
            // the `key` is dynamic [id]:value
            new_name:value
        })*/
        setFieldValue( name, text )
    }

    // console.log(` <| addonForm values |> `, values)
    let processing = addonCreate.processing || addonUpdate.processing;

    let addon_categories_list = getAddonsCategoriesReducer?.success?.data?.data;

    let addon_category_option = [];
    addon_categories_list && addon_categories_list.map(list => {
        addon_category_option.push({value: list.id, label: list.name})
    })

    let selected_addoncate_list = [];
    values.selected_addon_categories && values.selected_addon_categories.map(c => {
        selected_addoncate_list.push({value: c.id, label: c.name})
    });
    let key_ctr = 0;
    return (
        <div className="plr-35 w-1000">
            <AddonWorker/>

            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                    {trans && trans.map((t) => {
                        key_ctr++;
                        return (<>
                            <div className="col-sm-6" key={key_ctr}>
                                <TextField
                                    name={`translation.${t.lang_code}.name`}
                                    id={t.lang_code}
                                    label={`Addon Name (${t.lang_code === 'ar' ? 'Arabic' : 'English'})`}
                                    placeholder={`Enter Addon Name on ${t.lang_code === 'ar' ? 'Arabic' : 'English'}`}
                                    onChange={onAddonTranslationFormChange}
                                    className={ t.lang_code === 'ar' ? 'text-translation' : ''}
                                    value={values.translation && values.translation[t.lang_code] && values.translation[t.lang_code].name}
                                    error={errors && errors.translation && errors.translation[t.lang_code] && errors.translation[t.lang_code].name}
                                    touched={touched && touched.translation && touched.translation[t.lang_code] && touched.translation[t.lang_code].name}
                                />
                                {/*<TextAreaField name={`translation.${t.lang_code}.description`}
                                               id={t.lang_code}
                                               placeholder={`Enter Description ${t.lang_code}`}
                                               label={`Description (${ucFirst(t.lang_code)})`}
                                               onChange={onAddonTranslationFormChange}
                                               value={values.translation && values.translation[t.lang_code] && values.translation[t.lang_code].description}
                                               error={errors && errors.translation && errors.translation[t.lang_code] && errors.translation[t.lang_code].description}
                                               touched={touched && touched.translation && touched.translation[t.lang_code] && touched.translation[t.lang_code].description}
                                />*/}
                            </div>
                        </>)
                    })}



                    <div className="col-sm-6">
                        <TextField
                            name="price"
                            placeholder="Price"
                            onChange={handleChange}
                            value={values.price}
                            error={errors && errors.price}
                            touched={touched.price}
                        />

                    </div>
                    <div className="col-sm-6">
                        <TextField
                            name="calorie"
                            placeholder="Calorie"
                            onChange={handleChange}
                            value={values.calorie}
                            error={errors && errors.calorie}
                            touched={touched.calorie}
                        />
                    </div>
                    <div className="col-sm-6">
                        <MultiSelectField
                            name="addon_categories"
                            options={addon_category_option}
                            onChange={onSelectChange}
                            label="Addon Categories"
                            value={selected_addoncate_list}

                            error={errors && errors.addon_categories}
                            touched={touched.addon_categories}
                        />
                    </div>
                    <div className="col-sm-6">
                        <SelectField
                            name="status"
                            options={activeStatus}
                            onChange={handleChange}
                            label="Status"
                            value={values.status}
                            _label="name"
                            _value="value"

                            error={errors && errors.status}
                            touched={touched.status}
                        />
                    </div>
                </div>
                <button type="submit"
                        className="btn-theme mt-10">{processing ?
                    <GrowingLoader/> : `${id ? "Update" : "Create"}`}</button>
            </form>
        </div>
    )
}
export default AddonForm;