export default function getRestaurantCategoriesReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { processing, success, error } = action;
    switch (action.type)
    {
        case 'GET_RESTAURANT_CATEGOPRIES_SUCCESS':
            return {...state, ...{success, processing:false, error:null }};

        case 'GET_RESTAURANT_CATEGOPRIES_ERROR':
            return {...state, ...{ error, processing:false, success:null }};

        case 'GET_RESTAURANT_CATEGOPRIES_PROCESSING':
            return {...state, ...{ processing }};

        case 'GET_RESTAURANT_CATEGOPRIES_RESET':
            return { ...state, ...{
                    success: null,
                    error: null
                }
            };
        default: return state;
    }
}