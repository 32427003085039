import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import getFoodCategoriesSort from "../../../../ajax/actions/food-category/food_categories_sort_get";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import FoodDND from "./FoodDND";
import useAppState from "../../../../hooks/useAppState";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";
import SelectField from "../../../common/form/SelectField";
import getFoodsByCategory from "../../../../ajax/actions/foods/foods_by_category_get";
import sortFoodPost from "../../../../ajax/actions/foods/sort_food_post";

const FoodSort = () => {
    let dispatch = useDispatch();
    const getRestaurantBranch = useAppState(s => s.getRestaurantBranch)
    const foodCategoriesSortGet = useAppState(s => s.foodCategoriesSortGet)
    const foodByCategoryGet = useAppState(s => s.foodByCategoryGet)
    const postSortFood = useAppState(s => s.postSortFood)
    const [branchID, setBranchID] = useState(``)
    const [foodCategory, setFoodCategory] = useState(``)
    const [initialLoad, setInitialLoad] = useState(true)
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Food Sort`);

    useEffect(() => {
        let details = {
            title: 'Food Sort',
            icon: 'foods',
            action: false,
            url: `${url}`
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch(setIsList(false));
    }, [])

    useEffect(() => {
        getRestaurantBranch.success && dispatch(getFoodCategoriesSort());
        let branchID = getRestaurantBranch?.success?.data?.data?.id
        setBranchID(branchID);
    }, [getRestaurantBranch.success])

    useEffect(() => {
        let branchID = getRestaurantBranch?.success?.data?.data?.id
        dispatch(getFoodsByCategory(branchID, foodCategory))
    }, [postSortFood.success])

    function onDragEnd(results) {
        if (!results) return;
        dispatch(sortFoodPost(results, branchID, foodCategory))
    }

    const handleChange = (e) => {
        setFoodCategory(e.target.value);
        dispatch(getFoodsByCategory(branchID, e.target.value))
    }

    let {processing} = postSortFood;

    let food_categories_list = foodCategoriesSortGet?.success?.data?.payload;
    let list = foodByCategoryGet?.success?.data?.data;

    return (
        <div className="plr-35 w-1000">
            <div className="row">
                <div className={`col-md-6`}>
                    <SelectField
                        name="food_category"
                        options={food_categories_list}
                        onChange={handleChange}
                        label="Food Category"
                        value={foodCategory}
                        _label="name"
                        _value="id"
                    />
                </div>
                <div className={`col-md-6 ${processing ? `item-disabled` : ``}`}>

                    {(foodByCategoryGet.processing && initialLoad) &&
                    <div className="text-center"><GrowingLoader/></div>}
                    {list ?
                        <FoodDND list={list} onDragEnd={res => onDragEnd(res)}/> :
                        <p>Foods not available for the given category, select the food category.</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(FoodSort);