import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from '../../../store'
import getAddonCategories from './addon_categories_get'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'DELETE_ADDON_CATEGORY_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_ADDON_CATEGORY_ERROR', error};
}

function _processing(processing, id) {
    return {type: 'DELETE_ADDON_CATEGORY_PROCESSING', processing, id};
}

function deleteAddonCategory(branchId, addonCategoryID) {
    return ( dispatch, getState ) => {
        let { main } = getState();
        let { user_role } = main.data;
        dispatch(_processing(true, addonCategoryID));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            },
        }

        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${branchId}/addon/category/${addonCategoryID}`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/category/${addonCategoryID}`
        }

        // axios.delete(apiBaseUrl(`restaurant/v1/restaurant/${branchId}/addon-category/${addonCategoryID}`), config)
        axios.delete(apiBaseUrl(url), config)
            .then(function (res) {
                dispatch(_success(res));
                handleSuccess(res);
                store.dispatch(getAddonCategories());

            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
        });
    }
}

export default deleteAddonCategory;