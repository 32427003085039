import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const OrderItems = ({orders, processing}) => {
    let items = orders?.orderItems?.data;
    let order = orders;
    let addon_count = 0;
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Order Detail</h6>
            <div className="order-detail-table table-responsive">
                {processing ? <GrowingLoader/> :
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Discount %</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items && items.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        <div className="product-name">{item.name}</div>
                                        <div className="product-attributes">
                                            {item.food_size ?
                                                <div className="single-attribute">Size: <span>{item.food_size}</span>
                                                </div> : null}

                                            {item.addons.data && item.addons.data.length ?
                                                <div className="single-attribute">Addons:<br/>
                                                    {item.addons.data && item.addons.data.length && item.addons.data.map((addon, key) => {
                                                        let separator = ",";
                                                        addon_count++;
                                                        if (item.addons.data.length === addon_count) {
                                                            separator = " "
                                                        }
                                                        return (<span
                                                            key={addon.id}>{addon.addon_name} ({order.currency_code + ' ' + addon.unit_price}){separator}</span>)
                                                    })}
                                                </div> : null}
                                            {item.special_instruction ?
                                                <div className="single-attribute">Special Instructions:<br/>
                                                    <span>{item.special_instruction}</span>
                                                </div> : null
                                            }
                                        </div>
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>{order && order.currency_code}{item.unit_price}</td>
                                    <td>{item.has_discount ? item.discount_percentage : '-'}</td>
                                    <td>{order && order.currency_code}{item.sub_total}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan="5">Subtotal: {order && order.currency_code}{order && order.subtotal}</td>
                        </tr>
                        <tr>
                            <td colSpan="5">VAT({order && order.site_tax_rate}%): {order && order.currency_code}{order && order.tax_amount}</td>
                        </tr>
                        <tr>
                            <td colSpan="5"><span>Grand Total: {order && order.currency_code}{order && order.grand_total}</span></td>
                        </tr>
                        </tfoot>
                    </table>
                }
            </div>
        </div>
    )
}

export default OrderItems;