import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../../common/table/Table";
import TableDataLoader from "../../../../common/loaders/TableDataLoader";
import {useParams, useRouteMatch} from "react-router";
import NoDataAvailable from "../../../../common/NotFound/NoDataAvailable";
import useAppState from "../../../../../hooks/useAppState";
import {
    setBranchTimeslotDayParams,
    setDefaultTimeslotDayParams,
    setRestaurantTimeslotParams, toggleTimeslotDayFilter,
    toggleTimeslotListFilter
} from "./TimeslotDay-Action";
import TimeslotDayItem from "./TimeslotDayItem";
import ReactTooltip from "react-tooltip";
import getReservationTimeslotDay from "../../../../../ajax/actions/reservations/reservation_timeslot_day_get";
import RestaurantHeader from "../../../../layout/Header/RestaurantHeader";
import {toggleFilterSidebar} from "../../../../layout/Main/Main-Actions";
import getReservationTimeslots from "../../../../../ajax/actions/reservations/reservation_timeslots_get";
import TimeslotDayForm from "./TimeslotDayForm";
import {fromRecord, inRecord} from "../../../../../utils/DataUtils";
import GrowingLoader from "../../../../common/loaders/GrowingLoader";
import {setIsList} from "../../../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../../../branches/Header/BranchSubHeader-Action";
import TimeslotHeader from "./TimeslotHeader";
import {useTitle} from "react-use";
import {Title} from "../../../../../Config";

const TimeslotDay = () => {

    const [currentTimeSlot, setCurrentTimeSlot] = useState(``);

    const reservationTimeslotDayGet = useAppState(s => s.reservationTimeslotDayGet)
    const reservationTimeslotsGet = useAppState(s => s.reservationTimeslotsGet)
    const reservationDayTimeslotStatusUpdate = useAppState(s => s.reservationDayTimeslotStatusUpdate)

    const timeslotDay = useAppState(s => s.timeslotDay)
    const userDetails = useAppState(s => s.userDetails)
    const getRestaurantBranch = useAppState(s => s.getRestaurantBranch)
    const main = useAppState(s => s.main)

    let {fields, params, mode, list, timeslot_modal_open} = timeslotDay && timeslotDay.data;
    let {url} = useRouteMatch();
    let {id} = useParams();
    let {restaurant_id, branch_id, currency} = params;
    let dispatch = useDispatch();
    let {filter_sidebar_open} = main.data;
    const [initialLoad, setInitialLoading] = useState(true);

    useTitle(Title.name + ` Reservation Timeslot Day`);

    useEffect(() => {
        dispatch(setDefaultTimeslotDayParams())


        return () => {
            filter_sidebar_open && dispatch(toggleFilterSidebar())
        }
    }, [])

    useEffect(() => {
        if (getRestaurantBranch.success) {
            dispatch(setBranchTimeslotDayParams(getRestaurantBranch.success.data.data))
        }
    }, [getRestaurantBranch.success])

    useEffect(() => {
        if (reservationDayTimeslotStatusUpdate.success) {
            branch_id && dispatch(getReservationTimeslots(params.branch_id))
            branch_id && dispatch(getReservationTimeslotDay(params.branch_id, id))
            setInitialLoading(false);
        }
    }, [reservationDayTimeslotStatusUpdate.success])

    useEffect(() => {
        if (reservationTimeslotsGet.success) {
            let timeslots = reservationTimeslotsGet && reservationTimeslotsGet.success.data.data;
            let current_slot = '';
            if (inRecord(timeslots, 'id', '==', +id)) {
                // console.log(` <| here |> `, timeslots)
                current_slot = fromRecord(timeslots, 'id', '==', +id);
                // console.log(` <| current_slot |> `, current_slot)
            }
            setCurrentTimeSlot(current_slot);

            let details = {
                title: `Timeslot Day of ${current_slot && current_slot.day}`,
                icon: 'order',
                action: false,
            }
            dispatch(setIsList(false));
            dispatch(setBranchHeaderDetails(details));
        }
    }, [reservationTimeslotsGet.success])

    useEffect(() => {
        branch_id && dispatch(getReservationTimeslots(params.branch_id))
        branch_id && dispatch(getReservationTimeslotDay(params.branch_id, id))
    }, [branch_id])

    const {success} = reservationTimeslotDayGet;
    // let list = success && success.data && success.data.data;

    /*let current_page = success && success.data && success.data.meta && success.data.meta.pagination.current_page;
    let last_page = success && success.data && success.data.meta &&  success.data.meta.pagination.total_pages;
    let total = success && success.data && success.data.meta && success.data.meta.pagination.total;*/
    // let current_page = success && success.data && success.data.data && success.data.data.current_page;
    // let last_page = success && success.data && success.data.data &&  success.data.data.last_page;
    // let total = success && success.data && success.data.data && success.data.data.total;
    // let pages = {current_page, last_page, total};
    return (
        <>
            {/*<RestaurantHeader title={`Timeslot Day of ${currentTimeSlot && currentTimeSlot.day}`} icon="order"
                              header="timeslot"/>*/}

            <TimeslotHeader/>
            <div className="table-block table-responsive pl-50">

                <Table reducer={reservationTimeslotDayGet}
                       params={params}
                       action={toggleTimeslotDayFilter}
                       columns={fields}>
                    <tbody>
                    {(reservationTimeslotDayGet.processing && initialLoad ) ? <TableDataLoader colspan={7}/> :
                        list && list.length ? list.map(order => {
                                return (
                                    <TimeslotDayItem key={order.id}
                                                     branch_id={branch_id}
                                                     timeslot={order}
                                                     reducer={reservationTimeslotDayGet}
                                                     slotReducer={reservationDayTimeslotStatusUpdate}
                                    />
                                )
                            }
                        ) : <NoDataAvailable colspan={10}/>}
                    </tbody>
                </Table>
            </div>
            {/*{list && list.length ? (
                <div className="fixed-footer">
                    <PaginationButtons pages={pages}
                                       fetchAction={toggleTimeslotListFilter}
                                       processing={reservationTimeslotsGet && reservationTimeslotsGet.processing}/>
                </div>
            ) : null}*/}
            {timeslot_modal_open ? <TimeslotDayForm current_day={currentTimeSlot}
                             currency={currency}
                             mode={mode}/> : null }
            <ReactTooltip backgroundColor="#BF9F60"/>
        </>
    )
}

export default TimeslotDay;