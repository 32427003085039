import axios from 'axios/index';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _success(success){
    return { type: 'PUT_ORDER_SUCCESS', success }
}
function _error(error){
    return { type:'PUT_ORDER_ERROR', error }
}
function _processing(processing){
    return { type: 'PUT_ORDER_PROCESSING', processing }
}

function updateOrder(data, id )
{
    return (dispatch, getState) => {

        dispatch( _processing( true ));
        dispatch( _success( null ));
        dispatch( _error( null ));

      axios({
        url: apiBaseUrl(`order/v1/branch/order/${id}`),
        method: "put",
        dataType: 'json',
        data: data,
        headers: {
          'Authorization': 'Bearer ' + loginToken(),
          'x-app-source': AppSource.appSource
        }
      })
        .then(res => {
          dispatch(_success(res));
          dispatch(_processing(false));
          handleSuccess( res )
        }).catch(error => {
          dispatch(_error(error));
          dispatch(_processing(false));
          handleError( error )
        });
    }
}

export default updateOrder;

