import getAddons from "../../../../ajax/actions/addon/addons_get";
import { useHistory } from "react-router-dom";
import {toggleURLQuery} from "../../../../utils/URLUtils";
export function toggleAddonListFilter(e, history) {
    return function ( dispatch, getState ) {
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-ADDON-LIST-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            dispatch( getAddons());
        }
    }
}

export function toggleAddonFilterOpen( ) {
    return function ( dispatch ) {
        dispatch({ type:'ADDON-FILTER-OPEN-CLOSE', });
    }
}

export function setDefaultAddonParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-ADDON-PARAMS', });
    }
}

export function setAddonSearchReset( history ) {
    return function ( dispatch,) {
        toggleURLQuery( 'q', `` , history)
        dispatch({type: 'SET-ADDON-SEARCH-RESET', });
    }
}