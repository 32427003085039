import axios from 'axios/index';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'POST_ADDON_CATEGORY_SORT_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ADDON_CATEGORY_SORT_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ADDON_CATEGORY_SORT_PROCESSING', processing };
}

function createAddonCategorySort( data, id )
{
    return ( dispatch, getState ) =>
    {
        let { main } = getState();
        let { user_role } = main.data;
      dispatch(_processing(true));
      dispatch(_error(null));
      dispatch(_success(null));

        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${ id }/addon/category/sort`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/category/sort`
        }
        let datas = {
            addon_category_info:{...data}
        }
      axios({
        url:apiBaseUrl(url),
        method: "post",
        dataType: 'json',
        data: datas,
        headers: {
            'Authorization': 'Bearer ' + loginToken(),
             'x-app-source': AppSource.appSource
        }
      })
      .then(function(res){
          dispatch(_success(res));
          handleSuccess(res);
      }).catch(function(error){
          handleError(error);
          dispatch(_error(error));
      });
    }
}
export function createAddonCategorySortReset()
{
    return dispatch => dispatch({ type: `POST_ADDON_CATEGORY_SORT_RESET` })
}

export default createAddonCategorySort;