import React, {useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router";
import {toggleFilterSidebar} from "../../../layout/Main/Main-Actions";
import {reservationStatusOptions} from "../../../../utils/StaticDataUtils";
import SelectField from "../../../common/form/SelectField";
import updateReservationStatus from "../../../../ajax/actions/reservations/reservation_status_put";

const ReservationStatusUpdate = ( props ) => {

    const reduxState = useSelector(state => state);
    let history = useHistory();
    const { main, userDetails } = reduxState;
    const [ status, setStatus ] = useState(``);
    const {filter_sidebar_open } = main.data;
    useEffect(() => {
        let { reservation } = props;
        setStatus( reservation && reservation.reservation_status )
    }, []);


    let dispatch = useDispatch();
    const closeFilterSidebar = () => {
        dispatch(toggleFilterSidebar())
    }

    const changeStatus = (e) => {
        setStatus( e.target.value )
    }

    const submitReservationStatus = ( ) => {
        let { reservation } = props;
        let data = {
            branch_id: reservation && reservation.restaurant_branch_id,
            reservation_status: status,
        }
        dispatch( updateReservationStatus( data, reservation && reservation.id ) )
    }

    return (<div className={`filter-sidebar ${filter_sidebar_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Actions</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">

                    <SelectField name="reservation_status"
                                 options={reservationStatusOptions}
                                 onChange={ e => changeStatus(e) }
                                 _value="value"
                                 _label="label"
                                 value={status}
                    />
                    <a href="javascript:void(0)" className="btn-theme mt-20" onClick={submitReservationStatus }>Update</a>
                </form>
            </div>
        </div>
    </div>)
}

export default ReservationStatusUpdate;