export const foodSelectFormChange = ( name, value )   => dispatch => dispatch({ type: `FOOD-SELECT-FORM-CHANGE`, name,value } );
export function setFood( foods )  {
    return function (dispatch) {
        let food = foods && foods.data && foods.data.data;
        dispatch({type: `SET-FOOD`, food});
    }
}
export const setDefaultFoodForm = ()         => dispatch => dispatch({ type: `SET-DEFAULT-FOOD-FORM` } );
export const setBranchDataFood = ( branchData )         => dispatch => dispatch({ type: `SET-BRANCH-DATA-FOOD`, branchData:branchData.data.details } );

export const addMoreFoodSize = () => {
    return function ( dispatch ) {
        dispatch({ type:'ADD-MORE-FOOD-SIZE' })
    }
}

export const removeFoodSize =(food_size_id) => {
    return function ( dispatch ) {
        dispatch({ type:'REMOVE-FOOD-SIZE', food_size_id })
    }
}


export const counterFoodSize = (values) => {
    let food_size = values.food_size
    if (!food_size.length) {
        return -1
    } else {
        let _id = 0
        food_size && food_size.length && food_size.map((item, i) => {
            if (item._id > _id) {
                _id = item._id
            }
        })
        return _id
    }
}