import React, {Suspense, lazy} from 'react'
import {
    Router, Route, Switch
} from "react-router-dom";
// import Main from "../Main/Main";
import {history} from "../../../store";
import {loggedIn} from "../../../utils/LocalStorageUtils";
import useScreenResize from "../../../hooks/useScreenResize";
import Div from "../../common/Div/Div";
// import Login from "../../modules/login/Login";
import 'animate.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import Alert from 'react-s-alert';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Main = React.lazy(() => import('../Main/Main'));
const Login = React.lazy(() => import('../../modules/login/Login'));
toast.configure();
const App = () => {
    useScreenResize()

    return (

        <Div className="App">

            <Router history={history}>
                <Suspense fallback={<div>Loading...</div>}>
                    {
                        !loggedIn() ? (

                            <Switch>
                                <Route key="/login" path="/" component={Login}/>
                                <Route exact key="/login" path="/login" component={Login}/>
                            </Switch>

                        ) : <Main/>
                    }
                </Suspense>
            </Router>
            <Alert stack={{limit: 1}}/>
        </Div>
    );
};

export default App;
