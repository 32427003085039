import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import SelectField from "../../../common/form/SelectField";
import {limitOptions} from "../../../../utils/StaticDataUtils";
import {useHistory, useRouteMatch} from "react-router";
import {
    setAddonCategorySearchReset,
    setDefaultAddonCategoryParams,
    toggleAddonCategoryFilterOpen,
    toggleAddonCategoryListFilter
} from "./AddonCategoryList-Action";
import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import SearchTextField from "../../../common/form/SearchTextField";
import {setAddonSearchReset} from "../../addons/list/AddonList-Action";

const AddonCategoryFilter = () => {

    const reduxState = useSelector(state => state);
    let history = useHistory();
    const {main, userDetails, addonCategoryList} = reduxState;
    let {params, addon_category_filter_open} = addonCategoryList && addonCategoryList.data;
    const {filter_sidebar_open,} = main.data;
    let parent_restaurantID = userDetails?.success?.restaurant_id;
    // console.log(` <| order_filter_mode |> `,order_filter_mode )
    let {url} = useRouteMatch()

    let dispatch = useDispatch();
    const closeFilterSidebar = () => {
        dispatch(toggleAddonCategoryFilterOpen())
    }

    const addonListFilterToggle = (e) => {
        dispatch(toggleAddonCategoryListFilter(e, history))
    }

    const addonFilterSubmit = () => {
        dispatch(getAddonCategories());
        closeFilterSidebar();
    }

    const onResetAddonCategorySearch = () => {
        dispatch(setAddonCategorySearchReset( history ))
    }

    const addonFilterSetDefault = () => {
        dispatch(setDefaultAddonCategoryParams());
        history.push(url);
        closeFilterSidebar();
        dispatch(getAddonCategories())
    }
// console.log(` <| branchList |> `,branchList )
    return (<div className={`filter-sidebar ${addon_category_filter_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Filter</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">
                    {/*<TextField name="q"
                               placeholder="Search"
                               label="Search"
                               value={params.q}
                               onChange={addonListFilterToggle}/>*/}

                    <SearchTextField name="q"
                                     placeholder="Search"
                                     label="Search"
                                     value={params.q}
                                     onReset={onResetAddonCategorySearch}
                                     onChange={addonListFilterToggle}/>

                    <SelectField name="limit"
                                 options={limitOptions}
                                 onChange={addonListFilterToggle}
                                 _value="total"
                                 _label="label"
                                 value={params.limit}
                    />
                    <a href="javascript:void(0)" className="btn-theme btn-outline mt-40"
                       onClick={addonFilterSetDefault}>Clear Filter</a>
                    <a href="javascript:void(0)" className="btn-theme mt-20" onClick={addonFilterSubmit}>Filter</a>
                </form>
            </div>
        </div>
    </div>)
}

export default AddonCategoryFilter;