import React from 'react';

class DotDotDot extends React.Component
{
    render()
    {
        return <span className="dotdotdot">...</span>
    }
}


export default DotDotDot;