export default function indexPartnerIntegrationReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { processing, success, error, id } = action;
    switch (action.type)
    {
        case 'INDEX_PARTNER_INTEGRATION_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'INDEX_PARTNER_INTEGRATION_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'INDEX_PARTNER_INTEGRATION_PROCESSING':
            return { ...state, ...{ processing, id }};

        default: return state;
    }
}