import React from "react";
import DateRangeFieldNew from "../../common/form/DateRangeFieldNew";
import {useDispatch, useSelector} from "react-redux";
import {toggleFilterSidebar} from "../../layout/Main/Main-Actions";
import {Link} from "react-router-dom";
import moment from 'moment';
import {toggleOrderDateChange,} from "./OrderList-Action";
import getOrders from "../../../ajax/actions/Orders/orders_get";
import {useHistory} from "react-router";

const OrderHeader = ({orderFilterMode}) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const reduxState = useSelector(state => state);
    const {orderList,} = reduxState;
    let {params} = orderList && orderList.data;

    const toggleFilter = () => {
        dispatch(toggleFilterSidebar(orderFilterMode))
    }
    const handleEvent = (event, picker) => {
        let start_date = moment(picker.startDate).format('YYYY-MM-DD');
        let end_date = moment(picker.endDate).format('YYYY-MM-DD');
        // console.log(` <| start date |> `,moment(picker.startDate).format('YYYY-MM-DD') )
        // console.log(` <| end date |> `,moment(picker.endDate).format('YYYY-MM-DD') )
        dispatch(toggleOrderDateChange(start_date, end_date, history))
    }

    const dateApply = (event, picker) => {
        dispatch(getOrders(params))

    }
    return (
        <>
            <div className="calendar-block">
                {/*<input type="text" name="" className="form-control" id="datepick"/>*/}
                <DateRangeFieldNew handleEvent={handleEvent} enddate={params.end_date} startdate={params.start_date}
                                   onApply={dateApply}/>
                <span className="icon-calendar"/>
            </div>
            <div className="button-wrapper">
                <a href="javascript:void(0)" onClick={toggleFilter} className="btn-theme" id="btn-filter">Filters</a>
            </div>
        </>
    )
}

export default OrderHeader;