import axios from 'axios';
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {loginToken} from '../../../../utils/LocalStorageUtils'
import store from '../../../../store'
import {handleError, handleSuccess} from "../../../../utils/ErrorUtils";
import { AppSource } from '../../../../Config';

function _success(success) {
    return {type: 'UPDATE_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'UPDATE_USER_ERROR', error};
}

function _processing(processing) {
    return {type: 'UPDATE_USER_PROCESSING', processing};
}

function updateUser( values, id ) {

    return (dispatch, getState) => {
        dispatch(_processing(true));
        // let {payload} = getState().loginForm.data;

        axios({

            url: apiBaseUrl(`auth/v1/user/update/${id}`),
            method: "put",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
            });
    }
}
export default updateUser;