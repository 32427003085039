import axios from 'axios/index';
import { apiBaseUrl } from '../../../../utils/URLUtils'
import { loginToken } from '../../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'INDEX_PARTNER_INTEGRATIONS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'INDEX_PARTNER_INTEGRATIONS_ERROR', error };
}
function _processing(processing, id)
{
    return { type: 'INDEX_PARTNER_INTEGRATIONS_PROCESSING', processing, id};
}

function getPartnerIntegrationIndex( payload )
{
    return ( dispatch ) =>
    {
      dispatch(_processing(true, payload.id));
      dispatch(_success(null));
      dispatch(_error(null));

        axios({
            url: apiBaseUrl(`auth/v2/vendor/partner/${payload.partner_id}/restaurant/${payload.restaurant_id}/branch/${payload.branch_id}/integration/index-menu`),
            method: "get",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){
                dispatch(_success(res));
                handleSuccess(res)
            }).catch(function(error){
                handleError(error);
                dispatch(_error(error));
            });
    }
}
export function getPartnerIntegrationIndexReset()
{
    return dispatch => dispatch({ type: `INDEX_PARTNER_INTEGRATIONS_RESET` })
}

export default getPartnerIntegrationIndex;