import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import SelectField from "../../../common/form/SelectField";
import {foodTypeOptions, limitOptions} from "../../../../utils/StaticDataUtils";
import {useHistory, useRouteMatch} from "react-router";
import {setDefaultFoodParams, setFoodSearchReset, toggleFoodFilterOpen, toggleFoodListFilter} from "./FoodList-Action";
import getFoods from "../../../../ajax/actions/foods/foods_get";
import SearchTextField from "../../../common/form/SearchTextField";
import useAppState from "../../../../hooks/useAppState";
import getFoodCategoriesSort from "../../../../ajax/actions/food-category/food_categories_sort_get";

const FoodFilter = (props) => {

    const reduxState = useSelector(state => state);
    const {breakfast} = props;
    let history = useHistory();

    const main = useAppState( s => s.main);
    const userDetails = useAppState( s => s.userDetails);
    const foodList = useAppState( s => s.foodList);
    const getRestaurantBranch = useAppState( s => s.getRestaurantBranch);

    let {params, food_filter_open, food_categories_list } = foodList && foodList.data;
    const {filter_sidebar_open, } = main.data;
    let parent_restaurantID = userDetails && userDetails.success && userDetails.success.restaurant_id;
    // console.log(` <| order_filter_mode |> `,order_filter_mode )
    let { url } = useRouteMatch()

    useEffect( () => {
        // getRestaurantBranch.success &&
        dispatch( getFoodCategoriesSort( ) );
    },[getRestaurantBranch.success]) //getRestaurantBranch.success

    let dispatch = useDispatch();
    const closeFilterSidebar = () => {
        dispatch(toggleFoodFilterOpen())
    }

    const foodListFilterToggle = (e) => {
        dispatch(toggleFoodListFilter(e, history))
    }

    const foodFilterSubmit = () => {
        dispatch( getFoods( ) );
        closeFilterSidebar();
    }

    const onResetFoodSearch = () => {
        dispatch(setFoodSearchReset( history ))
    }

    const foodFilterSetDefault = () => {
        dispatch( setDefaultFoodParams( ) );
        history.push( url );
        closeFilterSidebar();
        dispatch( getFoods(  ) )
    }
// console.log(` <| branchList |> `,branchList )
    return (<div className={`filter-sidebar ${food_filter_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Filter</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">
                    <SearchTextField name="q"
                                     placeholder="Search"
                                     label="Search"
                                     value={params.q}
                                     onReset={onResetFoodSearch}
                                     onChange={foodListFilterToggle}/>

                    <SelectField name="limit"
                                 options={limitOptions}
                                 onChange={foodListFilterToggle}
                                 _value="total"
                                 _label="label"
                                 value={params.limit}
                    />

                    { breakfast === 'enabled' ? <SelectField name="food_type"
                                                            options={foodTypeOptions}
                                                            onChange={foodListFilterToggle}
                                                            _value="value"
                                                            _label="name"
                                                            value={params.food_type}
                    /> : null }

                    <SelectField name="category_id"
                                 label="Food Category"
                                 options={food_categories_list}
                                 onChange={foodListFilterToggle}
                                 _value="id"
                                 _label="name"
                                 value={params.category_id}
                    />

                    <a href="javascript:void(0)" className="btn-theme btn-outline mt-40" onClick={foodFilterSetDefault}>Clear Filter</a>
                    <a href="javascript:void(0)" className="btn-theme mt-20" onClick={foodFilterSubmit }>Filter</a>
                </form>
            </div>
        </div>
    </div>)
}

export default FoodFilter;