export const tagsByColor = [
  { color: `gray`, strings: [ `inactive`, `default`, `old`, `N/A`, `no` ] },
  { color: `#DE3D39`, strings: [ `danger`,` error`, `canceled`, `cancelled`, `refunded`, `closed`, `unpaid`, `failed`, `unprocessed`, `rejected`, `out-of-stock` ] },
  { color: `#17A57F`, strings: [ `success`, `approved`, `completed`, `delivered`, `accepted`, `current`, `restaurant-open`,`open`, `active`, `paid`, `new`, `redirected`, `in-stock`, 'yes' ] },
  { color: `#F6B237`, strings: [ `warning`, `pending`, `processing`, `new` ] },
  { color: `#2db7f5`, strings: [ `info`, `on-hold`, `assigned`, `reassigned`, `normal`, `processed`, `non-redirected` ] },
  { color: `#722ed1`, strings: [ `picked`, `is_default`, `pre-order` ] },
]

export const tagsByStatus = [
  { status: `default`, strings: [  `default`, `old`, `N/A`,`is_default`,  ] },
  { status: `danger`, strings: [ `danger`,`inactive`,` error`,`no`, `canceled`, `cancelled`, `refunded`, `closed`, `unpaid`, `failed`, `unprocessed`, `rejected`, `out-of-stock`, 'false' ] },
  { status: `success`, strings: [ `success`, `approved`, `completed`, `delivered`, `accepted`, `current`, `restaurant-open`,`open`, `active`, `paid`, `new`, `redirected`, `in-stock`, 'yes', 'true' ] },
  { status: `warning`, strings: [ `warning`, `pending`, `processing`, `new` ] },
  { status: `info`, strings: [ `info`, `on-hold`, `assigned`, `reassigned`, `normal`, `processed`, `non-redirected` ] },
  { status: `primary`, strings: [ `picked`,  `pre-order` ] },
]