import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {ucFirstAll} from "../../../../utils/StringUtils";

const OrderCorporateCustomerDetails = ({orders, processing }) => {
    let order = orders && orders.corporate_customer;
    // let shipping_address = orders && orders.shipping_address;

    return (
        order !== null ?
        <div className="box-colored bg-light-gray pb-10">
            <h6>Corporate Customer Detail</h6>
            { processing ? <GrowingLoader /> :
             <>
                 <div className="single-info">
                     <div className="info-title">Customer Name</div>
                     <div className="info-detail">{order && ucFirstAll(order.customer_name)}</div>
                 </div>
                 <div className="single-info">
                     <div className="info-title">Phone Number</div>
                     <div className="info-detail">{order && order.phone_no}</div>
                 </div>
                 <div className="single-info">
                     <div className="info-title">Customer Type</div>
                     <div className="info-detail">{order && order.customer_type}</div>
                 </div>
             </>
            }
        </div> : null
    )
}

export default OrderCorporateCustomerDetails;