import getOrders from "../../../ajax/actions/Orders/orders_get";
import {toggleURLQuery} from "../../../utils/URLUtils";

export function toggleOrderListFilter(e, history) {

    return function ( dispatch, getState ) {
        let { orderList } = getState();
        let { params } = orderList && orderList.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-ORDER-LIST-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            dispatch( getOrders( params ));
        }
    }
}

export function toggleOrderDateChange(start_date, end_date, history) {
    return function( dispatch  ) {
        toggleURLQuery( 'start_date', start_date, history );
        toggleURLQuery( 'end_date', end_date, history );
        // let { name, value } = e.target;
        dispatch({type:'TOGGLE-ORDER-DATE-CHANGE', start_date, end_date})
    }
}

export function setDefaultOrderParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-ORDER-PARAMS', });
    }
}

export function setBranchOrderParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-BRANCH-ORDER-PARAMS', param:params });
    }
}

export function setBranchID( branchID ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-ORDERLIST-BRANCH-ID', branchID });
    }
}

export function setRestaurantOrderParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-RESTAURANT-ORDER-PARAMS', param:params });
    }
}