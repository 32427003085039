import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {setFood, setBranchDataFood, setDefaultFoodForm} from "./FoodForm-Actions";
import {urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import getFood, {getFoodReset} from "../../../../ajax/actions/foods/food_get";
import {createFoodReset} from "../../../../ajax/actions/foods/food_post";
import {updateFoodReset} from "../../../../ajax/actions/foods/food_put";
import getFoodCategoriesSort from "../../../../ajax/actions/food-category/food_categories_sort_get";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {toast} from "react-toastify";
import {ConfigToastAlert} from "../../../../Config";

const FoodWorker = () =>
{
    let dispatch = useDispatch();
    const history = useHistory();
    const reduxState = useSelector(state => state);
    const { foodCreate,  branchInfoReducer, foodGet, foodUpdate } = reduxState;
    let { id } = useParams();

    useEffect(() => {
        let details = {
            title:`${id ? "" : "Create"} Food`,
            icon: 'foods',
            id:id || ``,
            action: false,
        }
        if( !id || id === 'undefined' ) {
            dispatch( setDefaultFoodForm() );
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch( setIsList( false ) );
        return () => {
            dispatch(getFoodReset())
        }
    }, [])
    let branch_id = branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
    useEffect(() => {
        if( branchInfoReducer ) {
            // let branch_id = branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
            dispatch( setBranchDataFood( branchInfoReducer ) );
            if( branch_id ) {
                dispatch( getAddonCategories( 100 ) );
                dispatch(getFoodCategoriesSort(  ))
            }
            if( id ) {
                branch_id && dispatch( getFood( branch_id, id ) )
            }
        }
    }, [ branch_id] );

    useEffect(() => {
        if ( foodCreate && foodCreate.success ) {
            dispatch( createFoodReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ foodCreate ])

    useEffect(() => {
        if ( foodUpdate && foodUpdate.success ) {
            dispatch( updateFoodReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ foodUpdate ])

    useEffect(() => {
        foodGet.success && dispatch(setFood( foodGet.success ))
        if( foodGet?.success?.status === 204 ){
            toast.success(`Data not available `, ConfigToastAlert );
        }
    },[foodGet])

    return ( null)
}
export default FoodWorker;