export default function getFoodCategoriesOfCountryReducer
  (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'GET_FOOD_CATEGORIES_OF_COUNTRY_SUCCESS':
            return {...state, ...{ success: action.success }};

        case 'GET_FOOD_CATEGORIES_OF_COUNTRY_ERROR':
            return {...state, ...{ error: action.error }};

        case 'GET_FOOD_CATEGORIES_OF_COUNTRY_PROCESSING':
            return {...state, ...{ processing: action.processing }};

        default: return state;
    }
}