import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../../common/form/TextField";
import {useParams,} from "react-router";
import {useFormik} from "formik";
import * as Yup from "yup";
import SelectField from "../../../../common/form/SelectField";
import {activeStatus} from "../../../../../utils/StaticDataUtils";
import GrowingLoader from "../../../../common/loaders/GrowingLoader";

import MultiSelectField from "../../../../common/form/MultiSelectField";
import {useTitle} from "react-use";
import {Title} from "../../../../../Config";
import updatePartnerIntegration from '../../../../../ajax/actions/partner/integration/partner_integration_put';
import createPartnerIntegration from '../../../../../ajax/actions/partner/integration/partner_integration_post';
import PartnerIntegrationFormWorker from './PartnerIntegrationFormWorker';
import { onFormModeChangeUpdate, togglePartnerIntegrationForm } from '../list/PartnerIntegrationList-Action';
import getPartnerWithType from '../../../../../ajax/actions/partner/integration/partner_with_type_get';
import getRestaurantBranchList from '../../../../../ajax/actions/branch/get_restaurant_branch_list';
import getPartnerExternalBranches from '../../../../../ajax/actions/partner/integration/partner_external_branches_get';
import getPartnerExternalGroupMenu from '../../../../../ajax/actions/partner/integration/partner_external_group_menus_get';
import { setDefaultPartnerIntegrationForm } from './PartnerIntegrationForm-Actions';

const PartnerIntegrationForm = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { PartnerIntegrationFormReducer, createPartnerIntegrationReducer, userDetails,
        updatePartnerIntegrationReducer, partnerIntegrationListReducer, getPartnerWithTypeReducer,
        restaurantBranchList, getPartnerExternalBranchesReducer, getPartnerExternalGroupMenusReducer } = reduxState;
    let { id } = useParams();
    
    const [partners, setPartners] = useState([]);
    const [branches, setBranches] = useState([]);
    const [externalBranches, setExternalBranches] = useState([]);
    const [externalMenus, setExternalMenus] = useState([]);
    const [isFoodics, setIsFoodics] = useState(false);

    useTitle(Title.name + ` Partner Integration`);

    let fields = { ...PartnerIntegrationFormReducer.data }

    const restaurant_id = userDetails && userDetails.success && userDetails.success.restaurant_id;
    const { partner_modal_open, is_update } = partnerIntegrationListReducer.data;

    React.useEffect(() => {
        if (partner_modal_open ) {
            setFieldValue('restaurant_id', restaurant_id)
            dispatch(getRestaurantBranchList(restaurant_id))
            dispatch( getPartnerWithType( restaurant_id ) )
        }
    }, [partner_modal_open])

    React.useEffect(() => {
        const _branches = restaurantBranchList.success && restaurantBranchList.success.data.data
        const branch_options = _branches && _branches.reduce((acc, ite) => {
            return [ ...acc, { value:ite.id, label:ite.branch_name }]
        }, [])
        setBranches( branch_options )
    }, [restaurantBranchList])
    
    React.useEffect(() => {
        const external = getPartnerExternalBranchesReducer.success && getPartnerExternalBranchesReducer.success.data.payload;
        const options = external && external.reduce((acc, ite) => {
            return [ ...acc, { value: ite.id, label: ite.name}]
        }, [])
        setExternalBranches(options);
    }, [getPartnerExternalBranchesReducer])
    
    React.useEffect(() => {
        const external = getPartnerExternalGroupMenusReducer.success && getPartnerExternalGroupMenusReducer.success.data.payload;
        const options = external && external.reduce((acc, ite) => {
            return [ ...acc, { value: ite.id, label: ite.name}]
        }, [])
        setExternalMenus(options);
    },[getPartnerExternalGroupMenusReducer])
    
    React.useEffect(() => {
        if (getPartnerWithTypeReducer.success) {
            const success = getPartnerWithTypeReducer.success && getPartnerWithTypeReducer.success.data.data;
            const partner_options = success && success.reduce( (acc, ite) => {
                return [...acc, {value: ite.id, label: ite.name}]
            },[])
            setPartners(partner_options);
        }
    },[getPartnerWithTypeReducer])
    
    const doesPartnerIDMatches = (partnerID) => {
        // Simulate partner existence check
        if (partnerID) {
            
            const _partner = partners.find(item => item.label === 'Foodics');
            
            if (_partner && _partner.value === partnerID) return true;
        } return false
    };

    const validationSchema = Yup.object().shape({
        branch_id: Yup.number().typeError(' must be a number').required('is required'),
        partner_id: Yup.number().typeError(' must be a number').required('is required'),
        external_group_menu_id: Yup.string().when('partner_id', {
            is: (item) => item && doesPartnerIDMatches(item),
            then: Yup.string().required(' is required'),
            otherwise: Yup.string() 
        }),
    });

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                if (is_update) {
                    dispatch(updatePartnerIntegration(values))
                } else {
                    dispatch(createPartnerIntegration(values))
                }

            }
        })
    
    const handlePartnerChange = ( e ) => {
        const { value, name } = e.target;
        if (value) {
            dispatch(getPartnerExternalBranches(+value, restaurant_id, { type: 'foodics' }))
            dispatch(getPartnerExternalGroupMenu(+value, restaurant_id, { type: 'foodics' }))
            if (doesPartnerIDMatches(+value)) {
                setIsFoodics( true )
            } else { setIsFoodics(false)}
        }
        setFieldValue( [name], value )
    }
    
    const toggle = () => {
        dispatch(togglePartnerIntegrationForm())
        dispatch( setDefaultPartnerIntegrationForm() );
        dispatch( onFormModeChangeUpdate(false) )
    }

    let processing = createPartnerIntegrationReducer.processing || updatePartnerIntegrationReducer.processing;
    
    return (
        <div className={`filter-sidebar ${partner_modal_open ? `filter-active` : null}`}>
            <div className="filter-sidebar-wrapper">
                <div className="filter-heading-block">
                    <h2>Partner Integration</h2>
                    <span className="icon-close" onClick={toggle}/>
                </div>
                <div className="filter-form-block">
                    
                    <PartnerIntegrationFormWorker/>

                    <form action="" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12">
                                <SelectField
                                    name="branch_id"
                                    options={branches}
                                    onChange={handleChange}
                                    label="Branches"
                                    value={values.branch_id}
                                    disabled={ is_update }
                                    _label="label"
                                    _value="value"

                                    error={errors && errors.branch_id}
                                    touched={touched.branch_id}
                                />
                            </div> 
                            <div className="col-sm-12">
                                <SelectField
                                    name="partner_id"
                                    options={partners}
                                    onChange={handlePartnerChange}
                                    label="Partner"
                                    value={values.partner_id}
                                    disabled={ is_update }
                                    _label="label"
                                    _value="value"

                                    error={errors && errors.partner_id}
                                    touched={touched.partner_id}
                                />
                            </div> 
                            <div className="col-sm-12">
                                {isFoodics?
                                    <SelectField
                                        name="external_branch_id"
                                        options={externalBranches}
                                        onChange={handleChange}
                                        label="External Branch ID"
                                        value={values.external_branch_id}
                                        _label="label"
                                        _value="value"

                                        error={errors && errors.external_branch_id}
                                        touched={touched.external_branch_id}
                                    /> :
                                    <TextField
                                        name="external_branch_id"
                                        placeholder="External Branch ID"
                                        label="External Branch ID"
                                        onChange={handleChange}
                                        value={values.external_branch_id}
                                        error={errors && errors.external_branch_id}
                                        touched={touched.external_branch_id}
                                    />
                                }
                            </div> 
                            <div className="col-sm-12">
                                {isFoodics ?
                                    <SelectField
                                        name="external_group_menu_id"
                                        options={externalMenus}
                                        onChange={handleChange}
                                        label="External Group Menu ID"
                                        value={values.external_group_menu_id}
                                        _label="label"
                                        _value="value"

                                        error={errors && errors.external_group_menu_id}
                                        touched={touched.external_group_menu_id}
                                    /> :
                                    <TextField
                                        name="external_group_menu_id"
                                        placeholder="External Group Menu ID"
                                        label="External Group Menu ID"
                                        onChange={handleChange}
                                        value={values.external_group_menu_id}
                                        error={errors && errors.external_group_menu_id}
                                        touched={touched.external_group_menu_id}
                                    />
                                }

                                
                            </div>
                            
                        </div>
                        <button type="submit"
                                className="btn-theme mt-10">{processing ?
                            <GrowingLoader/> : `${is_update ? "Update" : "Create"}`}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PartnerIntegrationForm;