import React, {useState} from 'react';
import moment from 'moment';
import {names} from "../../../utils/StringUtils";
import {labels, placeholders} from "../../../utils/FormUtils";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const DateRangeFieldNew = (props) => {
    // const[locale, setLocale] = useState({format:'MMM D, Y'})

    const handleCallback = (start, end, label) => {
        // console.log(start, end, label);
        // console.log(` <| start, end, label |> `, start, end, label )
    }
    // console.log(` <|moment(props.startdate,"MM D, YYYY")  |> `, moment(props.startdate, 'Y-M-D').format('MM/DD/YYYY'))
    let startDate = moment(props.startdate, 'Y-M-D').format('MM/DD/YYYY');
    let endDate = moment(props.enddate, 'Y-M-D').format('MM/DD/YYYY');
    return (

        <div
            className={names(`form-group mb-0`, props.errors && props.errors.length && `has-error`, props.required && `required`)}>

            {!props.noLabel ? <>{labels(props.name, props.label, props.errors)}</> : null}

            <DateRangePicker opens='left'
                             // startDate={moment().startOf('day')}
                             initialSettings={{ startDate: startDate, endDate: endDate }}
                             onEvent={props.handleEvent}
                             // endDate={moment().endOf('day').add(2, 'day')}
                             onApply={ props.onApply }
                             locale={{
                                 format: 'MMM D, YYYY'
                             }}>
                <input type="text" className="form-control"/>
            </DateRangePicker>
        </div>
    )
}
export default DateRangeFieldNew;