import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from "../../../utils/URLUtils";
import {handleError} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'GET_RESTAURANT_BRANCH_LIST_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_RESTAURANT_BRANCH_LIST_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_RESTAURANT_BRANCH_LIST_PROCESSING', processing};
}

function getRestaurantBranchList(restaurantID, filters) {
    return dispatch => {
        dispatch(_processing(true));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                 'x-app-source': AppSource.appSource
            },
            params:{
                limit: urlParamValue(`limit`) ? urlParamValue(`limit`) : filters && filters.limit || 10,
                page: urlParamValue(`page`) ? urlParamValue(`page`) : filters && filters.page || 1,
                q: urlParamValue(`q`) ? urlParamValue(`q`) : filters && filters.q || ``,
                sort_column: urlParamValue(`sort_column`) ? urlParamValue(`sort_column`) : filters && filters.sort_column || 'id',
                sort_by: urlParamValue(`sort_by`) ? urlParamValue(`sort_by`) : filters && filters.sort_by || 'desc'
            }
        };

        // let url =apiBaseUrl(`restaurant/v1/restaurant/${restaurantID}/branch/pagination`);
        let url = apiBaseUrl(`vendor/v1/vendor/branch`);
        axios.get(url, config)
            .then(function (response) {
                dispatch(_processing(false));
                dispatch(_success(response));
            }).catch(function (error) {
                // handleError(error);
            dispatch(_processing(false));
            dispatch(_error(error));
        });
    }
}

export default getRestaurantBranchList;
