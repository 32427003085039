import { loggedIn, login, logOut } from "../../../utils/LocalStorageUtils";

export const screenResized = () => dispatch => dispatch({ type: `SCREEN-RESIZED` });

export const loginChanged  = () =>
{
  if (loggedIn()) { logOut() } else { login() }
  return dispatch => dispatch({ type: `LOGIN-CHANGED` })
};

