import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'GET_ADDONS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ADDONS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ADDONS_PROCESSING', processing};
}

function getAddons() {
    return (dispatch, getState) => {
        let { getRestaurantBranch, addonList, main } = getState();
        let { user_role } = main.data;
        // console.log(` <| user_role |> `,user_role )
        let id = getRestaurantBranch.success && getRestaurantBranch.success.data.data.id;
        let filter = addonList.data.params;
        dispatch(_processing(true));
        dispatch(_success(true));
        dispatch(_error(true));
        let config = {
            params: {
                page: urlParamValue(`page`) ? urlParamValue(`page`) : filter.page || 1,
                limit: urlParamValue(`limit`) ? urlParamValue(`limit`) : filter.limit || 10,
                q: urlParamValue(`q`) ? urlParamValue(`q`) : filter.q || null,
                sort_by: urlParamValue(`sort_by`) ? urlParamValue(`sort_by`) : filter.sort_by || 'desc',
                sort_column: urlParamValue(`sort_column`) ? urlParamValue(`sort_column`) : filter.sort_column || 'id'
            },
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            },
        }
        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${id}/addon`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon`
        }
        // axios.get(apiBaseUrl(`restaurant/v1/restaurant/${id}/addon/pagination`), config)
        axios.get(apiBaseUrl(url), config)
            .then(function (res) {
                dispatch(_success(res));
            }).catch(function (error) {
                dispatch(_error(error));
            });
    }
}


export default getAddons;