import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'CREATE_RESERVATION_SETTING_SUCCESS', success }
}

function _error(error) {
    return { type: 'CREATE_RESERVATION_SETTING_ERROR', error }
}

function _processing(processing) {
    return { type: 'CREATE_RESERVATION_SETTING_PROCESSING', processing }
}


function createReservationSetting( data ) {

    let restaurantId = data.branch_id;
    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurantId}`),
            method: "post",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(data)),
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                handleSuccess(res)
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                handleError(error)
                dispatch(_processing(false));

            });
    }
}
export function createReservationSettingReset()
{
    return dispatch => dispatch({ type: `CREATE_RESERVATION_SETTING_RESET` })
}
export default createReservationSetting;
