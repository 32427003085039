import React, {useEffect} from 'react';
import SpecificOrderHeader from "./specific-order/specific-order-header";
import OrderSpecialInstruction from "./specific-order/OrderSpecialInstruction";
import OrderItems from "./specific-order/OrderItems";
import OrderRestaurantDetails from "./specific-order/OrderRestaurantDetails";
import OrderCustomerDetails from "./specific-order/OrderCustomerDetails";
import OrderStatus from "./specific-order/OrderStatus";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useRouteMatch} from "react-router";
import getOrder, {getOrderReset} from "../../../ajax/actions/Orders/order_get";
import {setIsList} from "../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../branches/Header/BranchSubHeader-Action";
import {setUserRole} from "../../layout/Main/Main-Actions";
import OrderStatusUpdateForm from "./orderUpdateForm/OrderStatusUpdateForm";
import OrderCorporateCustomerDetails from "./specific-order/OrderCorporateCustomerDetails";

const BranchOrderDetail = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    let {id} = useParams();
    const { orderGet, userDetails } = reduxState;
    let { url } = useRouteMatch();

    useEffect( () => {
        // id && dispatch( getOrder( id ) );
        let details = {
            title: 'Order Details',
            icon: 'order',
            id,
            // action: true,
            linkUrl: `${url}`
        }
        dispatch( setIsList( false ) )
        dispatch(setBranchHeaderDetails(details))
        return () => {
            dispatch( getOrderReset() )
        }
    },[])

    useEffect( () => {
        dispatch(setUserRole())
        dispatch( getOrder( id ) )
    }, [userDetails.success])

    let role = userDetails && userDetails.success && userDetails.success.roles && userDetails.success.roles[0].name;
    let { success, processing } = orderGet;
    let order_details = success?.data?.data;
    let showUpdateForm = false;
    if( order_details && order_details.order_status === 'pending'){
        showUpdateForm = true;
    }
    return (<>
        <div className="plr-50">
            <div className="row">
                <div className="col-md-8">
                    <OrderStatus processing={processing} orders={order_details}/>
                    <OrderSpecialInstruction processing={processing} orders={order_details}/>
                    <OrderItems processing={processing} orders={order_details}/>
                </div>
                <div className="col-md-4">
                    {<OrderStatusUpdateForm id={ id } orders={order_details} /> }
                    <OrderRestaurantDetails processing={processing} orders={order_details}/>
                    <OrderCustomerDetails processing={processing} orders={order_details}/>
                    <OrderCorporateCustomerDetails processing={processing} orders={order_details}/>
                </div>
            </div>
        </div>
    </>)
}
export default BranchOrderDetail;