import React, { memo } from "react";
import GrowingLoader from "./GrowingLoader";

const LoadingTableRow = ({ colSpan }) =>
{
  return (

    <tr className="LoadingTableRow">
      <td colSpan={ colSpan } className="text-center">
        <GrowingLoader />
      </td>
    </tr>

  )
};

export default memo( LoadingTableRow )