import {toggleURLQuery} from "../../../../utils/URLUtils";

export function toggleTimeslotListFilter(e, history) {

    return function ( dispatch, getState ) {
        let { reservationList } = getState();
        let { params } = reservationList && reservationList.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-RESERVATION-LIST-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            // dispatch( getReservationTimeslots( params ));
        }
    }
}

export function setDefaultTimeslotParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-RESERVATION-PARAMS', });
    }
}

export function changeStatusTimeSlot( payload, id ) {
    console.log(` <| payload |> `,payload )
    return function ( dispatch,) {
        dispatch({type: 'CHANGE-STATUS-TIMESLOT', payload, id });
    }
}

export function setBranchTimeslotParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-BRANCH-TIMESLOT-PARAMS', param:params });
    }
}

export function setRestaurantTimeslotParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-RESTAURANT-TIMESLOT-PARAMS', param:params });
    }
}