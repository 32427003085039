import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux"
import GrowingLoader from "../loaders/GrowingLoader";
import {useHistory} from "react-router";
import DotDotDot from "../table/DotDotDot";

const PaginationButtons = (props) => {
    let dispatch = useDispatch();
    const [loader, setLoader] = useState(``);
    let history = useHistory();
    function onPageClick(button, page) {
        setLoader(button);
        let {fetchAction } = props;
        let e = {target: {name: `page`, value: page}}
        fetchAction && dispatch( fetchAction( e, history ))
    }

    const loaderAt = (button) => {
        let {processing} = props;
        return processing && loader === button;
    }


    let { pages, showFormerDots } = props;
    let {current_page, last_page, total} = pages;
    if (current_page && last_page) //&& ( current_page !== last_page ) )
        return (
            <ul className="pagination">
                <li className="mr-3">
                    <a href="javascript:void(0)" className={`${+current_page === 1 ? `item-disabled` : ``}`}
                       onClick={() => onPageClick(`previous`, 1)}>
                        {loaderAt(`previous`) ?
                            <GrowingLoader/> : <span className="fa-fast-backward"/>}
                    </a>
                </li>
                <li className="mr-3">
                    <a href="javascript:void(0)" className={`${+current_page === 1 ? `item-disabled` : ``}`}
                       onClick={() => onPageClick(`left`, current_page - 1)}>
                        {loaderAt(`left`) ?
                            <GrowingLoader/> : <span className="fa-step-backward"/>}
                    </a>
                </li>
                {+current_page !== 1 && (
                    <li className="page-number">
                        <a href="javascript:void(0)" className={`${+current_page === 1 ? `item-disabled` : ``}`}
                           onClick={() => onPageClick(`left`, current_page - 1)}>
                            {loaderAt(`left`) ?
                                <GrowingLoader/> : +current_page > 1 ? +current_page - 1 : 1}
                        </a>
                    </li>
                )}


                <li className="page-number">
                    <a href="javascript:void(0)" className="active item-disabled">{current_page}</a></li>

                {+current_page === last_page ? null : <li className="page-number">
                    <a href="javascript:void(0)" className={`${+current_page === last_page ? 'item-disabled' : ''}`}
                       onClick={() => onPageClick(`right`, current_page + 1)}>
                        {loaderAt(`right`) ?
                            <GrowingLoader/> : +current_page + 1}
                    </a>
                </li> }

                { (showFormerDots && +current_page !== last_page && +current_page !== last_page - 1 && +current_page !== last_page - 2 ) ? <DotDotDot/> : null }

                {(+current_page === last_page || +current_page === last_page - 1 ) ? null :
                    <li className="page-number">
                        <a href="javascript:void(0)" className={`${+current_page === last_page ? 'item-disabled' : ''}`}
                           onClick={() => onPageClick(`next`, last_page)}>
                            {loaderAt(`next`) ?
                                <GrowingLoader/> : last_page}
                        </a>
                    </li>
                }

                <li className="mr-3">
                    <a href="javascript:void(0)" className={`${+current_page === last_page ? `item-disabled` : ``}`}
                       onClick={() => onPageClick(`right`, current_page + 1)}>
                        {loaderAt(`right`) ?
                            <GrowingLoader/> : <span className="fa-step-forward"/>}
                    </a>
                </li>

                {+current_page === last_page ? null :
                    <li className="ml-2">
                        <a href="javascript:void(0)" className={`${+current_page === last_page ? 'item-disabled' : ''}`}
                           onClick={() => onPageClick(`next`, last_page)}>
                            {loaderAt(`next`) ?
                                <GrowingLoader/> : <span className="fa-fast-forward"/>}
                        </a>
                    </li>
                }
            </ul>
        );
    return null;
}
export default PaginationButtons;