export default function updatePartnerIntegrationReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { processing, success, error } = action;
    switch (action.type)
    {
        case 'UPDATE_PARTNER_INTEGRATION_SUCCESS':
            return {...state, ...{success, processing:false, error:false }};

        case 'UPDATE_PARTNER_INTEGRATION_ERROR':
            return {...state, ...{ error, processing:false }};

        case 'UPDATE_PARTNER_INTEGRATION_PROCESSING':
            return {...state, ...{ processing }};

        case 'UPDATE_PARTNER_INTEGRATION_RESET':
            return { ...state, ...{
                    success: null,
                    error: null,
                    processing:false
                }
            };
        default: return state;
    }
}