import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {setBranchHeaderDetails} from "../../../branches/Header/BranchSubHeader-Action";
import {setIsList} from "../../../branches/specific/BranchInfo-Action";
import { setDefaultPartnerIntegrationForm } from './PartnerIntegrationForm-Actions';
import { createPartnerIntegrationReset } from '../../../../../ajax/actions/partner/integration/partner_integration_post';
import { updatePartnerIntegrationReset } from '../../../../../ajax/actions/partner/integration/partner_integration_put';
import { togglePartnerIntegrationForm } from '../list/PartnerIntegrationList-Action';
import getPartnerIntegrations from '../../../../../ajax/actions/partner/integration/partner_integrations_get';

const PartnerIntegrationFormWorker = () =>
{
    let dispatch = useDispatch();
    const history = useHistory()
    const reduxState = useSelector(state => state);
    const { PartnerIntegrationFormReducer, partnerIntegrationListReducer, createPartnerIntegrationReducer, updatePartnerIntegrationReducer } = reduxState;
    
    let {  is_update } = partnerIntegrationListReducer && partnerIntegrationListReducer.data;
    
    useEffect(() => {
        let details = {
            title:`${is_update ? "Update" : "Create"} Partner Integration`,
            icon: 'partner',
            id: ``,
            action: false,
        }
        dispatch(setIsList(false));
        // if( !is_update  ) {
        //     dispatch( setDefaultPartnerIntegrationForm() );
        // }
        dispatch(setBranchHeaderDetails(details));

        return () => {
            // dispatch(getAddonReset())
        }
    }, []);


    useEffect(() => {
        if ( createPartnerIntegrationReducer && createPartnerIntegrationReducer.success ) {
            dispatch( createPartnerIntegrationReset() )
            dispatch(togglePartnerIntegrationForm())
            dispatch(setDefaultPartnerIntegrationForm())
            dispatch( getPartnerIntegrations() )
        }
    }, [ createPartnerIntegrationReducer ])

    useEffect(() => {
        if ( updatePartnerIntegrationReducer && updatePartnerIntegrationReducer.success ) {
            dispatch( updatePartnerIntegrationReset() )
            dispatch(togglePartnerIntegrationForm())
            dispatch(setDefaultPartnerIntegrationForm())
            dispatch( getPartnerIntegrations() )
        }
    }, [ updatePartnerIntegrationReducer ])

    return ( null)
}
export default PartnerIntegrationFormWorker;