export default function internalReservationDateGetReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {

        case 'GET_INTERNAL_RESERVATION_DATE_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'GET_INTERNAL_RESERVATION_DATE_ERROR':
            return {...state, ...{error: action.error}};

        case 'GET_INTERNAL_RESERVATION_DATE_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}