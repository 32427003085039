import {clearBuildUrls, toggleURLQuery, urlParamValue} from "../../../../utils/URLUtils";
import getFaultReport from "../../../../ajax/actions/report/sales/sales_report_get";

export function toggleFaultReportFilter(e, history) {

    return function ( dispatch, getState ) {
        let { faultReportReducer } = getState();
        let { params } = faultReportReducer && faultReportReducer.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-FAULT-REPORT-FILTER', name, value});
    }
}

export function onFaultReportDateChange(e) {

    return function ( dispatch ) {

        let {name, value} = e.target;
        dispatch({type: 'ON-FAULT-REPORT-DATE-CHANGE', name, value});
    }
}

export function toggleFaultReportDateChange(start_date, end_date, history) {
    return function( dispatch, getState  ) {
        let { faultReportReducer, restaurantBranchList } = getState();
        let { params } = faultReportReducer && faultReportReducer.data;
        toggleURLQuery( 'start_date', start_date, history );
        toggleURLQuery( 'end_date', end_date, history );
        // let { name, value } = e.target;
        // let country_id = restaurantBranchList && restaurantBranchList.success && restaurantBranchList.success.data.data[0].country_id;
        dispatch({type:'TOGGLE-FAULT-REPORT-DATE-CHANGE', start_date, end_date});
        // dispatch( getFaultReport( params  ) )
    }
}

export function setDefaultFaultReportParams( ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-FAULT-REPORT-PARAMS', });
    }
}

export function setBranchIDForFaultReport( branch_id ) {
    return function ( dispatch,getState ) {
        let { faultReportReducer,restaurantBranchList } = getState();
        let { params } = faultReportReducer && faultReportReducer.data;
        let country_id = restaurantBranchList && restaurantBranchList.success && restaurantBranchList.success.data.data[0].country_id;
        dispatch({type: 'SET-BRANCH-ID-FOR-FAULT-REPORT',branch_id });
        dispatch( getFaultReport( params,country_id  ) )
    }
}

export function setBranchIDForMonthlyReport( branch_id ) {
    return function ( dispatch ) {
        dispatch({type: 'SET-BRANCH-ID-FOR-MONTHLY-REPORT',branch_id });
    }
}

// export function setCountryID( countryID ) {
//     return function ( dispatch,) {
//         dispatch({type: 'SET-COUNTRY-ID',countryID });
//     }
// }
export function setParentRestaurantIDForFaultReport( parentID ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-PARENT-RESTAURANT-ID-FOR-FAULT-REPORT',parentID });
    }
}

export function toggleFaultReportFilterSidebar( mode ) {
    // console.log(` <| mode |> `,mode )
    return function ( dispatch,) {
        dispatch({type: 'TOGGLE-FAULT-REPORT-FILTER-SIDER', mode });
    }
}
export function setCountryIDForFaultReport( countryID ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-COUNTRY-ID-FOR-FAULT-REPORT', countryID });
    }
}

export function openCloseFaultReportFilterSidebar(  ) {
    return function ( dispatch,) {
        dispatch({type: 'OPEN-CLOSE-FAULT-REPORT-FILTER-SIDER', });
    }
}