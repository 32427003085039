import axios from 'axios';
import { apiBaseUrl } from '../../../utils/URLUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success){
    return { type: 'GET_ORDER_SUCCESS', success }
}
function _error(error){
    return { type:'GET_ORDER_ERROR', error }
}
function _processing(processing){
    return { type: 'GET_ORDER_PROCESSING', processing }
}

function getOrder( order_id ) {
    return (dispatch, getState) => {

        const { branchID } = getState().orderList.data;
        let {user_role} = getState().main.data;
        dispatch(_processing(true));

        let config = {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`), 'x-app-source': AppSource.appSource },
            params:{
                branch_id: branchID
            }
        };

        if( !branchID ) {
            delete config.params;
        }

        // let url = `order/v1/vendor/orders/${ order_id }`;
        let url = ``;
        if( user_role === 'branch-admin' ) {
            delete config.params;
            url= `order/v1/vendor/branch/orders/${ order_id }`
        } else if (user_role === 'restaurant-admin') {
            url = `order/v1/vendor/orders/${ order_id }`
        }

        axios.get(apiBaseUrl(url), config)

          .then(res => {
              dispatch(_success(res));

          }).catch(error => {
              dispatch(_error(error));
            // handleError( error )
          });
    }
}
export function getOrderReset()
{
    return dispatch => dispatch({ type: `GET_ORDER_RESET` })
}
export default getOrder;