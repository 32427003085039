import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'POST_INTEGRATION_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_INTEGRATION_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_INTEGRATION_PROCESSING', processing};
}

function VerifyCode(data) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let { main } = getState();
            let { user_role } = main.data;
            dispatch(_processing(true));
            dispatch(_success(null));
            dispatch(_error(null));

            // let url = `http://localhost:7171/foodics-callback`;
            let url = `vendor/v3/partner-callback`; // to be changed and made generlized

            axios({
                url: apiBaseUrl(url),
                method: "post",
                data: data,
                headers: {
                    // 'Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    // 'X-App-Source': apiBaseUrl('/'),
                    'X-App-Source': AppSource.appSource,
                    'Authorization': 'Bearer ' + loginToken()
                }
            })
                .then(function (res) {
                    dispatch(_success(res));
                    handleSuccess(res);
                    resolve(res);
                })
                .catch(function (error) {
                    dispatch(_error(error.response.data));
                    handleError(error.response.data);
                    reject(error.response.data || 'An error occurred');
                });
        });
    }
}

export default VerifyCode;