import React from 'react';
import NotFoundImage from './not-found.png';
import { Link } from "react-router-dom";
import './NotFound.scss'

const NotFound = () =>
{
  return (

    <div className="NotFound fadeInDown">

      <img src={ NotFoundImage } alt="Not Found"/>

      <hr/>

      <p>The page you are looking for could not be found.</p>

      <Link className="btn-theme" to="/home">Go Home</Link>

    </div>

  )
};

export default NotFound;