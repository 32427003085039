import axios from 'axios';
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {handleError, handleSuccess} from "../../../../utils/ErrorUtils";
import { AppSource } from '../../../../Config';

function _success(success) {
    return { type: 'CREATE_INTERNAL_RESERVATION_SUCCESS', success }
}

function _error(error) {
    return { type: 'CREATE_INTERNAL_RESERVATION_ERROR', error }
}

function _processing(processing) {
    return { type: 'CREATE_INTERNAL_RESERVATION_PROCESSING', processing }
}


function createInternalReservation(data ) {

    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        delete data.formSubmitted;
        delete data.errors;

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/reservation/restaurant`),
            method: "post",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(data)),
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess( res );

            }).catch(function(error){

                dispatch(_error(error));
                handleError( error );
                dispatch(_processing(false));

            });
    }
}
export function createInternalReservationReset() {
    return dispatch => dispatch({type: `CREATE_INTERNAL_RESERVATION_RESET`})
}
export default createInternalReservation;
