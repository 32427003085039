import axios from 'axios';
import { apiBaseUrl } from '../../../utils/URLUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success){
    return { type: 'GET_ORDER_PREPARATION_TIME_SUCCESS', success }
}
function _error(error){
    return { type:'GET_ORDER_PREPARATION_TIME_ERROR', error }
}
function _processing(processing){
    return { type: 'GET_ORDER_PREPARATION_TIME_PROCESSING', processing }
}

function getOrderPreparationTime( order_id ) {
    return (dispatch, getState) => {

        const { branchID } = getState().orderList.data;
        let {user_role} = getState().main.data;
        dispatch(_processing(true));

        let config = {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`), 'x-app-source': AppSource.appSource },
        };
        let url= `order/v1/vendor/orders/${ order_id }/preparation-time/list`

        axios.get(apiBaseUrl(url), config)

          .then(res => {
              dispatch(_success(res));

          }).catch(error => {
              dispatch(_error(error));
            // handleError( error )
          });
    }
}
export function getOrderReset()
{
    return dispatch => dispatch({ type: `GET_ORDER_PREPARATION_TIME_RESET` })
}
export default getOrderPreparationTime;