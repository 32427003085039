import axios from 'axios'
import {apiBaseUrl} from '../../../../utils/URLUtils'
import {loginToken} from '../../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config'

function _success(success) {
    return {type: 'UPDATE_PARTNER_INTEGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_PARTNER_INTEGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_PARTNER_INTEGRATION_PROCESSING', processing}
}

function updatePartnerIntegration(values) {
    
    
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`auth/v2/vendor/partner/branch/${values.branch_id}/integrations/${values.id}`),
            method: 'put',
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                handleSuccess(res)

            }).catch(function (error) {
                // console.log(` <| error |> `, {error})
            dispatch(_error(error))
            // handleError(error)
        })
    }
}

export function updatePartnerIntegrationReset() {
    return dispatch => dispatch({type: `UPDATE_PARTNER_INTEGRATION_RESET`})
}

export default updatePartnerIntegration