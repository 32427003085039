import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../common/table/Table";
import TableDataLoader from "../../../common/loaders/TableDataLoader";
import {useParams, useRouteMatch} from "react-router";
// import TimeslotListItem from "./TimeslotListItem";
import NoDataAvailable from "../../../common/NotFound/NoDataAvailable";
import PaginationButtons from "../../../common/buttons/PaginationButtons";
import useAppState from "../../../../hooks/useAppState";
import timeslotList from "./TimeslotList-Reducer";
import {setBranchReservationParams, setDefaultReservationParams} from "../ReservationList-Action";
import {
    setBranchTimeslotParams,
    setDefaultTimeslotParams,
    setRestaurantTimeslotParams,
    toggleTimeslotListFilter
} from "./TimeslotList-Action";
import getReservationTimeslots from "../../../../ajax/actions/reservations/reservation_timeslots_get";
import TimeslotListItem from "./TimeslotListItem";
import {toggleFilterSidebar} from "../../../layout/Main/Main-Actions";
import RestaurantHeader from "../../../layout/Header/RestaurantHeader";
import ReactTooltip from "react-tooltip";
import updateTimeslotStatus from "../../../../ajax/actions/reservations/timeslot_status_put";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import Switch from "react-switch";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const Timeslot = () => {

    const reservationTimeslotsGet = useAppState( s => s.reservationTimeslotsGet )
    const timeslotList = useAppState( s => s.timeslotList )
    const userDetails = useAppState( s => s.userDetails )
    const getRestaurantBranch = useAppState( s => s.getRestaurantBranch )
    const timeslotStatusUpdate = useAppState( s => s.timeslotStatusUpdate )
    const main = useAppState( s => s.main )

    let {fields, params, list } = timeslotList && timeslotList.data;
    let {url} = useRouteMatch();
    let {restaurant_id, branch_id} = params;
    let dispatch = useDispatch();
    let {filter_sidebar_open} = main.data;
    const [initialLoad, setInitialLoading] = useState(true);

    useEffect(() => {
        dispatch(setDefaultTimeslotParams())
        let details = {
            title:`TimeSlot`,
            icon: 'order',
            action: false,
        }
        dispatch(setIsList(false));
        dispatch(setBranchHeaderDetails(details));
        // dispatch(getReservationReset());
        return () => {
            // dispatch(getReservationReset())
            filter_sidebar_open && dispatch(toggleFilterSidebar())
        }
    }, [])

    useTitle(Title.name + ` Reservation Timeslot`);

    useEffect(() => {
        if (getRestaurantBranch.success) {
            dispatch(setBranchTimeslotParams(getRestaurantBranch.success.data.data))
        }
    }, [getRestaurantBranch.success])

    useEffect(() => {
        if (timeslotStatusUpdate.success) {
            dispatch(getReservationTimeslots(params.branch_id))
            setInitialLoading(false);
        }
    }, [timeslotStatusUpdate.success])

    useEffect(() => {
            branch_id && dispatch(getReservationTimeslots(params.branch_id))
    }, [ branch_id])

    return (
        <>
            <div className="table-block table-responsive pl-50">
                <Table reducer={reservationTimeslotsGet}
                       params={params}
                       action={toggleTimeslotListFilter}
                       columns={fields}>
                    <tbody>
                    { (reservationTimeslotsGet.processing && initialLoad )? <TableDataLoader colspan={6}/> :
                        list && list.length ? list.map(order => {
                                return (
                                    <TimeslotListItem key={order.id}
                                                         timeslot={order}
                                                         reducer={reservationTimeslotsGet}
                                                      timeslotStatusUpdateReducer={timeslotStatusUpdate}
                                    />
                                )
                            }
                        ) : <NoDataAvailable colspan={6}/>}
                    </tbody>
                </Table>
            </div>
            {/*{list && list.length ? (
                <div className="fixed-footer">
                    <PaginationButtons pages={pages}
                                       fetchAction={toggleTimeslotListFilter}
                                       processing={reservationTimeslotsGet && reservationTimeslotsGet.processing}/>
                </div>
            ) : null}*/}
            <ReactTooltip backgroundColor="#BF9F60"/>
        </>
    )
}

export default Timeslot;