import {toggleURLQuery} from '../../../utils/URLUtils'
import getRestaurantReviews from "../../../ajax/actions/review/restaurant_reviews_get";

export function toggleReviewListFilter(e, history,) {
    return function ( dispatch, ) {
        let { name, value, sort } = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-REVIEW-LIST-FILTER', name, value, history});
        dispatch( getRestaurantReviews( ));
    }
}

export function toggleModalOpenCLose() {
    return function ( dispatch, ) {
        dispatch({type: 'TOGGLE-REVIEW-MODAL',});
    }
}

export function viewReviewDetails(review) {
    return function ( dispatch, ) {
        dispatch({type: 'VIEW-REVIEW-DETAILS', review});
    }
}