import axios from 'axios';
import { apiBaseUrl } from '../../../../utils/URLUtils'
import { loginToken } from '../../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'DELETE_PARTNER_INTEGRATION_SUCCESS', success };
}
function _error(error)
{
    return { type: 'DELETE_PARTNER_INTEGRATION_ERROR', error };
}
function _processing(processing)
{
    return { type: 'DELETE_PARTNER_INTEGRATION_PROCESSING', processing };
}

function deletePartnerIntegration(branchID, id)
{
    return ( dispatch ) =>
    {
      dispatch(_processing(true));
      dispatch(_success(null));
      dispatch(_error(null));

      axios({
        url: apiBaseUrl(`auth/v2/vendor/partner/branch/${branchID}/integrations/${id}`),
        method: "delete",
        dataType: 'json',
        headers: {
            'Authorization': 'Bearer ' + loginToken(),
            'x-app-source': AppSource.appSource
        }
      })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
          handleError( error )

            });
    }
}


export default deletePartnerIntegration;