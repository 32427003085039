import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router";
import BranchInfo from "../branches/specific/BranchInfo";
import AddonsCategories from "../addons-categories/list/AddonsCategories";
import AddonCategoryForm from "../addons-categories/form/AddonCategoryForm";
import Addons from "../addons/list/Addons";
import AddonForm from "../addons/form/AddonForm";
import Foods from "../foods/list/Foods";
import FoodForm from "../foods/form/FoodForm";
import FoodCategories from "../food-categories/list/FoodCategories";
import BranchOrders from "../order/BranchOrders";
import NotFound from "../../common/NotFound/NotFound";
import OrderDetail from "../order/OrderDetail";
import Account from "../account/Account";
import BranchSalesReport from "../report/sales/BranchSalesReport";
import Reservation from "../reservations/details/Reservation";
import BranchReservations from "../reservations/BranchReservations";
import Timeslot from "../reservations/timeslot/Timeslot";
import ReservationSetting from "../reservations/setting/ReservationSetting";
import TimeslotDay from "../reservations/timeslot/day/TimeslotDay";
import InternalReservation from "../reservations/internal-reservation/InternalReservation";
import Schedule from "../reservations/schedule/Schedule";
import AddonCategorySort from "../addons-categories/sort/AddonCategorySort";
import useAppState from "../../../hooks/useAppState";
import BranchOrderDetail from "../order/BranchOrderDetail";
import FoodSort from "../foods/sort/FoodSort";
import BranchFaultReport from "../report/fault/BranchFaultReport";
import FoodsInReview from "../foods/list/FoodsInReview";

const BranchAdminRoutes = (props) => {
    let {url, id, mode} = props;
    const [reservationMode, setReservationMode] = useState(false)

    const reservationSettingGet = useAppState(s => s.reservationSettingGet)

    useEffect(() => {
        if (reservationSettingGet.success) {
            setReservationMode(true);
        }
    }, [reservationSettingGet.success])
    // console.log(` <|url, id |> `,url, id )
    return (
        <>
            <Switch>
                {mode === "branch-admin" ?
                    <Route exact path={`/`} render={() => <Redirect to={`/branches/${id}/info`}/>}/> : null}
                <Route exact path={[`/branches/${id}/info`]} component={BranchInfo}/>
                <Route exact path={`/branches/${id}/addons-categories`} component={AddonsCategories}/>
                <Route exact path={`/branches/${id}/addons-categories/create`} component={AddonCategoryForm}/>
                <Route exact path={`/branches/${id}/sort-addons-category`} component={AddonCategorySort}/>
                <Route exact path={`/branches/${id}/addons-categories/:id([0-9]{0,9})`} component={AddonCategoryForm}/>
                <Route exact path={`/branches/${id}/addons`} component={Addons}/>
                <Route exact path={`/branches/${id}/addons/create`} component={AddonForm}/>
                <Route exact path={`/branches/${id}/addons/:id([0-9]{0,9})`} component={AddonForm}/>
                <Route exact path={`/branches/${id}/foods`} component={Foods}/>
                <Route exact path={`/branches/${id}/foods/sort`} component={FoodSort}/>
                <Route exact path={`/branches/${id}/foods/create`} component={FoodForm}/>
                <Route exact path={`/branches/${id}/foods/:id([0-9]{0,9})`} component={FoodForm}/>
                <Route exact path={`/branches/${id}/food-categories`} component={FoodCategories}/>
                <Route exact path={`/branches/${id}/foods-in-review`} component={FoodsInReview}/>
                <Route exact path={`/branches/${id}/orders`} component={BranchOrders}/>
                <Route exact path={`/branches/${id}/order/:id([0-9]{0,9})/view`} component={BranchOrderDetail}/>
                <Route exact path={`/account`} component={Account}/>
                <Route exact path={`/branches/${id}/report`} component={BranchSalesReport}/>
                <Route exact path={`/branches/${id}/fault-report`} component={BranchFaultReport}/>
                <Route exact path={`/branches/${id}/reservations`} component={BranchReservations}/>
                <Route exact path={`/branches/${id}/reservations/:id([0-9]{0,9})/view`} component={Reservation}/>
                {reservationMode ?
                    <>
                        <Route exact path={`/branches/${id}/reservations/timeslot`} component={Timeslot}/>
                        <Route exact path={`/branches/${id}/reservations/timeslot/:id([0-9]{0,9})/day`}
                               component={TimeslotDay}/>
                        <Route exact path={`/branches/${id}/reservations/setting`} component={ReservationSetting}/>
                        <Route exact path={`/branches/${id}/reservations/internal`} component={InternalReservation}/>
                        <Route exact path={`/branches/${id}/reservations/schedule`} component={Schedule}/>
                    </> : null
                }


                <Route><NotFound/></Route>
            </Switch>
        </>
    )
}
export default BranchAdminRoutes;