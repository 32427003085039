import axios from 'axios'
import { apiBaseUrl } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config'

function _success(success) {
  return { type: 'FOOD_POST_SUCCESS', success }
}

function _error(error) {
  return { type: 'FOOD_POST_ERROR', error }
}

function _processing(processing) {
  return { type: 'FOOD_POST_PROCESSING', processing }
}

function createFood( values ) {
  return (dispatch, getState) => {
    dispatch(_processing(true))
    let branchID = values.restaurantID;

    let {main} = getState();
    let {user_role} = main.data;

    if( !values.food_size.length ) {
      delete values.food_size;
    }

    let url = ``;
    if (user_role === 'restaurant-admin') {
      url = `vendor/v1/vendor/${branchID}/item`
    } else if (user_role === `branch-admin`) {
      url = `vendor/v1/vendor/item`
    }

    axios({
      // url: apiBaseUrl(`restaurant/v1/restaurant/${branchID}/food`),
      url: apiBaseUrl(url),
      method: 'post',
      dataType: 'json',
      data: values,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'x-app-source': AppSource.appSource
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        handleSuccess(res)
      }).catch(function(error) {
        dispatch(_error(error))
        handleError(error)
        /*if (error.response && error.response.status === 422) {
          scrollToError()
        }*/
    })
  }
}
export function createFoodReset()
{
  return dispatch => dispatch({ type: `FOOD_POST_RESET` })
}

export default createFood