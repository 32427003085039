import axios from 'axios';
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {handleError} from "../../../../utils/ErrorUtils";
import { AppSource } from '../../../../Config';

function _success(success) {
    return { type: 'GET_INTERNAL_RESERVATION_TIME_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_INTERNAL_RESERVATION_TIME_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_INTERNAL_RESERVATION_TIME_PROCESSING', processing }
}


function getInternalReservationTime( params ) {

    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params:{
                date:params.booking_date,
                guests:params.no_of_guests,
                device_type:'web'
            }
        };

       axios.get( apiBaseUrl(`reservation/v1/public/branch/${params.branch_id}/timeslot/v2`), config)
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                handleError(error)
                dispatch(_processing(false));

            });

    }
}

export default getInternalReservationTime;
