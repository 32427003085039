import React, {useEffect} from "react";
import {setIsList} from "../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../branches/Header/BranchSubHeader-Action";
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../common/form/TextField";
import * as Yup from "yup";
import {useFormik} from "formik";
import updateUser from "../../../ajax/actions/Oauth/user/user_put";
import {changePasswordFormVisible, setAccountForm} from "./AccountForm-Actions";
import ChangePassword from "./ChangePassword";
import GrowingLoader from "../../common/loaders/GrowingLoader";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const Account = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { userDetails, accountForm, userUpdate } = reduxState;
    useTitle(Title.name + ` Account`);
    // console.log(` <| userDetails |> `, userDetails)
    let success = userDetails && userDetails.success;

    useEffect(() => {
        success && dispatch(setAccountForm(success));
    }, [success]);

    let avatar = "http://via.placeholder.com/140x140";
    let fields = {...accountForm.data.payload};


    const validationSchema = Yup.object().shape({
        last_name: Yup.string().required('is required'),
        first_name: Yup.string().required('is required'),
        // middle_name: Yup.string().required('is required'),
        email: Yup.string().required('is required'),
        mobile: Yup.number(` be a number`).required('is required'),
        city: Yup.string().required('is required'),
        country: Yup.string().required('is required'),
        address_line_1: Yup.string().required('is required'),
    });


    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                dispatch(updateUser(values, success.id))
            }
        })

    const changePasswordVisible = () => {
        dispatch(changePasswordFormVisible())
    }

    return (
        <>
            <div className="heading-block">
                <div className="title-block">
                    <div className="title-block-icon">
                        <span className="icon-order"/>
                    </div>
                    <div className="title-block-content">
                        <h2>Account</h2>
                        <span>{success && success.full_name}</span>
                        {/*<span><a href="">Branches</a></span>
                        <span><a href="">Test Restaurant</a></span>
                        <span>Orders</span>*/}
                    </div>
                </div>
            </div>

            <div className="account-block pl-50">
                <div className="account-block-wrapper">
                    <div className="account-holder-img">
                        <img src={success && success.avatar || avatar} alt="avatar"/>
                    </div>
                    <div className="account-info">
                        <form action="">
                            <div className="form-wrapper">
                                <div className="row align-items-end">
                                    <div className="col-xl-8">
                                        <TextField name="email"
                                                   label="Email Address"
                                                   disabled
                                                   placeholder={`Enter Email Address`}
                                                   onChange={handleChange}
                                                   value={values.email}
                                                   error={errors && errors.email}
                                                   touched={touched && touched.email}/>
                                    </div>
                                    <div className="col-xl-4">
                                        <a href="javascript:void(0)" className="btn-theme btn-outline btn-rounded mt-20"
                                           data-toggle="modal" data-target="#changePassword"
                                           onClick={changePasswordVisible}>Change password</a>
                                    </div>
                                </div>
                                <div className="row align-items-end">
                                    <div className="col-md-4">
                                        <TextField name="first_name"
                                                   placeholder={`Enter First Name`}
                                                   onChange={handleChange}
                                                   value={values.first_name}
                                                   error={errors && errors.first_name}
                                                   touched={touched && touched.first_name}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="middle_name"
                                                   placeholder={`Enter Middle Name`}
                                                   onChange={handleChange}
                                                   value={values.middle_name}
                                                   error={errors && errors.middle_name}
                                                   touched={touched && touched.middle_name}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="last_name"
                                                   placeholder={`Enter Last Name`}
                                                   onChange={handleChange}
                                                   value={values.last_name}
                                                   error={errors && errors.last_name}
                                                   touched={touched && touched.last_name}/>
                                    </div>
                                </div>
                                {/*<div className="row align-items-end">
                                    <div className="col-md-4">
                                        <TextField name="old_password"
                                                   placeholder={`Enter Old Password`}
                                                   onChange={handleChange}
                                                   value={values.old_password}
                                                   error={errors && errors.old_password}
                                                   touched={touched && touched.old_password}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="new_password"
                                                   placeholder={`Enter New Password`}
                                                   onChange={handleChange}
                                                   value={values.new_password}
                                                   error={errors && errors.new_password}
                                                   touched={touched && touched.new_password}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="confirm_password"
                                                   placeholder={`Enter Confirm Password`}
                                                   onChange={handleChange}
                                                   value={values.confirm_password}
                                                   error={errors && errors.confirm_password}
                                                   touched={touched && touched.confirm_password}/>
                                    </div>
                                </div>*/}
                                <div className="row align-items-end">
                                    <div className="col-md-6">
                                        <TextField name="mobile"
                                                   placeholder={`Enter Mobile Number`}
                                                   onChange={handleChange}
                                                   value={values.mobile}
                                                   error={errors && errors.mobile}
                                                   touched={touched && touched.mobile}/>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Role</label>
                                            <input type="text" value={success && success.roles && success.roles[0].name}
                                                   className="form-control" disabled/>
                                        </div>
                                    </div>

                                </div>
                                <div className="row align-items-end">
                                    <div className="col-md-4">
                                        <TextField name="country"
                                                   placeholder={`Enter Country`}
                                                   onChange={handleChange}
                                                   value={values.country}
                                                   error={errors && errors.country}
                                                   touched={touched && touched.country}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="city"
                                                   placeholder={`Enter City`}
                                                   onChange={handleChange}
                                                   value={values.city}
                                                   error={errors && errors.city}
                                                   touched={touched && touched.city}/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField name="address_line_1"
                                                   placeholder={`Enter Address`}
                                                   label="Address"
                                                   onChange={handleChange}
                                                   value={values.address_line_1}
                                                   error={errors && errors.address_line_1}
                                                   touched={touched && touched.address_line_1}/>
                                    </div>
                                </div>
                                <div className="row align-items-end">
                                    <div className="col-md-12">
                                        <div className="mt-30">
                                            <button type="submit" className="btn-theme btn-gray btn-submit"
                                                    onClick={handleSubmit}> { userUpdate.processing ? <GrowingLoader /> : `Save Changes` }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ChangePassword/>
        </>
    )
}
export default Account;