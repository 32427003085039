import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import useAppState from "../../../../hooks/useAppState";
import {useFormik} from "formik";
import * as Yup from "yup";
import UploadImage from "../../../common/form/UploadImage";
import updateReservationSetting from "../../../../ajax/actions/reservations/reservation_setting_put";
import createReservationSetting from "../../../../ajax/actions/reservations/reservation_setting_post";
import TextField from "../../../common/form/TextField";
import SortableImagesDND from "./SortableImages";
import getReservationSetting from "../../../../ajax/actions/reservations/reservation_setting_get";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import TextAreaField from "../../../common/form/TextAreaField";
import {useBeforeUnload, useError, useTitle} from "react-use";
import {Title} from "../../../../Config";

const ReservationSetting = () => {

    let dispatch = useDispatch();

    const [settingMode, setSettingMode] = useState(false);
    const reservationSettingGet = useAppState(s => s.reservationSettingGet);
    const getRestaurantBranch = useAppState(s => s.getRestaurantBranch);
    const reservationSettingUpdate = useAppState(s => s.reservationSettingUpdate);
    const reservationSettingCreate = useAppState(s => s.reservationSettingCreate);
    const [uploadKey, setUploadKey] = useState(Math.random().toString(36));
    const [ gallery, setGallery ] = useState( [] );
    const [ translation, setTranslation ] = useState( {} );
    const [ menu, setMenu ] = useState( [] );
    const [ coverImage, setCoverImage ] = useState( `` );
    const [ immersiveUrl, setImmersiveUrl ] = useState( `` );
    const [ branchID, setBranchID ] = useState( `` );
    const [ details, setDetails ] = useState({
            title: `Create Reservation Setting`,
            icon: 'order',
            // id: data.id || ``,
            id: ``,
            action: false,
    });

    const dispatchError = useError();


    useEffect( () => {
        dispatch(setIsList(false));
        dispatch(setBranchHeaderDetails(details));
    }, [])

    useTitle(Title.name + ` Reservation Setting`);

    // useBeforeUnload(true, 'You have unsaved changes, are you sure?');

    const clickHandler = () => {
        dispatchError('Some error!');
    };

   function setTranslations(success) {
       let translation = {};
       if (success && success.data && success.data.data && success.data.data.translation) {

           success.data.data.translation.map((item, i) => {

               translation[item.lang_code] = {
                   description: "",
                   cancellation_policy: "",
                   terms_and_conditions: ""
               };
           });
           setTranslation( translation )
       }
   }

    useEffect(() => {
        if( getRestaurantBranch.success ) {
            getRestaurantBranch.success && dispatch(getReservationSetting())
            const {success} = getRestaurantBranch;
            setBranchID(success && success.data && success.data.data && success.data.data.id)
            setTranslations(getRestaurantBranch.success);
        }

    }, [getRestaurantBranch.success])

    useEffect(() => {
        if (reservationSettingGet.success) {

            setSettingMode(true);
            const {success} = reservationSettingGet;
            const data = success && success.data && success.data.data;
            setGallery( data.gallery );
            setCoverImage( data.cover_image );
            setMenu( data.menu );
            setImmersiveUrl( data.immersive_url );
            setBranchID( data.branch_id );
            setFieldValue( 'cover_image', data.cover_image )

            let _translation = {};
            if(data.translations && data.translations.length ) {
                data.translations.length && data.translations.map(trans => {
                    setFieldValue(`translation.${trans.lang_code}.description`, trans.description);
                    setFieldValue(`translation.${trans.lang_code}.cancellation_policy`, trans.cancellation_policy);
                    setFieldValue(`translation.${trans.lang_code}.terms_and_conditions`, trans.terms_and_conditions);
                    _translation[trans.lang_code] = {
                        description: trans.description,
                        cancellation_policy: trans.cancellation_policy,
                        terms_and_conditions: trans.terms_and_conditions
                    };
                })

                setTranslation(_translation)
            }else {
                setTranslations( getRestaurantBranch.success )
            }

            let _details = {
                title: `${reservationSettingGet.success ? "Update " : "Create " } Reservation Setting`,
                icon: 'order',
                id: data.id || ``,
                action: false,
            }
            dispatch( setBranchHeaderDetails(_details) )

        } else {
            setSettingMode(false);
            dispatch( setBranchHeaderDetails(details) )
            setGallery( [] );
            setCoverImage( `` );
            setMenu( [] );
            setImmersiveUrl( `` );
            setTranslations(getRestaurantBranch.success);

        }
    }, [reservationSettingGet.success, reservationSettingGet.error])

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue, resetForm} = useFormik(
        {
            initialValues: {immersive_url: '', menu: [], gallery: [], cover_image: ''},
            enableReinitialize: true,

            /*validationSchema: Yup.object().shape({
                cover_image: Yup.string().required('is required'),
            }),
*/
            onSubmit: (values) => {
                    values.menu = menu
                values.gallery = gallery
                values.cover_image = coverImage
                values.immersive_url = immersiveUrl
                values.branch_id = branchID;
                values.translations = translation
                if (settingMode) {
                    dispatch(updateReservationSetting(values))
                } else {
                    dispatch(createReservationSetting(values))
                }
            }
        })

    const handleImage = e => {
        let {value, name} = e.target;
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.size > 400000) {
            // this.setState({ sizeErrorFeatured: true })
        } else {
            reader.onloadend = () => {

                setFieldValue(`cover_image`, reader.result)
                setCoverImage(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }

    const removeFeatureImage = (e) => {
        setCoverImage(``)
        setFieldValue(`cover_image`, ``);
    }

    const _handleGalleryChange = (e) => {
        let {name} = e.target;
        let files = Array.from(e.target.files);
        let sizeErrorGallery = false;
        files.map(file => {
            if (file.size > 400000) {
                sizeErrorGallery = true;
            }
        });

        if (sizeErrorGallery) {
            // console.log(` <|  |> `, )
        } else {
            for (let i = 0; i < files.length; i++) {
                _handleUploadedGalleryFiles(files[i], name, i);
            }
        }
    }
    function _handleUploadedGalleryFiles(file, name, order) {

        let reader = new FileReader();
        let _values = {...values};
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                _values[name].push({data: e.target.result, order: order});
                name === 'gallery' ? setGallery( prevState => [...prevState, {data: e.target.result, order: order} ]  ) :
                    setMenu( prevState => [...prevState, {data: e.target.result, order: order} ]  )
                // setFieldValue(name, _values[name]);

            }
        };
        // reader.readAsDataURL(file);
    }

    const removeGalleryImage = (e) => {
        let _gallery = [];
        for (let i = 0; i < gallery.length; i++) {
            if (+e.target.id === i) {
                let index = gallery.indexOf(gallery[i]);
                gallery.splice(index, 1);
                setGallery( gallery );
            }
        }
           gallery && gallery.length && gallery.map( (m,key) => {
               _gallery.push({ data:m.data, order:key })
           } );
           setGallery(_gallery)
        // setFieldValue(name, name);
    }

    const removeMenuImage = (e) => {
        let _menu = [];
        for (let i = 0; i < menu.length; i++) {
            if (+e.target.id === i) {
                let index = menu.indexOf(menu[i]);
                menu.splice(index, 1);
                setMenu(menu);
            }
        }
       menu && menu.length && menu.map( (m,key) => {
           _menu.push({ data:m.data, order:key })
       } );
       setMenu(_menu)
        // setFieldValue(name, name);
    }

    const onDragEnd = (items, mode) => {
        if (mode === 'gallery') {
            let _gallery = [];
            items && items.length && items.map((m, key) => {
                _gallery.push({data: m.src, order: key})
            });
            setGallery(_gallery)
        } else {
            let _menu = [];
            items && items.length && items.map((m, key) => {
                _menu.push({data: m.src, order: key})
            });
            setMenu( _menu )
        }
    }

    const onTranslationFormChange = e => {
        let {value, name} = e.target;
        setFieldValue(name, value)
    }

    let galleryPreview = gallery
    let galleryData = [];
    for (let key in galleryPreview) {
        galleryData[key] = ({
            'src': galleryPreview[key].data || galleryPreview[key].path,
            'order': galleryPreview[key].order
        });
    }

    let menuData = [];
    let menuPreview = menu;
    for (let key in menuPreview) {
        menuData[key] = ({'src': menuPreview[key].data || menuPreview[key].path, 'order': menuPreview[key].order});
    }
    // console.log(` <| gallery |> `,gallery )
    // console.log(` <|  values|> `, values)
    let processing = reservationSettingUpdate.processing || reservationSettingCreate.processing;
    return (
        <div className="plr-35 w-1000">
            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <UploadImage name="cover_image"
                                     onChange={handleImage}
                                     uploadType="featured"
                                     onRemoveImage={removeFeatureImage}
                                     error={errors.cover_image}
                                     touched={touched.cover_image}
                                     value={coverImage ? coverImage : ``}/>
                    </div>
                    <div className="col-md-6 ">
                        <TextField
                            name="immersive_url"
                            label="360 Url"
                            placeholder="360 Url"
                            onChange={(e) => setImmersiveUrl( e.target.value )}
                            value={immersiveUrl}
                        />
                    </div>

                    { Object.keys( translation ).length ? Object.keys( translation ).map( (trans ) => {
                        let lang = trans === 'ar' ? 'Arabic' : 'English'
                        return (
                            <div className="col-md-6">
                                <TextAreaField
                                    name={`translation.${trans}.description`}
                                    label={`Description (${ lang })`}
                                    placeholder="Description"
                                    onChange={onTranslationFormChange}
                                    value={values.translation && values.translation[trans] && values.translation[trans].description}
                                />

                                <TextAreaField
                                    name={`translation.${trans}.cancellation_policy`}
                                    label={`Cancellation Policy (${ lang })`}
                                    placeholder="Cancellation Policy"
                                    onChange={onTranslationFormChange}
                                    value={values.translation && values.translation[trans] && values.translation[trans].cancellation_policy}
                                />

                                <TextAreaField
                                    name={`translation.${trans}.terms_and_conditions`}
                                    label={`Terms And Condition (${ lang })`}
                                    placeholder="Terms And Condition"
                                    onChange={onTranslationFormChange}
                                    value={values.translation && values.translation[trans] && values.translation[trans].terms_and_conditions}
                                />
                            </div>
                        )
                    }) : null }

                    <div className="col-sm-12">
                        <label className="control-label">Gallery Image</label>
                        <div className="input-file-wrapper">
                            <input type="file" name="gallery" id="file-2" key={uploadKey}
                                   data-multiple-caption="{count} files selected" multiple className="form-control"
                                   onChange={_handleGalleryChange} accept='image/*'/>
                            <label htmlFor="file-2" className="btn-default">
                                <i className="mdi mdi-upload"/>
                                <span>Browse files...</span>
                            </label>
                        </div>
                        <div id="dvPreview">
                            <SortableImagesDND list={galleryData}
                                               removeImage={removeGalleryImage}
                                               onDragEnd={results => onDragEnd(results, 'gallery')}/>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <label className="control-label">Menu</label>
                        <div className="input-file-wrapper">
                            <input type="file" name="menu" id="file-3" key={uploadKey}
                                   data-multiple-caption="{count} files selected" multiple className="form-control"
                                   onChange={_handleGalleryChange} accept='image/*'/>
                            <label htmlFor="file-3" className="btn-default">
                                <i className="mdi mdi-upload"/>
                                <span>Browse files...</span>
                            </label>
                        </div>
                        <div id="dvPreview">
                            <SortableImagesDND list={menuData}
                                               removeImage={removeMenuImage}
                                               onDragEnd={results => onDragEnd(results, 'menu')}/>
                        </div>
                    </div>
                </div>
                <button type="submit"
                        className="btn-theme mt-10">{processing ?
                    <GrowingLoader/> : `${settingMode ? "Update" : "Create"}`}</button>
            </form>
        </div>
    )
}
export default ReservationSetting;