import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {setAddon, setBranchDataAddon, setDefaultAddonForm} from "./AddonForm-Actions";
import getAddon, {getAddonReset} from "../../../../ajax/actions/addon/addon_get";
import {createAddonReset} from "../../../../ajax/actions/addon/addon_post";
import {urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import {updateAddonReset} from "../../../../ajax/actions/addon/addon_put";
import getAddonCategories from "../../../../ajax/actions/addon-categories/addon_categories_get";
import {setIsList} from "../../branches/specific/BranchInfo-Action";

const AddonWorker = () =>
{
    let dispatch = useDispatch();
    const history = useHistory()
    const reduxState = useSelector(state => state);
    const { addonCreate,  branchInfoReducer, addonGet, addonUpdate } = reduxState;
    let { id } = useParams();

    useEffect(() => {
        let details = {
            title:`${id ? "" : "Create"} Addon`,
            icon: 'addons',
            id:id || ``,
            action: false,
        }
        dispatch(setIsList(false));
        if( !id || id === 'undefined' ) {
            dispatch( setDefaultAddonForm() );
        }
        dispatch(setBranchHeaderDetails(details));

        return () => {
            dispatch(getAddonReset())
        }
    }, []);

    let branch_id = branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
    useEffect(() => {
        if( branchInfoReducer ) {
            // let branch_id = branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
            dispatch( setBranchDataAddon( branchInfoReducer ) );
            branch_id && dispatch( getAddonCategories( 100 ) );
            if( id ) {
                branch_id && dispatch( getAddon( branch_id, id ) )
            }
        }
    }, [branch_id]);

    useEffect(() => {
        if ( addonCreate && addonCreate.success ) {
            dispatch( createAddonReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ addonCreate ])

    useEffect(() => {
        if ( addonUpdate && addonUpdate.success ) {
            dispatch( updateAddonReset() )
            history.push(urlTrailWithoutLastItem());
        }
    }, [ addonUpdate ])

    useEffect(() => {
        addonGet.success && dispatch(setAddon( addonGet.success ))
    },[addonGet])

    return ( null)
}
export default AddonWorker;