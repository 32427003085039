import axios from 'axios/index';
import { apiBaseUrl } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import store from '../../../store';
import getAddons from './addons_get'
import { AppSource } from '../../../Config';

function _success(success)
{ return { type: 'DELETE_ADDON_SUCCESS', success }; }
function _error(error)
{ return { type: 'DELETE_ADDON_ERROR', error }; }
function _processing(processing, id)
{ return { type: 'DELETE_ADDON_PROCESSING', processing, id }; }

function deleteAddon( addonId, restaurantId )
{
  return ( dispatch, getState ) =>
  {
    let { main } = getState();
    let { user_role } = main.data;
    dispatch( _processing(true, addonId ));

    let url = ``;
    if( user_role === 'restaurant-admin' ) {
      url = `vendor/v1/vendor/${restaurantId}/addon/${ addonId }`
    } else if( user_role === `branch-admin` ) {
      url = `vendor/v1/vendor/addon/${addonId}`
    }

    axios({
      // url:apiBaseUrl(`restaurant/v1/restaurant/${restaurantId}/addon/${ addonId }`),
      url:apiBaseUrl(url),
      method: "delete",
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
         'x-app-source': AppSource.appSource
      }
    })
      .then(function(res){
        dispatch(_success(res));
        handleSuccess( res )
        store.dispatch( getAddons( ))

      }).catch(function(error){
        handleError(error);
        dispatch(_error(error));
      });
  }
}
export default deleteAddon;