import getReservationSchedule from "../../../../ajax/actions/reservations/schedule/reservation_schedule_get";

export function toggleScheduleListFilter( e, history ) {
    return function ( dispatch, getState ) {
        let {name, value} = e.target;
        dispatch({type: 'TOGGLE-SCHEDULE-LIST-FILTER', name, value});
        dispatch( getReservationSchedule() )
    }
}

export function toggleScheduleFilterOpen( ) {
    return function ( dispatch ) {
        dispatch({ type:'SCHEDULE-FILTER-OPEN-CLOSE', });
    }
}

export function hourModeChange( name, value ) {
    return function ( dispatch ) {
        dispatch({ type:'ON-HOUR-MODE-CHANGE', name, value });
    }
}

export function setScheduleFilterData( filterData ) {
    return function ( dispatch ) {
        dispatch({ type:'SET-SCHEDULE-FILTER-DATA', filterData });
    }
}

export function closeScheduleInfo()  {
    return function ( dispatch ) {
        dispatch({ type:'CLOSE-SCHEDULE-INFO-MODAL' });
    }
}

export function setBranchResrvationScheduleBranchID( id ) {
    return function ( dispatch ) {
        dispatch({ type:'SET-SCHEDULE-BRANCH-ID', id });
        dispatch( getReservationSchedule() );
    }
}

export function setDefaultScheduleParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-SCHEDULE-PARAMS', });
        dispatch( getReservationSchedule() );
    }
}

export function setCurrentScheduleParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-CURRENT-DAY-SCHEDULE-PARAMS', });
        dispatch( getReservationSchedule() );
    }
}

export function setScheduleDateFilterChange( name, value ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-SCHEDULE-DATE-FILTER-CHANGE', name, value });
        dispatch( getReservationSchedule() );
    }
}