import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleFilterSidebar} from "../../../layout/Main/Main-Actions";
import getPaymentStatuses from "../../../../ajax/actions/Orders/payment-status/payment_statuses_get";
import getDeliveryStatuses from "../../../../ajax/actions/Orders/delivery-status/delivery_statuses_get";
import getOrderStatuses from "../../../../ajax/actions/Orders/order-status/order_statuses_get";
import SelectField from "../../../common/form/SelectField";
import getRestaurantBranchList from "../../../../ajax/actions/branch/get_restaurant_branch_list";
import {useHistory, useRouteMatch} from "react-router";
import getShippingMethods from "../../../../ajax/actions/Orders/shipping-method/shipping_methods_get";
import getPaymentMethods from "../../../../ajax/actions/Orders/payment-method/payment_methods_get";
import {
    openCloseSalesReportFilterSidebar, setBranchIDForReport, setCountryIDForReport,
    setDefaultSalesReportParams,
    toggleSalesReportFilter
} from "./SalesReport-Action";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import {pushToPage} from "../../../../utils/URLUtils";

const SalesReportFilter = (props) => {
    const[  countryID, setCountryID ] = useState(``);
    const reduxState = useSelector(state => state);
    let history = useHistory();
    let {url} = useRouteMatch();
    const { main, salesReport, deliveryStatusesGet, orderStatusesGet, paymentStatusesGet,
        userDetails, restaurantBranchList, shippingMethodsGet, paymentMethodsGet } = reduxState;
    let { params, report_filter_open, report_filter_mode } = salesReport && salesReport.data;
    let parent_restaurantID = userDetails && userDetails.success && userDetails.success.restaurant_id;
    let branch_id = ``;
    // console.log(` <| props |> `, props)
    if( props.mode === 'branch' ) {
        branch_id = userDetails && userDetails.success && userDetails.success.branch_id;
    }
    useEffect(() => {
        dispatch(getDeliveryStatuses());
        dispatch(getOrderStatuses());
        dispatch(getPaymentStatuses());
        dispatch( getShippingMethods() );
        dispatch( getPaymentMethods() );
        if(props.mode !== 'branch') {
            let filters = {
                limit : 1000
            }
            dispatch(getRestaurantBranchList(parent_restaurantID, filters));
        }
       if( props.mode === 'branch' ) {
           dispatch(setBranchIDForReport( branch_id ));
       }
    }, []);

    useEffect(() => {
        if( restaurantBranchList.success ) {
            setCountryID( restaurantBranchList.success.data.data[0].country_id );
            dispatch( setCountryIDForReport(restaurantBranchList.success.data.data[0].country_id) )
        }
    },[restaurantBranchList.success]);

    let deliveryStatuses = deliveryStatusesGet?.success?.data?.payload?.data;

    let orderStatuses = orderStatusesGet?.success?.data?.payload?.data;

    let paymentStatuses = paymentStatusesGet?.success?.data?.payload?.data;

    let paymentMethods =paymentMethodsGet?.success?.data?.data;

    let deliveryMethods =shippingMethodsGet?.success?.data?.data;

    const branchList = restaurantBranchList?.success?.data?.data;

    let dispatch = useDispatch();
    const closeFilterSidebar = ( ) => {
        dispatch(openCloseSalesReportFilterSidebar())
    }

    const salesReportFilterToggle = (e) => {
        dispatch(toggleSalesReportFilter(e, history))
    }

    const orderFilterSubmit = () => {
        dispatch( getSalesReport( params, countryID ) );
        closeFilterSidebar();
    }
    const salesFilterSetDefault = () => {
        dispatch( setDefaultSalesReportParams( ) );
        pushToPage( history, url )
        closeFilterSidebar();
        // dispatch( getSalesReport( params, countryID ) );
    }
    return (<div className={`filter-sidebar ${report_filter_open ? `filter-active` : null}`}>
        <div className="filter-sidebar-wrapper">
            <div className="filter-heading-block">
                <h2>Filter</h2>
                <span className="icon-close" onClick={closeFilterSidebar}/>
            </div>
            <div className="filter-form-block">
                <form action="">

                    {props.mode !== 'branch' && (<SelectField name="branch_id"
                                 options={branchList}
                                 onChange={salesReportFilterToggle}
                                 _value="id"
                                 _label="branch_name"
                                 value={params.branch_id}
                    />)}

                    <SelectField name="order_status"
                                 options={orderStatuses}
                                 onChange={salesReportFilterToggle}
                                 _value="slug"
                                 _label="name"
                                 value={params.order_status}
                    />

                    <SelectField name="delivery_status"
                                 options={deliveryStatuses}
                                 onChange={salesReportFilterToggle}
                                 _value="slug"
                                 _label="name"
                                 value={params.delivery_status}
                    />

                    <SelectField name="payment_status"
                                 options={paymentStatuses}
                                 onChange={salesReportFilterToggle}
                                 _value="slug"
                                 _label="name"
                                 value={params.payment_status}
                    />

                    <SelectField name="delivery_method"
                                 options={deliveryMethods}
                                 onChange={salesReportFilterToggle}
                                 _value="slug"
                                 _label="name"
                                 value={params.delivery_method}
                    />

                    <SelectField name="payment_method"
                                 options={paymentMethods}
                                 onChange={salesReportFilterToggle}
                                 _value="slug"
                                 _label="name"
                                 value={params.payment_method}
                    />

                    <a href="javascript:void(0)" className="btn-theme btn-outline mt-40" onClick={salesFilterSetDefault}>Clear Filter</a>
                    <a href="javascript:void(0)" className="btn-theme mt-20" onClick={orderFilterSubmit }>Filter</a>
                </form>
            </div>
        </div>
    </div>)
}

export default SalesReportFilter;