import {PartnerIntegrationListState} from './PartnerIntegration-State'

export default function partnerIntegrationListReducer(state = PartnerIntegrationListState, action) {

    let {data} = {...state};
    let {name, value, mode} = action;

    switch (action.type) {

        case 'TOGGLE-PARTNER-INTEGRATION-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
            }

            data.fields.map( field => {
                if( field.field === value ) {
                    field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                }
            } )

            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'TOGGLE-PARTNER-INTEGRATION-MODAL':
            data.partner_modal_open = !data.partner_modal_open;
            return {...state, data};

        case 'FORM-MODE-CHANGE-UPDATE':
            data.is_update = mode;
            return {...state, data};


        default:
            return state
    }
}
