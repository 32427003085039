import {ScheduleListState} from './Schedule-State'
import {urlParamValue} from "../../../../utils/URLUtils";
import {
    currentMonth,
    currentYear, getTodaysDay,
    halfHr24TimeTable,
    hr24TimeTable,
    quarterHr24TimeTable
} from "../../../../utils/DateTimeUtils";
import {inRecord} from "../../../../utils/DataUtils";

export default function scheduleReducer(state = ScheduleListState, action) {

    let {data} = {...state};
    let {name, value, success, id, filterData } = action;

    switch (action.type) {

        case 'TOGGLE-SCHEDULE-LIST-FILTER':
            data.params[name] = value;
            return {...state, data};

        case 'SET-SCHEDULE-BRANCH-ID':
            data.params.branch_id = id;
            return {...state, data};

        case 'SET-SCHEDULE-FILTER-DATA':
            data.params.showInfoModal = true;
            data.params.filterData = filterData;
            return {...state, data};

        case 'CLOSE-SCHEDULE-INFO-MODAL':
            data.params.showInfoModal = false;
            data.params.filterData = ``;
            return {...state, data};

        case 'SCHEDULE-FILTER-OPEN-CLOSE':
            data.schedule_filter_open = !data.schedule_filter_open;
            return {...state, data};

        case 'ON-HOUR-MODE-CHANGE':
            data.params[name] = value;
            if (value === 'hr') {
                data.params.hourOption = hr24TimeTable()
            } else if (value === 'half') {
                data.params.hourOption = halfHr24TimeTable()
            } else if (value === 'quarter') {
                data.params.hourOption = quarterHr24TimeTable()
            }
            return {...state, data};

        case 'GET_RESERVATION_SCHEDULE_SUCCESS':
            let  _schedule, hour_options = []
            if( success ) {
                _schedule = success && success.data && success.data.data && success.data.data.schedule;
                hour_options = success && success.data && success.data.data && success.data.data.time;
                if (!inRecord(_schedule, 'day', '===', 0)) {
                    _schedule.unshift({date: "", day: 0, time: []})
                }
            }

            data.params.schedule = _schedule;
            data.params.hourOption = hour_options;
            return {...state, data};

        case 'SET-DEFAULT-SCHEDULE-PARAMS':
            data.params = {
                branch_id : data.params.branch_id,
                hour_mode: 'quarter',
                year:currentYear(),
                month:currentMonth() <= 9 ? '0'+currentMonth() : currentMonth(),
                schedule:[],
                hourOption:quarterHr24TimeTable(),
                filterData:``,
                day:``,
                showInfoModal:false
            };
            return {...state, data};

        case 'SET-CURRENT-DAY-SCHEDULE-PARAMS':
            data.params.day = getTodaysDay()
            return {...state, data};

        case 'SET-SCHEDULE-DATE-FILTER-CHANGE':
            let _values = value.split('-');
            data.params.year = _values[0];
            data.params.month = _values[1];
            return {...state, data};

        default:
            return state
    }
}
