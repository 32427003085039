import React, {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Tags from "../../../common/Tags/Tags";
import updateTimeslotStatus from "../../../../ajax/actions/reservations/timeslot_status_put";
import {useDispatch} from "react-redux";
import Switch from "react-switch";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import useAppState from "../../../../hooks/useAppState";

const TimeslotListItem = ({timeslot, url, reducer, timeslotStatusUpdateReducer}) => {
    const {day, total_slots, total_pax, available_status, closed, id, restaurant_branch_id} = timeslot;
    const [ checked, setChecked ] = useState( !closed)
    const [ processing, setProcessing ] = useState( ``)
    let dispatch = useDispatch();
    const toggleConfirm = (statusName, statusNumber) => {
        setChecked( !checked )
        setProcessing( id )
        let data = {
            closed: statusNumber,
            restaurant_id: restaurant_branch_id,
            reservation_day_id: id

        }
        dispatch(updateTimeslotStatus(data));
    };

    useEffect(() => {
        if (timeslotStatusUpdateReducer.success) {
            setProcessing('');
        }
    }, [timeslotStatusUpdateReducer.success])

    let statusNumber = closed === false;
    return (
        <tr>
            <td>{day}</td>
            <td>{total_slots}</td>
            <td>{total_pax}</td>
            <td><Tags status={closed ? 'closed' : 'open'}/></td>
            <td>
                {/*<button className="ml-2 btn-primary"
                        onClick={() => toggleConfirm(closed === false ? 'enable' : 'disable', statusNumber)}>
                    Toggle Status
                </button>*/}
                <Switch
                        // onChange={() => toggleConfirm(closed === false ? 'enable' : 'disable', statusNumber)}
                        onChange={() => toggleConfirm( checked, statusNumber )}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                />
                { processing ? <GrowingLoader /> : null }
            </td>
            <td className="buttons-block">
                <Link to={`/branches/${restaurant_branch_id}/reservations/timeslot/${id}/day`}>
                    <i className="fa-eye" data-tip="View"/>
                </Link>
            </td>
        </tr>
    )
};

export default memo(TimeslotListItem);