export default function internalReservationCreateReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'CREATE_INTERNAL_RESERVATION_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'CREATE_INTERNAL_RESERVATION_ERROR':
            return {...state, ...{error: action.error}};

        case 'CREATE_INTERNAL_RESERVATION_PROCESSING':
            return {...state, ...{processing: action.processing}};
        case 'CREATE_INTERNAL_RESERVATION_RESET':
            return { ...state, ...{
                    success: null,
                    error: null,
                    processing:false
                }
            };
        default: return state;
    }
}