import axios from 'axios/index';
import { apiBaseUrl, } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { scrollToError } from '../../../utils/ScrollUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
  return { type: 'PUT_ADDON_SUCCESS', success };
}
function _error(error)
{
  return { type: 'PUT_ADDON_ERROR', error };
}
function _processing(processing)
{
  return { type: 'PUT_ADDON_PROCESSING', processing };
}

function updateAddon( data, addonID )
{
  return ( dispatch, getState ) =>
  {
    let { main } = getState();
    let { user_role } = main.data;
    dispatch(_processing(true));
    if(data.calorie === "" ) {
      data.calorie = 0;
    }
    let url = ``;
    if( user_role === 'restaurant-admin' ) {
      url = `vendor/v1/vendor/${data.restaurantID}/addon/${ addonID }`
    } else if( user_role === `branch-admin` ) {
      url = `vendor/v1/vendor/addon/${addonID}`
    }

    axios ({
      // url:apiBaseUrl(`restaurant/v1/restaurant/${data.restaurantID}/addon/${ addonID }`),
      url:apiBaseUrl(url),
      method: "put",
      dataType: 'json',
      data,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
         'x-app-source': AppSource.appSource
      }
    })
      .then(function(res) {
        dispatch(_success(res));
        handleSuccess( res )
      })
      .catch(function(error) {
        // console.log(` <|error |> `, {error} )
        handleError(error);
        dispatch(_error(error));

        /*if ( error.response && error.response.status === 422 ) {
          // store.dispatch( addonFormServerValidationErrors( error.response.data.message ));
          scrollToError();
        }*/
      });
  }
}
export function updateAddonReset()
{
  return dispatch => dispatch({ type: `PUT_ADDON_RESET` })
}

export default updateAddon;