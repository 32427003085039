import { MainState } from "./Main-State";

export default function MainReducer (state = MainState, action )
{
  let { data } = { ...state };

  let { mode, flag, role, bool } = action;

  switch( action.type )
  {
    case `TOGGLE-SIDEBAR`:
      data.sidebar.open = !data.sidebar.open;
      return { ...state,  data };

    case `SET-FLAG-SPECIFIC-BRANCH`:
      data.isSpecificBranch = flag;
      return { ...state,  data };

    case `TOGGLE-FILTER-SIDEBAR`:
      data.filter_sidebar_open = !data.filter_sidebar_open;
        data.order_filter_mode = mode;
      return { ...state,  data };

    case `SET-USER-ROLE`:
      data.user_role = role;
      return { ...state,  data };

    case `SET-MINIMIZE-MENU`:
      data.minimize_menu = bool;
      return { ...state,  data };

    default: return state;
  }
}