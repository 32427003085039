import axios from 'axios'
import {apiBaseUrl} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config'

function _success(success) {
    return {type: 'FOOD_PUT_SUCCESS', success}
}

function _error(error) {
    return {type: 'FOOD_PUT_ERROR', error}
}

function _processing(processing) {
    return {type: 'FOOD_PUT_PROCESSING', processing}
}

function updateFood(values, foodId) {
    return (dispatch, getState) => {

        let {main} = getState();
        let {user_role} = main.data;

        dispatch(_processing(true));
        let branchID = values.restaurantID;

        /*let {food_size} = values;

        food_size && food_size.length && food_size.map((size, i) => {
            let {size_translation} = size;
            let _translation = {};
            size_translation && size_translation.length && size_translation.map((trans) => {
                _translation[trans.lang] = {
                    title: trans.title
                }
            });

            delete values.food_size[i].size_translation;
            values.food_size[i].size_translation = _translation;
        });*/

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${branchID}/item/${foodId}`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/item/${foodId}`
        }

        axios({
            // url: apiBaseUrl(`restaurant/v1/restaurant/${branchID}/food/${foodId}`),
            url: apiBaseUrl(url),
            method: 'put',
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                handleSuccess(res)

            }).catch(function (error) {
                // console.log(` <| error |> `, {error})
            dispatch(_error(error))
            handleError(error)
        })
    }
}

export function updateFoodReset() {
    return dispatch => dispatch({type: `FOOD_PUT_RESET`})
}

export default updateFood