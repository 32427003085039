import axios from 'axios';
import {apiBaseUrl} from "../../../../utils/URLUtils";
import { AppSource } from '../../../../Config';

function _success(success) {
    return {type: 'GET_RESERVATION_SCHEDULE_SUCCESS', success}
}

function _error(error) {
    return {type: 'GET_RESERVATION_SCHEDULE_ERROR', error}
}

function _processing(processing) {
    return {type: 'GET_RESERVATION_SCHEDULE_PROCESSING', processing}
}


function getReservationSchedule() {

    return (dispatch, getState ) => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let { scheduleReducer } = getState();
        let filter = scheduleReducer.data.params;
        // console.log(` <| filter |> `,filter )

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params: {
                year: filter.year,
                month: filter.month,
                mode:filter.hour_mode,
                day:filter.day
            }
        };

        if(!config.params.day) {
            delete config.params.day
        }

        axios.get(apiBaseUrl(`reservation/v1/branch/${filter.branch_id}/schedule`), config)
            .then(function (res) {

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {

            dispatch(_error(error));
            dispatch(_processing(false));

        });

    }
}

export default getReservationSchedule;
