import React from 'react'
import {names, ucFirst, unslufigy} from '../../../utils/StringUtils'
import Div from "../Div/Div";
import GrowingLoader from "../loaders/GrowingLoader";
import {inRecord} from "../../../utils/DataUtils";

const CheckboxField = (props) => {
    const {
        name, size, refer, value, icon, onChange, id, noLabel, labelIcon, label, touched, defaultValue,
        addonAfter, disabled, className, error, required, options, onKeyUp, autoFocus, processing,
    } = props;

    const hasError = error && touched;

    const Label = () => {
        if (label) return (
            <Div className={names(`label-text`, hasError && `has-error`)}>
                {label} {hasError ? error + '.' : null}
            </Div>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return (
                    <Div className={names(`label-text`)}>
                        {_label} {hasError ? error + '.' : null}
                    </Div>
                )
            }
        }
        return null
    }
// console.log(` <| values on checkfield |> `, value)
    return (
        <>
            <div className={names(hasError && `has-error`)}>
                <p>{!noLabel ? <Label/> : null}</p>
                <div className="row">
                    {processing ? <GrowingLoader/> :
                        options && options.length ? options.map((opt, key) => {
                            let checked = false;
                            if (value.includes(opt.id)) {
                                checked = true;
                            }
                            return (
                                <div className="col-md-3 col-sm-6" key={opt.id}>
                                    <Div className={names(`form-check form-group`)}>
                                        <input type="checkbox"
                                               name={name}
                                               className="form-check-input"
                                               value={opt.id}
                                               checked={checked}
                                               onChange={onChange}
                                               id={opt.id}/>
                                        <label className="form-check-label" htmlFor={opt.id}>{opt.name}</label>
                                    </Div>
                                </div>
                            )
                        }) : <small className="item-disabled ">No {unslufigy(name || label,'_')} Available</small>}
                </div>
            </div>
        </>
    )
}

export default CheckboxField