import {AddonsListState} from './AddonList-State'
import {urlParamValue} from "../../../../utils/URLUtils";

export default function addonListReducer(state = AddonsListState, action) {

    let {data} = {...state};
    let {name, value} = action;

    switch (action.type) {

        case 'TOGGLE-ADDON-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }

                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )
            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'ADDON-FILTER-OPEN-CLOSE':
            data.addon_filter_open = !data.addon_filter_open;
            return {...state, data};

        case 'SET-ADDON-SEARCH-RESET':
            data.params.q = ``;
            return {...state, data};

        case 'SET-DEFAULT-ADDON-PARAMS':
            data.params = {
                limit: 10,
                page:  1,
                sort_by: 'desc',
                sort_column:  `id`,
                q: ``,
            };
            return {...state, data};

        default:
            return state
    }
}
