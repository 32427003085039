import axios from 'axios';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import branchInfoReducer from "../../../components/modules/branches/specific/BranchInfo-Reducer";
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'GET_FOOD_CATEGORIES_SORT_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_FOOD_CATEGORIES_SORT_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_FOOD_CATEGORIES_SORT_PROCESSING', processing };
}

function getFoodCategoriesSort( )
{
    return ( dispatch, getState ) =>
    {
        let {main} = getState();
        let {user_role} = main.data;
        dispatch(_processing(true));
        let { getRestaurantBranch, branchInfoReducer } = getState();
        // console.log(` <| branchInfoReducer |> `, branchInfoReducer)
        // console.log(` <| getRestaurantBranch |> `, getRestaurantBranch)
        let branchID = getRestaurantBranch && getRestaurantBranch.success && getRestaurantBranch?.success?.data?.data.id;
        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        };
        let urls = ``;
        if (user_role === 'restaurant-admin') {
            // urls = `vendor/v1/vendor/${branchID}/item/category`
            urls = `vendor/v1/vendor/${branchID}/item/branch/category/sort`
        } else if (user_role === `branch-admin`) {
            // urls = `vendor/v1/vendor/item/category`
            urls = `vendor/v1/vendor/item/branch/category/sort`
        }
        // const url= apiBaseUrl(`restaurant/v1/category/sort/${branchID}`);
        // const url= apiBaseUrl(urls);
        const url= apiBaseUrl(`vendor/v1/vendor/${branchID}/item/branch/category/sort`);
        axios.get(url, config)
          .then(function(res){
              dispatch(_success(res));

          }).catch(function(error){
              dispatch(_error(error));
              // handleError( error )
          });
    }
}
export default getFoodCategoriesSort;