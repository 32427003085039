import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import React from "react";


const SortableItem = SortableElement(({ value, removeImage }) => {
    return (
        <li className="gallery-preview">
            <i id={value.order} className="icon-close" onClick={removeImage}/>
            <img id={ value.order } src={value.src} alt="" key={value.order} width={100} height={100} />
        </li>
    )
});

const SortableList = SortableContainer(({ items, removeImage }) => {
    return (
        <ul className="sortable-ul p-0 pt-3">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} removeImage={  removeImage } />
            ))}
        </ul>
    );
});

const SortableImagesDND = ( props ) =>
{
    const onSortEnd = ({ oldIndex, newIndex }) =>
    {
        props.onDragEnd(arrayMove(props.list, oldIndex, newIndex));
    };



        let { removeImage } = props;
        // console.log(` <|this.props.list  |> `,this.props.list )
        return (
            <div className="sortable-list">
                <SortableList
                    axis="x"
                    distance={3}
                    removeImage={  removeImage }
                    helperClass="dragging"
                    transitionDuration={300}

                    items={props.list}
                    onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)}
                />
            </div>
        );
}

export default SortableImagesDND;