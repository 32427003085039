import React, {useEffect} from "react";
import Modal from "react-modal";
import {useDispatch, useSelector} from "react-redux";
import {changePasswordFormVisible} from "./AccountForm-Actions";
import TextField from "../../common/form/TextField";
import * as Yup from "yup";
import {useFormik} from "formik";
import updateUser from "../../../ajax/actions/Oauth/user/user_put";
import GrowingLoader from "../../common/loaders/GrowingLoader";

const ChangePassword = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { accountForm, userDetails, userUpdate } = reduxState;
    let changePasswordFlag = accountForm.data.changePasswordFlag;

    const customStyles = {
        content : {
            width: `700px`,
            border:'none',
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            background            : 'transparent'
        }
    };

    const changePasswordVisible = () => {
        dispatch( changePasswordFormVisible() )
    }
    let fields = {...accountForm.data.payload };
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required('is required'),
        new_password: Yup.string().required('is required'),
        confirm_password: Yup.string().required('is required').oneOf([Yup.ref('new_password'), null], ' must match with new password'),
    });
    let success = userDetails && userDetails.success;
    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                dispatch(updateUser(values, success.id))
            }
        })

    useEffect(() => {
        if( changePasswordFlag ){
            userUpdate.success && dispatch( changePasswordFormVisible() )
        }

    }, [userUpdate.success]);

    return (
        <Modal
            isOpen={changePasswordFlag}
            style={customStyles}
            onRequestClose={changePasswordVisible}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Change Password</h5>
                        <button type="button" className="close" onClick={ changePasswordVisible }>
                            <span aria-hidden="true" className="icon-close"/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-form-container">
                            <form action="">
                                <div className="form-wrapper">
                                    <TextField name="old_password"
                                               placeholder={`Enter Old Password`}
                                               onChange={handleChange}
                                               value={values.old_password}
                                               error={errors && errors.old_password}
                                               touched={touched && touched.old_password }/>

                                    <TextField name="new_password"
                                               placeholder={`Enter New Password`}
                                               onChange={handleChange}
                                               value={values.new_password}
                                               error={errors && errors.new_password}
                                               touched={touched && touched.new_password }/>

                                    <TextField name="confirm_password"
                                               placeholder={`Enter Confirm Password`}
                                               onChange={handleChange}
                                               value={values.confirm_password}
                                               error={errors && errors.confirm_password}
                                               touched={touched && touched.confirm_password }/>
                                    {/*<div className="form-group">
                                        <label>Old Password</label>
                                        <input type="text" className="form-control"
                                               placeholder="Enter your old password"/>
                                    </div>*/}
                                    {/*<div className="form-group">
                                        <label>New Password</label>
                                        <input type="text" className="form-control" placeholder="New password"/>
                                    </div>*/}
                                    {/*<div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="text" className="form-control" placeholder="Confirm password"/>
                                    </div>*/}
                                    <div className="mt-30">
                                        <button type="submit" className="btn-theme btn-gray btn-submit"
                                                onClick={handleSubmit}>{ userUpdate.processing ? <GrowingLoader /> : `Save Changes` }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
    export default ChangePassword;