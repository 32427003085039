import React, { useState} from 'react';
// import StatBox from "../../common/StatBox/StatBox";
// import SlideDown from "../../common/SlideDown/SlideDown";
// import LineChart from "../../common/charts/LineChart";
// import BarChart from "../../common/charts/BarChart";
// import DoughnutChart from "../../common/charts/DoughnutChart";
// import DashboardCharts from "./DashboardCharts";
import Div from "../../common/Div/Div";

const Dashboard = () =>
{
  const [ open, setOpen ] = useState( false )
  return (

    <Div className="Dashboard">

      <Div className="information-overview dashboard-overview w75 text-center">
        <Div className="row no-gutters">
          <Div className="col-xl-3 col-lg-6 col-6">

            {/*<StatBox count={ 7 } title="members" change={ 17 } percent days={ 30 }  />*/}

          </Div>
          <Div className="col-xl-3 col-lg-6 col-6">

            {/*<StatBox count={ 2 } title="teams" change={ 20 } percent days={ 30 }  />*/}

          </Div>
          <Div className="col-xl-3 col-lg-6 col-6">

            {/*<StatBox count={ 147 } title="projects" change={ 1 } days={ 30 }  />*/}

          </Div>
          <Div className="col-xl-3 col-lg-6 col-6">

            {/*<StatBox count={ 10358 } title="tasks" change={ 6 } percent days={ 30 }  />*/}

          </Div>
        </Div>
      </Div>

      <hr/>

      {/*<DashboardCharts />*/}

    </Div>

  )
};

export default Dashboard;