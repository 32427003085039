import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';
function _success(success) {
    return {type: 'GET_ADDON_CATEGORIES_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ADDON_CATEGORIES_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ADDON_CATEGORIES_PROCESSING', processing};
}

function getAddonCategories( limit=null ) {
    return (dispatch, getState) => {
         dispatch(_success( null ))
         dispatch(_error( null ))

        let { getRestaurantBranch, addonCategoryList, main } = getState();
        let id = getRestaurantBranch.success && getRestaurantBranch.success.data.data.id;
        let filter = addonCategoryList.data.params;
        let { user_role } = main.data;
        let config = {
            params: {
                page: urlParamValue(`page`) ? urlParamValue(`page`) : filter.page || 1,
                limit: limit || urlParamValue(`limit`),
                q: urlParamValue(`q`) ? urlParamValue(`q`) : filter.q || null,
                sort_by: urlParamValue(`sort_by`) ? urlParamValue(`sort_by`) : filter.sort_by || 'desc',
                sort_column: urlParamValue(`sort_column`) ? urlParamValue(`sort_column`) :filter.sort_column || 'id',
            },
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            },
        }
        dispatch(_processing(true));

        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `vendor/v1/vendor/${id}/addon/category`
        } else if( user_role === `branch-admin` ) {
            url = `vendor/v1/vendor/addon/category`
        }

        // axios.get(apiBaseUrl(`restaurant/v1/restaurant/${id}/addon-category/pagination`), config)
        axios.get(apiBaseUrl(url), config)
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}


export default getAddonCategories;