import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError} from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'GET_ADDON_CATEGORY_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ADDON_CATEGORY_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ADDON_CATEGORY_PROCESSING', processing};
}

function getAddonCategory(branchId, addonCategoryID) {
    // console.log(` <| branchId, addonCategoryID |> `, branchId, addonCategoryID )
    return (dispatch, getState) => {
        let {main} = getState();
        let {user_role} = main.data;
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                 'x-app-source': AppSource.appSource
            },
        }

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${branchId}/addon/category/${addonCategoryID}`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/addon/category/${addonCategoryID}`
        }
        // axios.get(apiBaseUrl(`restaurant/v1/admin/restaurant/${ branchId }/addon-category/${addonCategoryID}`), config)
        axios.get(apiBaseUrl(url), config)
            .then(function (res) {
                dispatch(_success(res));

            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
        });
    }
}

export function getAddonCategoryReset() {
    return dispatch => dispatch({type: `GET_ADDON_CATEGORY_RESET`})
}

export default getAddonCategory;