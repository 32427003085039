import React, {useEffect, memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import RestaurantHeader from "../../../layout/Header/RestaurantHeader";
import FaultReportFilter from "./FaultReportFilter";
import orderSvg from "../../../../assets/images/order.svg";
import sarSvg from "../../../../assets/images/sar.svg";
import taxSvg from "../../../../assets/images/tax.svg";
import {objectIsEmpty} from "../../../../utils/GeneralUtils";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import { setParentRestaurantIDForFaultReport} from "./FaultReport-Action";
import { Title} from "../../../../Config";
import {useTitle} from "react-use";
import getFaultReportStats from "../../../../ajax/actions/report/fault/fault_report_get";
import faultReportReducer from "./FaultReport-Reducer";

const FaultReport = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {faultReportReducer, userDetails, getFaultReportReducer} = reduxState;
    let {params} = faultReportReducer && faultReportReducer.data;
    let parent_restaurantID = userDetails?.success?.restaurant_id;
    let role = userDetails && userDetails.success && userDetails.success?.roles?.[0].name;
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Report`);

    useEffect(() => {
        let details = {
            title: 'Fault Report',
            icon: 'report',
            action: false,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch( getFaultReportStats(params) )
        dispatch(setIsList(false));
    }, []);

    useEffect(() => {
        if (role === 'restaurant-admin') {
            dispatch(setParentRestaurantIDForFaultReport(parent_restaurantID))
        }
    }, [userDetails.success])

    useEffect(() => {
        dispatch( getFaultReportStats(params) )
    }, [params.branch_id])

    let data = getFaultReportReducer?.success?.data?.data;

    // let monthly_report = apiBaseUrl(`order/v1/generate/monthly/v2?restaurant_id=${params.branch_id}&month=${salesReport.data.month}&year=${salesReport.data.year}&access_token=${localStorage.getItem('Bearer')}`);
    return (
        <>
            <RestaurantHeader title="Fault Report" icon="report" header="fault-report"/>
            <FaultReportFilter/>
            <div className="plr-35">
                <div className="row mb-5">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Amount</div>
                                    <div className="info-detail">{data && data?.currency}{data && data?.total_amount || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Fault Count</div>
                                    <div
                                        className="info-detail">{data && data.total_faults_count || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-orange">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Order Count</div>
                                    <div className="info-detail">{data && data.total_orders_count || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(FaultReport);