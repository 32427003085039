import React, {memo} from "react";
import {useHistory} from "react-router";
import Tags from "../../../common/Tags/Tags";
import {useDispatch, useSelector} from "react-redux";
import {ucFirst} from "../../../../utils/StringUtils";
import deleteAddonCategory from "../../../../ajax/actions/addon-categories/addon_category_delete";
import swal from "sweetalert";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const AddonsCategoryListItem = ({addonsCategory, url, branchID, reducer}) => {
    const {id, name, max_addon, min_addon, status} = addonsCategory;
    const history = useHistory()
    const dispatch = useDispatch()
    const edit = () => {
        history.push(`${url}/${id}`);
    }

    const deleteConfirm = (id) => {
        swal("Are you sure you want to delete this Addon Category? ", {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id,
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteAddonCategory(branchID, id))
        })
    };
    return (

        <tr>
            <td>{id}</td>
            <td>{ucFirst(name)}</td>
            <td>{max_addon}</td>
            <td>{min_addon}</td>
            <td>{status ? <Tags status="active"/> : <Tags status="inactive"/>}
            </td>
            <td className="buttons-block">
                <div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div><a href="javascript:void(0)" onClick={edit}><button><i className="fa-pencil" data-tip="Edit"/> Edit</button></a></div>
                        <div><a href="javascript:void(0)" onClick={() => deleteConfirm(id)}>
                            {reducer.processing && id === reducer.id ? <GrowingLoader/> : <button><i className="fa-trash" data-tip="Delete"/> Delete</button>}
                        </a></div>
                    </div>
                </div>
            </td>
        </tr>

    )
};

export default memo(AddonsCategoryListItem);