export default function createPartnerCategoryUnMapReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { processing, success, error } = action;
    switch (action.type)
    {
        case 'POST_PARTNER_CATEGORY_UNMAP_SUCCESS':
            return {...state, ...{success, processing:false, error:null }};

        case 'POST_PARTNER_CATEGORY_UNMAP_ERROR':
            return {...state, ...{ error, processing:false, success:null }};

        case 'POST_PARTNER_CATEGORY_UNMAP_PROCESSING':
            return {...state, ...{ processing, id:action.id }};

        case 'POST_PARTNER_CATEGORY_UNMAP_RESET':
            return { ...state, ...{
                    success: null,
                    error: null, id:'', processing:false
                }
            };
        default: return state;
    }
}