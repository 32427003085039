import {OrderListState} from './OrderList-State'
import {beginningOfThisMonth, getCurrentDate, today} from "../../../utils/DateTimeUtils";
import {urlParamValue} from "../../../utils/URLUtils";
import {fromRecord} from "../../../utils/DataUtils";

export default function orderListReducer(state = OrderListState, action) {

    let {data} = {...state};
    let { name, value, param, start_date, end_date, branchID } = action;
    switch (action.type) {

        case 'TOGGLE-ORDER-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )

            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'TOGGLE-ORDER-DATE-CHANGE':
            data.params.start_date = start_date;
            data.params.end_date = end_date;
            return {...state, data};

        case 'SET-ORDERLIST-BRANCH-ID':
            data.branchID = branchID;
            return {...state, data};

        case 'SET-DEFAULT-ORDER-PARAMS':
            data.params = {
                limit: urlParamValue(`limit`) || 15,
                page: urlParamValue(`page`) || 1,
                sort_by: urlParamValue(`sort_by`) || 'desc',
                sort_column: urlParamValue(`sort_column`) || `id`,
                q: urlParamValue(`q`) || ``,
                restaurant_id:urlParamValue(`restaurant_id`) || ``,
                delivery_status:urlParamValue(`delivery_status`) || ``,
                payment_status:urlParamValue(`payment_status`) || ``,
                country_id:urlParamValue(`country_id`) || ``,
                city_id: urlParamValue(`city_id`) || ``,
                end_date: urlParamValue(`end_date`) || getCurrentDate(),
                branch_id: urlParamValue(`branch_id`) || ``,
                start_date: urlParamValue(`start_date`) || beginningOfThisMonth(),
                order_status: urlParamValue(`order_status`) || `accepted`,
                customer_name:  urlParamValue(`customer_name `) || ``,
                payment_method: urlParamValue(`payment_method`) || ``,
                delivery_type: urlParamValue(`delivery_type`) || ``,
                phone: urlParamValue(`phone`) || ``
            };
            return {...state, data};

        case 'SET-BRANCH-ORDER-PARAMS':
            data.params.restaurant_id = param.parent_id;
            data.params.branch_id = param.id;
            data.params.country_id = param.country_id;
            return {...state, data};

        case 'SET-RESTAURANT-ORDER-PARAMS':
            data.params.restaurant_id = param.restaurant_id;
            data.params.branch_id = ``;
            data.params.country_id = param.country_id;
            return {...state, data};

        default:
            return state
    }
}
