import React from "react";
import Tags from "../../../common/Tags/Tags";
import {ucFirst} from "../../../../utils/StringUtils";
import {convert24To12Hrs, justDate} from "../../../../utils/DateTimeUtils";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import ReservationStatusUpdate from "./ReservationStatusUpdate";
import {toggleFilterSidebar} from "../../../layout/Main/Main-Actions";
import {useDispatch} from "react-redux";

const ReservationStatus = ( { processing, reservation } ) => {
    let dispatch = useDispatch();
    return (
        <div className="box-colored bg-light-gray pb-10">
            <div className="row">
                <div className="col-md-12">
                    <h6>Reservation Details </h6>
                </div>
                { processing ? <GrowingLoader /> :
                    <>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">Payment Method</div>
                                <div className="info-detail text-theme">{reservation && ucFirst(reservation.payment_method || '-')}</div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">Payment Status</div>
                                <div className="info-detail text-success">{reservation && reservation.payment_status ? <Tags status={reservation.payment_status}/> : '-' }</div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">Reservation On</div>
                                <div className="info-detail text-success">
                                    { reservation && reservation.booking_date ?
                                        <span>{justDate(reservation.booking_date)} <br/>
                                <small>{ convert24To12Hrs(reservation.booking_time)}</small>
                                </span>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">No Of Guests</div>
                                <div className="info-detail text-success">
                                    { reservation && reservation.no_of_guests ?
                                        <span>{reservation.no_of_guests}<br/></span>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">Reserved By</div>
                                <div className="info-detail text-success">
                                    { reservation && reservation.reserved_by ?
                                        <span> {ucFirst(reservation.reserved_by)}<br/></span>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="info-title">Reservation Status</div>
                                <div className="info-detail text-success">{ reservation && reservation.reservation_status ? <Tags status={reservation.reservation_status}/> : '-' }</div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-info">
                                <div className="button-wrapper">
                                    <a href="javascript:void(0)" onClick={() => dispatch(toggleFilterSidebar())} className="btn-theme" id="btn-filter">Update Status</a>
                                </div>
                            </div>
                        </div>

                        <ReservationStatusUpdate reservation={ reservation  }/>
                    </>

                }

            </div>
        </div>
    )
}

export default ReservationStatus;