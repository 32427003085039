import axios from 'axios';
import {timeCheck} from "../../../utils/DateTimeUtils";
import {apiBaseUrl} from "../../../utils/URLUtils";
import {handleError, handleSuccess} from "../../../utils/ErrorUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'CREATE_RESERVATION_DAY_TIMESLOT_SUCCESS', success }
}

function _error(error) {
    return { type: 'CREATE_RESERVATION_DAY_TIMESLOT_ERROR', error }
}

function _processing(processing) {
    return { type: 'CREATE_RESERVATION_DAY_TIMESLOT_PROCESSING', processing }
}


function createReservationDayTimeslot(data ) {

    // console.log(` <| data |> `, data )
    let restaurantId = data.branch_id;
    let day_id = data.reservation_day_id;
    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let cond_time = timeCheck(data.time);
        data.time = cond_time;
        // console.log(` <| cond_time |> `, data.payload)

        const getToken = localStorage.getItem('Bearer');
        axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurantId}/day/${day_id}/timeslot`),
            method: "post",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(data)),
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                handleSuccess( res );
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                handleError( error );
                dispatch(_processing(false));

            });
    }
}
export function createReservationDayTimeslotReset() {
    return dispatch => dispatch({type: `CREATE_RESERVATION_DAY_TIMESLOT_RESET`})
}
export default createReservationDayTimeslot;
