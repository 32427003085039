import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import {removeFoodSize} from "./FoodForm-Actions";
import NumberField from "../../../common/form/NumberField";
import {ucFirst} from "../../../../utils/StringUtils";
import RadioField from "../../../common/form/RadioField";
import SwitchField from "../../../common/form/SwitchField";
import CheckBoxFieldSingle from "../../../common/form/CheckBoxFieldSingle";

const FoodSizeForm = ( { onChange, values, slugChange, removeFoodSize, onHandleSizeDefault, updateMode, errors, touched } ) => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {foodForm} = reduxState;
    // const {food_size} = foodForm.data;
    const food_size = values && values.food_size;

    /*const removeFoodSize = ( food_size_id ) => {
        values.food_size = values.food_size.filter(item => item._id !== food_size_id )
    }*/
    // console.log(` <| values in size form |> `,values )
    // console.log(` <| size form |> `,food_size )
    return (

        food_size && food_size.length && food_size.map((fs, key) => {
            return (
                <div className="box-outlined-wrapper" key={key}>
                    <div className="box-outlined" key={fs._id}>
                        {/*{key === 0 ? <div className="default-food">D</div> : null }*/}
                        <div className="row">
                            { fs.size_translation && Object.keys(fs.size_translation).length &&
                                Object.keys(fs.size_translation).map( key1 => {
                                    let lang = key1 === 'ar' ? 'Arabic' : 'English';
                                    return (
                                        <div className="col-sm-6" key={`${key1}${fs._id}}`}>
                                            <TextField name={`food_size.${key}.size_translation.${key1}.title`}
                                                       placeholder={`Size Name ${lang}` }
                                                       label={ `Size Name ${key > 0 ? key : ''} ( ${lang} )` }
                                                        id={`${key}-${key1}`}
                                                        className={ key1 === 'ar' ? 'text-translation' : ''}
                                                       onChange = { key1 === 'en' ? slugChange : onChange }
                                                       value={food_size[key].size_translation && food_size[key].size_translation[key1] && food_size[key].size_translation[key1].title}
                                            />
                                        </div>
                                    )
                                } )
                            }

                            <div className="col-sm-12">
                                <TextField name={`food_size.${key}.size`}
                                           label="Slug"
                                           onChange={ onChange }
                                           value={values && values.food_size[key] && values.food_size[key].size}/>
                            </div>
                            <div className="col-sm-6">
                                <TextField name={`food_size.${key}.price`}
                                             label="Price"
                                           onChange = { onChange }
                                            value={ values && values.food_size[key] && values.food_size[key].price }
                                            error={errors?.food_size?.[key]?.price}
                                            touched={touched?.food_size?.[key]?.price}
                                />
                            </div>
                            <div className="col-sm-6">
                                <NumberField name={`food_size.${key}.calorie`}
                                             label="Calorie"
                                           onChange = { onChange }
                                            value={values && values.food_size[key] && values.food_size[key].calorie}
                                />
                            </div>
                            <div className="col-sm-6">
                                <RadioField
                                    name={`is_default`}
                                             label="Is Default"
                                            id={ updateMode === 'update' ? fs.id : fs._id }
                                            checked={ !!(values && values.food_size[key] && values.food_size[key].is_default === '1')}
                                             onChange = {e=> onHandleSizeDefault( e, updateMode === 'update' ? fs.id : fs._id, key ) }
                                             value={values && values.food_size[key] && values.food_size[key].is_default}
                                />
                            </div>

                        </div>
                    </div>

                     { (values.food_size[key].is_default === '0') ?
                         <div className="edit-block">
                             <ul className="list-unstyled">
                                 {/*<li><a href="#">Edit</a></li>*/}
                                 <li><a href="javascript:void(0)" onClick={() => removeFoodSize(values.food_size[key]._id)}>Delete</a></li>
                             </ul>
                         </div> :
                         (values.food_size.length === 1) ?
                             <div className="edit-block">
                                 <ul className="list-unstyled">
                                     <li><a href="javascript:void(0)" onClick={() => removeFoodSize(values.food_size[key]._id)}>Delete</a></li>
                                 </ul>
                            </div> :
                         null
                     }

                </div>
            )
        })
    )
}

export default FoodSizeForm;