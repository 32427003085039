import {urlParamValue} from "../../../../utils/URLUtils";
import {beginningOfThisMonth, getCurrentDate} from "../../../../utils/DateTimeUtils";

const DefaultSalesReportState = {
    params: {
        start_date: urlParamValue(`start_date`) || beginningOfThisMonth(),
        end_date: urlParamValue(`end_date`) || getCurrentDate(),
        branch_id: urlParamValue(`branch_id`) || '',
        order_status: urlParamValue(`order_status`) || `accepted`,
        payment_status: urlParamValue(`payment_status`) || ``,
        delivery_status: urlParamValue(`delivery_status`) || ``,
        delivery_method: urlParamValue(`delivery_method`) || ``,
        payment_method: urlParamValue(`payment_method`) || ``,
        restaurant_id: urlParamValue( `restaurant_id` ) || ``,
        country_id: urlParamValue(`country_id`) || ``,
        customer_info: false,
        city_id:``,
    },
    monthly_sales_date:``,
    month:``,
    year:``,
    branch_id:``,
    report_filter_open:false,
    report_filter_mode:`parent`
};

export const SalesReportState = {data: DefaultSalesReportState};