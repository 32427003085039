import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../../common/Tags/Tags";
import {useDispatch} from "react-redux";
import deleteAddon from "../../../../ajax/actions/addon/addon_delete";
import swal from "sweetalert"
import {ucFirst} from "../../../../utils/StringUtils";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import ReactTooltip from "react-tooltip";

const AddonListItem = ({addon, currency, url, reducer, branchID}) => {
    const {id, name, price, status, calorie} = addon;
    const dispatch = useDispatch()
    const deleteConfirm = (id) => {
        swal("Are you sure you want to delete this Addon? ", {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id,
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteAddon(id, branchID))
        })
    };

    return (
        <tr>
            <td>{id}</td>
            <td>{ucFirst(name)}</td>
            <td>{currency} {price}</td>
            <td>{calorie || '-'}</td>
            <td>{status ? <Tags status="active"/> : <Tags status="inactive"/>}
            </td>
            <td className="buttons-block">
                <div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div><Link to={`${url}/${id}`} >
                            <button><i className="fa-pencil" data-tip="Edit"/> Edit</button>
                        </Link>
                        </div>
                        <div><a href="javascript:void(0);"
                           onClick={() => deleteConfirm(id)}>
                            {reducer.processing && id === reducer.id ? <GrowingLoader/> :
                                <button><i className="fa-trash" data-tip="Delete"/> Delete</button>
                            }
                        </a></div>
                        {/*<PopConfirm subject="addon"
                            processing={ reducer.processing && id === reducer.id }
                            action={() =>  dispatch( deleteAddon( id, branchID )) } />*/}
                    </div>
                </div>
            </td>
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>

    )
};

export default memo(AddonListItem);