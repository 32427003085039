import {BranchListState} from './BranchList-State'

export default function branchListReducer(state = BranchListState, action) {

    let {data} = {...state};
    let {name, value} = action;

    switch (action.type) {

        case 'TOGGLE-BRANCH-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )
            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        default:
            return state
    }
}
