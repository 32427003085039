import { PartnerIntegrationFormState } from "./PartnerIntegrationForm-State";

export default function PartnerIntegrationFormReducer (state = PartnerIntegrationFormState, action )
{
  let { data } = { ...state };
  let { payload } = action;
  switch( action.type )
  {
    case 'SET-DEFAULT-PARTNER-INTEGRATION-FORM':
      data = {
        partner_id: '',
        external_branch_id: '',
        external_group_menu_id: '',
        restaurant_id: '',
        branch_id: '',
      }
      return { ...state, data };
    
    case 'SET-PARTNER-INTEGRATION-DATA':
      data = {
        partner_id: payload.partner_id,
        external_branch_id: payload.external_branch_id,
        external_group_menu_id: payload.external_group_menu_id,
        restaurant_id: payload.partner_restaurant_id,
        branch_id: payload.branch_id,
        id:payload.id
      }
      return { ...state, data }
    default: return state;
  }
}