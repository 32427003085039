export const AddonCategoryFormState = {
    data: {
        translation:{},
        status:``,
        min_addon:``,
        max_addon:``,
        trans:null,
        countryID:``,
        restaurantID:``,
        parentID:``,
        sort:1
    }
}