import {urlParamValue} from "../../../../utils/URLUtils";
import {beginningOfThisMonth, getCurrentDate} from "../../../../utils/DateTimeUtils";

const DefaultFaultReportState = {
    params: {
        start_date: urlParamValue(`start_date`) || beginningOfThisMonth(),
        end_date: urlParamValue(`end_date`) || getCurrentDate(),
        branch_id: urlParamValue(`branch_id`) || '',
        // country_id: urlParamValue(`country_id`) || ``,
        // city_id:``,
    },
    branch_id:``,
    report_filter_open:false,
    report_filter_mode:`parent`
};

export const FaultReportState = {data: DefaultFaultReportState};