import React, {useRef} from 'react';
import {justDate} from "../../../../utils/DateTimeUtils";
import {ucFirstAll} from "../../../../utils/StringUtils";
import "./CalanderInfo.scss";
import {useClickAway, useKeyPress} from "react-use";
import {closeScheduleInfo} from "./Schedule-Action";
import {useDispatch} from "react-redux";
import useAppState from "../../../../hooks/useAppState";

const CalanderInfo = () => {

    const dispatch = useDispatch()

    const scheduleReducer = useAppState(s => s.scheduleReducer);
    let {params} = scheduleReducer && scheduleReducer.data;
    let {filterData} = params;

    const ref = useRef(null)
    const {pax, time, date, day, reservations} = filterData;

    const closeInfo = () => {
        dispatch(closeScheduleInfo())
    }

    useClickAway( ref, () => ref && closeInfo() );
    useKeyPress( e => e.keyCode === 27 && closeInfo())

    return (

        <div className="CalanderInfo" ref={ref} key={pax + time + date}>
            {/*<div onClick={()=>closeInfo} className="div-info-close">X</div>*/}
            <div className="container-info">
                {reservations && reservations.length ? reservations.map((r, key) => {
                    return (
                        <div className={ `${reservations.length === key+1 ? null : "div-info-box"} mt-10` } key={key}>
                            <small>{ time }, { justDate( date ) }</small><br/>
                            <strong>Reserved By { ucFirstAll( r.customer_full_name )} </strong>
                            <p><small>No Of Guests : <span>{r.no_of_guests }</span></small></p>
                        </div>
                    )
                }) : <small>No Reservation Available.</small>}
            </div>
        </div>
    )
}
export default CalanderInfo;