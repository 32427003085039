import {urlParamValue} from "../../../utils/URLUtils";

const DefaultReservationListState = {
    params: {
        limit: urlParamValue(`limit`) || 15,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        // q: urlParamValue(`q`) || ``,
        restaurant_id:urlParamValue(`restaurant_id`) || ``,
        country_id:urlParamValue(`country_id`) || ``,
        city_id: urlParamValue(`city_id`) || ``,
        branch_id: urlParamValue(`branch_id`) || ``,
    },
    fields: [
        {field: `id`, label: `Order#`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `created_at`, label: `Resered At`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `restaurant_name`, label: `Branch`, sort: true, sort_type:'desc'},
        {field: `customer_name`, label: `Customer`, sort: true, sort_type:'desc'},
        {field: `reservation_date`, label: `Reservation Time`, sort: true, sort_type:'desc'},
        {field: `amount`, label: `Amount`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `no_of_guests`, label: `No Of Guests`, sort: true, sort_type:'desc' },
        {field: `city_name`, label: `City`, sort: true, sort_type:'desc' },
        {field: `payment_method`, label: `Payment Method`, sort: true, sort_type:'desc'},
        {field: `payment_status`, label: `Payment Status`, sort: false},
        {field: `reservation_status`, label: `Reservation Status`, sort: false},
        {field: `reserved_by`, label: `Reserved By`, sort: false},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const ReservationListState = {data: DefaultReservationListState}