import {urlParamValue} from "../../../../utils/URLUtils";

const DefaultAddonsListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) ||1,
        sort_by: urlParamValue(`sort_by`) ||'desc',
        sort_column: urlParamValue(`sort_column`) ||`id`,
        q: urlParamValue(`q`) || ``,
    },
    fields: [
        {field: `id`, label: `ID`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `name`, label: `Name`, sort_icon:false, sort: false},
        {field: `price`, label: `Price`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `calorie`, label: `Calories`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`,sort_icon:false, sort: false},
        {field: ``, label: ``, sort_icon:false,sort: false},
        // {field: ``, label: ``,sort_icon:false, sort: true},
    ],
    addon_filter_open:false,
}

export const AddonsListState = {data: DefaultAddonsListState}