import React from "react";
import {useDispatch} from "react-redux";
import {setTimeslotModalOpen} from "./TimeslotDay-Action";

const TimeslotHeader = () => {
    let dispatch = useDispatch();

    const toggleFilter = () => {
        dispatch(setTimeslotModalOpen('add'))
    }
    return (
        <div className="heading-block">
            <div className="button-wrapper pull-right">
                <a href="javascript:void(0)" onClick={toggleFilter} className="btn-theme" id="btn-filter">Add New</a>
            </div>
        </div>

    )
}

export default TimeslotHeader;