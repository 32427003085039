import axios from 'axios/index'
import { apiBaseUrl } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config'

function _success(success) {
  return { type: 'GET_RESTAURANT_SUCCESS', success }
}

function _error(error) {
  return { type: 'GET_RESTAURANT_ERROR', error }
}

function _processing(processing) {
  return { type: 'GET_RESTAURANT_PROCESSING', processing }
}

function getRestaurant(id) {
  // console.log(` <| id in restuant |> `, id )
  return (dispatch) => {
    dispatch(_processing(true))
    dispatch(_success(null))
    dispatch(_error(null))

    const config = {
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
         'x-app-source': AppSource.appSource
      }
    }
    const url = apiBaseUrl(`restaurant/v1/restaurant/${id}`)
    axios.get(url, config)
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))

      }).catch(function(error) {
      dispatch(_error(error))
      dispatch(_processing(false))
      // handleError(error)

    })
  }
}

export default getRestaurant