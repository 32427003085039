import React, {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Tags from "../../../../common/Tags/Tags";
import {ucFirst, ucFirstAll} from "../../../../../utils/StringUtils";
import swal from "sweetalert";
import {useDispatch} from "react-redux";
import {convert24To12Hrs} from "../../../../../utils/DateTimeUtils";
import {setTimeslotModalOpen, setTimeslotUpdateItem} from "./TimeslotDay-Action";
import updateReservationDayTimeslotStatus
    from "../../../../../ajax/actions/reservations/reservation_day_timeslot_status_put";
import Switch from "react-switch";
import GrowingLoader from "../../../../common/loaders/GrowingLoader";
import useAppState from "../../../../../hooks/useAppState";

const TimeslotDayItem = ({timeslot, url, reducer, branch_id, slotReducer}) => {
    const { time, base_price, blocked_slot,currency, pax, status, id, reservation_day_id } = timeslot;
    let dispatch = useDispatch();
    const [ checked, setChecked ] = useState( status )
    const [ processing, setProcessing ] = useState( ``)

    useEffect(() => {
        if (slotReducer.success) {
            setProcessing('');
        }
    }, [slotReducer.success])

    const toggleConfirm = (statusName, statusNumber) => {
        setChecked( !status )
        setProcessing( id )
        let data = {
            status:statusNumber,
            branch_id:branch_id,
            reservation_day_id:reservation_day_id

        }
        dispatch(updateReservationDayTimeslotStatus( data, id ))
        /*swal(`Are you sure you want to ${ statusName } this slot ? `, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Change",
                }
            }
        }).then((value) => {
            if (value) dispatch( updateReservationDayTimeslotStatus( data, id ) )
        })*/
    };

    function updateViewSlot( mode, id ) {
        dispatch(setTimeslotModalOpen(mode ))
        dispatch(setTimeslotUpdateItem( id ))
    }

    let statusNumber = status === 0;
    return (
        <tr>
            <td>{ convert24To12Hrs(time)}</td>
            <td>{currency} {base_price}</td>
            <td>{pax}</td>
            <td>{blocked_slot || '-'}</td>
            <td><Tags status={status ? 'open' :'closed' }/></td>
            <td>
                {/*<button className="btn-toggle-status ml-2 btn-primary" onClick={() => toggleConfirm( status === false ? 'enable' : 'disable', statusNumber )}>
                Toggle Status
                </button>*/}
                <Switch
                    onChange={() => toggleConfirm( !checked, statusNumber )}
                    checked={checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                />
                { processing ? <GrowingLoader /> : null }
            </td>
            <td className="buttons-block">
                <div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div><button onClick={ () => updateViewSlot( 'update', id ) }>
                            <i className="fa-pencil" data-tip="Edit"/> Edit
                        </button></div>

                        <div><button onClick={() => updateViewSlot( 'view', id )}>
                            <i className="fa-eye" data-tip="View"/> View
                        </button></div>
                    </div>
                </div>
            </td>
        </tr>
    )
};

export default memo(TimeslotDayItem);