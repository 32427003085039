import {BranchSubHeaderState} from './BranchSubHeader-State'

export default function branchSubHeaderReducer(state = BranchSubHeaderState, action) {

    let {data} = {...state};
    let {headerDetails} = action;

    switch (action.type) {
        case 'SET-BRANCH-HEADER-DETAILS':
            data = {
                title:headerDetails.title,
                icon:headerDetails.icon,
                linkUrl:headerDetails.linkUrl || ``,
                action:headerDetails.action,
                id:headerDetails.id || ``,
                filter:headerDetails.filter || false,
                processing:headerDetails.processing || false,
            };
            return {...state, data};
        default:
            return state
    }
}
