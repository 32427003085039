import React, {useEffect} from "react";
import OrderHeader from "./OrderHeader";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../common/table/Table";
import getOrders, {getOrdersReset} from "../../../ajax/actions/Orders/orders_get";
import {setBranchID, setBranchOrderParams, setDefaultOrderParams, toggleOrderListFilter} from "./OrderList-Action";
import TableDataLoader from "../../common/loaders/TableDataLoader";
import {useParams, useRouteMatch} from "react-router";
import {setBranchHeaderDetails} from "../branches/Header/BranchSubHeader-Action";
import {setIsList, setPaginationData} from "../branches/specific/BranchInfo-Action";
import OrderListItem from "./OrderListItem";
import NoDataAvailable from "../../common/NotFound/NoDataAvailable";
import {setUserRole, toggleFilterSidebar} from "../../layout/Main/Main-Actions";
import getOrder from "../../../ajax/actions/Orders/order_get";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const BranchOrders = () => {

    const reduxState = useSelector(state => state);
    const {main, ordersGet, orderList, userDetails, branchInfoReducer} = reduxState;
    let {fields, params} = orderList && orderList.data;
    let {url} = useRouteMatch();
    let {restaurant_id, branch_id} = params;
    let {filter_sidebar_open, user_role} = main.data;
    // console.log(` <| branchInfoReducer |> `, branchInfoReducer)
    let dispatch = useDispatch();
    useTitle(Title.name + ` Orders`);
    useEffect(() => {
        let details = {
            title: 'Orders',
            icon: 'order',
            action: false,
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch(setIsList(true));

        const interval = setInterval(() => {
            branch_id && dispatch(getOrders(params))
        }, 60000);

        return () => {
            dispatch(getOrdersReset())
            filter_sidebar_open && dispatch(toggleFilterSidebar())
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (branchInfoReducer) {
            branchInfoReducer.data && branchInfoReducer.data.details && dispatch(setBranchOrderParams(branchInfoReducer.data.details))
        }
    }, [branchInfoReducer])

    useEffect(() => {
        branch_id && dispatch(getOrders(params))
        dispatch(setBranchID(branch_id))
    }, [branch_id])

    useEffect(() => {
        dispatch(setPaginationData(ordersGet, toggleOrderListFilter));
    }, [ordersGet])

    // console.log(` <| ordersGet |> `, ordersGet )
    let list = ordersGet &&
        ordersGet.success &&
        ordersGet.success.data &&
        ordersGet.success.data.data;
    return (
        <>
            <div className="table-block table-responsive pl-50">
                <Table reducer={ordersGet}
                       action={toggleOrderListFilter}
                       params={params}
                       columns={fields}>
                    <tbody>
                    {ordersGet.processing ? <TableDataLoader colspan={10}/> :
                        list && list.length ? list.map(order => {
                                return (
                                    <OrderListItem key={order.id}
                                                   order={order}
                                                   branchAdmin={true}
                                                   reducer={ordersGet}/>
                                )
                            }
                        ) : <NoDataAvailable colspan={10}/>}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default BranchOrders;