import {urlParamValue} from "../../../../../utils/URLUtils";

const DefaultPartnerIntegrationListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) || ``,
    },
    fields: [
        {field: `id`, label: `Partner ID #`, sort_icon:true,sort: true, sort_type:'desc'},
        {field: `created_at`, label: `Created`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `partner`, label: `Partner`, sort: true, sort_icon:true, sort_type:'desc'},
        {field: `partner_type`, label: `Partner Type`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`, sort: false},
        {field: `action`, label: `Action`, sort: false},
    ],
    partner_modal_open:false,
    is_update:false
    
}

export const PartnerIntegrationListState = {data: DefaultPartnerIntegrationListState}