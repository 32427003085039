import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
// import ReviewHeader from "./ReviewHeader";
import Table from "../../common/table/Table";
import PaginationButtons from "../../common/buttons/PaginationButtons";
import getRestaurantReviews from "../../../ajax/actions/review/restaurant_reviews_get";
import ReviewListItem from "./ReviewListItem";
import {toggleReviewListFilter} from "./ReviewList-Action";
import ReviewDetails from "./modal/ReviewDetails";
import RestaurantHeader from "../../layout/Header/RestaurantHeader";
import TableDataLoader from "../../common/loaders/TableDataLoader";
import NoDataAvailable from "../../common/NotFound/NoDataAvailable";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const Reviews = () => {
    const reduxState = useSelector(state => state);
    const { restaurantReviewsGet, reviewList } = reduxState;
    const { success } = restaurantReviewsGet;
    const list = success && success.data && success.data.data;
    let current_page = success && success.data && success.data.meta && success.data.meta.current_page;
    let last_page = success && success.data && success.data.meta && success.data.meta.last_page;
    let total = success && success.data && success.data.meta && success.data.meta.total;
    let pages = { current_page, last_page, total };

    let { fields, params } = reviewList && reviewList.data;

    useTitle(Title.name + ` Review`);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch( getRestaurantReviews() )
    }, [ ])

    return (
        <>
            <RestaurantHeader title="Reviews" icon="review" />
            {/*<ReviewHeader/>*/}
            <div className="table-block table-responsive pl-50">
                <Table reducer={ restaurantReviewsGet }
                       action={toggleReviewListFilter}
                       params={params}
                   columns={fields}>
                    <tbody>
                    { restaurantReviewsGet.processing ? <TableDataLoader colspan={6} /> :
                        list && list.length ? list.map( review => {
                        return  (
                            <ReviewListItem key={ review.id } review={ review } />
                        )}
                    ) : <NoDataAvailable colspan={6}/>}
                    </tbody>
                </Table>
            </div>
            <div className="fixed-footer">
                    <PaginationButtons pages={pages }
                                   fetchAction={ toggleReviewListFilter }
                                   processing={ restaurantReviewsGet && restaurantReviewsGet.processing }/>
            </div>
            <ReviewDetails />
        </>
    )
}

export default Reviews;