import { AppState } from "./App-State";

export default function AppStateReducer (state = AppState, action )
{
  let { data } = { ...state };

  switch( action.type )
  {
    case `SCREEN-RESIZED`:
      data.screen.width = window.innerWidth;
      return { ...state,  data };

      case `LOGIN-CHANGED`:
      data.loggedIn = !data.loggedIn;
      return { ...state,  data };

    default: return state;
  }
}