import { AddonFormState } from "./AddonForm-State";
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../utils/ErrorUtils";

export default function AddonFormReducer (state = AddonFormState, action )
{
  let { data } = { ...state };
  let {  validationErrors, branchData,  addon } = action;
  switch( action.type )
  {
    case 'SET-BRANCH-DATA-ADDON':
      let _new_translation = {};
      branchData && branchData.translation && branchData.translation.map( lang => {
        _new_translation[lang.lang_code] = {
          name:"",
          description:``
        }
      });
      data.translation = _new_translation;
      data.parentID = branchData && branchData.parent_id;
      data.restaurantID = branchData && branchData.id;
      data.countryID = branchData && branchData.country_id;
      data.trans = branchData && branchData.translation;
      return {...state, data};

    case 'SET-ADDON':
      let updtranslation = {};
      let adn_cate = [];
      addon.add_on_translations.map(( item)=> {
        updtranslation[ item.lang ] = {
          name: item.name,
          description:item.description
        };
      });
      addon.addon_categories.map( adn => {
        adn_cate.push(adn.id);
      })
      data.selected_addon_categories = addon.addon_categories;
      data.addon_categories = adn_cate;
      data.translation = updtranslation;
      data.status = addon.status;
      data.price = addon.price;
      data.calorie = addon.calorie;
      return {...state, data};

    case 'SET-DEFAULT-ADDON-FORM':
      data = {
        translation:{},
        status:``,
        price:``,
        calorie:``,
        trans:null,
        addon_categories:[],
        selected_addon_categories:[],
        restaurantID:``,
        parentID:``
      }
      return {...state, data};
    default: return state;
  }
}