const DefaultBranchSubHeaderState = {
    data:{
        title:``,
        icon:``,
        action:false,
        linkUrl:``,
        filter:false,
        processing:false,
    }
}
export const BranchSubHeaderState = {data: DefaultBranchSubHeaderState}