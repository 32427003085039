import axios from 'axios';
import { apiBaseUrl } from '../../../utils/URLUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success){
    return { type: 'GET_ORDER_REJECT_STATUS_SUCCESS', success }
}
function _error(error){
    return { type:'GET_ORDER_REJECT_STATUS_ERROR', error }
}
function _processing(processing){
    return { type: 'GET_ORDER_REJECT_STATUS_PROCESSING', processing }
}

function getOrderRejectStatus( ) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        let config = {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`) },
            'x-app-source': AppSource.appSource

        };
        axios.get(apiBaseUrl(`order/v1/public/order/reject/status`), config)
          .then(res => {
              dispatch(_success(res));
          }).catch(error => {
              dispatch(_error(error));
            // handleError( error )
          });
    }
}
export function getOrderRejectStatusReset()
{
    return dispatch => dispatch({ type: `GET_ORDER_REJECT_STATUS_RESET` })
}
export default getOrderRejectStatus;