export function setRestaurantDetails() {
    return function ( dispatch, getState ) {
        let { getRestaurantBranch } = getState();
        let restaurant_data = getRestaurantBranch?.success?.data?.data;
        dispatch({type: 'SET-BRANCH-DETAILS',restaurant_data});
    }
}

export function setIsList(flag) {
    return function ( dispatch, ) {
        dispatch({type: 'SET-IS-LIST', flag });
    }
}

export function setPaginationData( reducer, action ) {
    // console.log(` <| reducer |> `,reducer )
    return function ( dispatch, ) {

        let { success, processing } = reducer;
        let current_page = ``; let last_page = ``; let total = ``;
        /*if( success && success.data && success.data.payload ) {
            current_page = success && success.data.payload.current_page;
            last_page = success && success.data.payload.last_page;
            total = success && success.data.payload.total;

        } else if( success && success.data && success.data.data && success.data.data.current_page) {
            current_page = success && success.data.data.current_page;
            last_page = success && success.data.data.last_page;
            total = success && success.data.data.total;
        } else if( success && success.data ) {
            current_page = success && success.data.current_page;
            last_page = success && success.data.last_page;
            total = success && success.data.total;
        }*/
        if( success?.data?.meta?.pagination ) {
            current_page = success?.data?.meta?.pagination?.current_page;
            last_page = success?.data?.meta?.pagination?.total_pages;
            total = success?.data?.meta?.pagination?.total;
        } else if( success?.data?.meta ) {
            current_page = success?.data?.meta?.current_page;
            last_page = success?.data?.meta?.last_page;
            total = success?.data?.meta?.total;
        } else if( success?.data ) {
            current_page = success?.data?.current_page;
            last_page = success?.data?.last_page;
            total = success?.data?.total;
        }
        let pages = { current_page, last_page, total };

        dispatch({type: 'SET-PAGINATION-DATA', pages, processing, fetchAction:action });
    }
}