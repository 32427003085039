import React, { memo } from "react";
import exclamation from "./exclamation.png";
import { Link } from "react-router-dom";
import './ErrorTableRow.scss'
import useCurrentPageTitle from "../../../hooks/useLocation";

const ErrorTableRow = ({ colSpan }) =>
{
  return (

    <tr className="ErrorTableRow fadeInDown">
      <td className="text-center" colSpan={ colSpan }>

        <img src={ exclamation } alt="Not Found" />

        <p>There was a problem retrieving { useCurrentPageTitle().toLowerCase() }.</p>

        <Link className="btn-theme" to="/home">Go Home</Link>

      </td>
    </tr>

  )
};

export default memo( ErrorTableRow )