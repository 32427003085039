import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../../common/Tags/Tags";
import {fullDate, fullDate12Hrs, fullDates, justDate} from "../../../../utils/DateTimeUtils";
import {useDispatch, useSelector} from "react-redux";
import deleteFood from "../../../../ajax/actions/foods/food_delete";
import {ucFirst, ucFirstAll} from "../../../../utils/StringUtils";
import swal from "sweetalert";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import ReactTooltip from "react-tooltip";

const FoodReviewListItem = ({food, currency, branchID, url, reducer, inventory, key}) => {
    let dispatch = useDispatch();
    const {id, featured_image, name, price, item_type, out_of_stock, qty, discount, created_at, request_status, calorie, preparation_time} = food;

    const deleteConfirm = (id) => {
        swal("Are you sure you want to delete this food? ", {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id,
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteFood(branchID, id))
        })
    };
    return (

        <tr key={id}>
            <td>{id}</td>
            {/* <td>{(featured_image && featured_image.length) ? (
                <img src={featured_image} alt={name} width="46"/>
            ) : ''}
            </td> */}
            <td><Link to={`${url}/${id}`}>{ucFirst(name)}</Link></td>
            <td>{currency} {price}</td>
            <td>{calorie || '-'}</td>
            {inventory === 1 ? <td>{ qty || 0 }</td> : null }
            <td>{discount || '-'}</td>
            {/*<td>{ucFirst(item_type)}</td>*/}
            <td>{out_of_stock === '1' || out_of_stock ? <Tags status="out-of-stock" color="danger"/> :
                <Tags status="no" color="success" />}</td>
            {/* <td>{ preparation_time ? preparation_time + ` minute(s)` : 'N/A'}</td> */}
            <td>{fullDates(created_at)}</td>
            <td>{<Tags status={request_status}/>}
            </td>
            {/* <td className="buttons-block">
                <div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div><Link to={`${url}/${id}`}>
                            <button ><i className="fa-pencil" data-tip="Edit"/> Edit</button>
                        </Link>
                        </div>
                        <div>
                            <a href="javascript:void(0);"
                           onClick={() => deleteConfirm(id)}>
                                {reducer.processing && id === reducer.id ? <GrowingLoader/> : <button ><i className="fa-trash" data-tip="Delete"/> Delete</button>}
                        </a>
                        </div>
                    </div>
                </div>
            </td> */}
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>

    )
};

export default memo(FoodReviewListItem);