export const addonCategoryFormChange = ( name, value )   => dispatch => dispatch({ type: `ADDON-CATEGORY-FORM-CHANGE`, name, value } );
export const addonCategoryTranslationFormChange = ( name, value, id )   => dispatch => dispatch({ type: `ADDON-CATEGORY-TRANSLATION-FORM-CHANGE`, name, value, id } );
export function addonCategoryFormSubmit()  {
    return function (dispatch) {
        dispatch({type: `ADDON-CATEGORY-FORM-SUBMIT`});
    }
}

export function setAddonCategory( addonCategory )  {
    return function (dispatch) {
        // console.log(` <| addonCategory |> `,addonCategory )
        let addon_category = addonCategory && addonCategory.data && addonCategory.data.data;
        dispatch({type: `SET-ADDON-CATEGORY`, addonCategory:addon_category});
    }
}
export const setDefaultAddonCategoryForm = ()         => dispatch => dispatch({ type: `SET-DEFAULT-ADDON-CATEGORY-FORM` } );
export const setBranchDataAddonCategory = ( branchData )         => dispatch => dispatch({ type: `SET-BRANCH-DATA-ADDON-CATEGORY`, branchData:branchData.data.details } );
export const cancelAddonCategoryFormDispatch = ()         => dispatch => dispatch({ type: `CANCEL-ADDON-CATEGORY-FORM-DISPATCH` } );
export const addonCategoryFormServerValidationErrors = (validationErrors)         => dispatch => dispatch({ type: `ADDON-CATEGORY-FORM-SERVER-VALIDATION-ERRORS`, validationErrors } );