import { fromRecord, inRecord } from './DataUtils'
import { queryStringPresentInURL, toggleURLQuery } from './URLUtils'
import { ucFirst } from './StringUtils'

/*export const setValuesToURL = ( title, history, ) =>
{
  if ( !queryStringPresentInURL( )) {
    let queries =
      inRecord( pagesData, `title`, `==`, title ) ?
        fromRecord( pagesData, `title`, `==`, title ).defaultFilters : [];
    queries && queries.map( q => toggleURLQuery(q.name, q.value, history))
  }
}*/


export const updateComponentWhenResized = ( _this ) =>
{
  if ( _this ) window.addEventListener(`resize`, () => _this.forceUpdate() )
}

export const removeUpdateComponentWhenResized = ( _this ) =>
{
  if ( _this ) window.removeEventListener(`resize`, _this.forceUpdate )
}

export const partOfSpacedString = ( string, limit ) => {
  if ( string ) {
    let part = string.split(` `);
    let newString = ``;
    for (let i = 0; i < limit; i++) {
      newString += `${ part[i] } `;
    }
    return newString;
  }
  return ``;
};

export const unslugifyWithSeparator = (text, separator) => {
  if (text) {
    let string = ``;
    let part = text.split(separator);
    if (!part.length) return part;
    if (part.length) part.map(item => {
      string += ucFirst(item) + ' ';
    });
    return string.trim();
  }
};

export const capitalizeWithSeparator = (text, separator) => {
  if (text && text.includes( separator )) {
    let string = ``;
    let part = text.split(separator);
    if (!part.length) return part;
    if ( part.length ) part.map(item => {
      string += ucFirst(item) + separator;
    });
    return string.substring(0, string.length - 1).trim();
  }
};

export const currencyFormat = n => {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export const objectIsEmpty = (obj) =>
{
  for (let key in obj) {
    if(obj.hasOwnProperty(key)) return false;
  }

  return true;
};

export const ArabicTranslate = (string) => {
  // const arabicRegex = /^[\u0600-\u06FF\s]*$/;
  const arabicRegex = /^[\u0600-\u06FF\s\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
  const newValue = string;
  if (arabicRegex.test(newValue)) {
      return newValue
  }
}

export const EnglishTranslate = (string) => {
  // const englishRegex = /^[A-Za-z0-9_@./,""''?<>#&+*%-\s]*$/;
  const englishRegex = /^[A-Za-z0-9\s\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
  const newValue = string;
  if (englishRegex.test(newValue)) {
    return newValue;
  }
}