import {ReservationListState} from './ReservationList-State'
import {urlParamValue} from "../../../utils/URLUtils";

export default function reservationListReducer(state = ReservationListState, action) {

    let {data} = {...state};
    let { name, value, param } = action;
    switch (action.type) {

        case 'TOGGLE-RESERVATION-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )

            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'SET-DEFAULT-RESERVATION-PARAMS':
            data.params = {
                limit: urlParamValue(`limit`) || 15,
                page: urlParamValue(`page`) || 1,
                sort_by: urlParamValue(`sort_by`) || 'desc',
                sort_column: urlParamValue(`sort_column`) || `id`,
                restaurant_id:urlParamValue(`restaurant_id`) || ``,
                country_id:urlParamValue(`country_id`) || ``,
                city_id: urlParamValue(`city_id`) || ``,
                branch_id: urlParamValue(`branch_id`) || ``,
            };
            return {...state, data};

        case 'SET-BRANCH-RESERVATION-PARAMS':
            data.params.restaurant_id = param.parent_id;
            data.params.branch_id = param.id;
            data.params.country_id = param.country_id;
            return {...state, data};

        case 'SET-RESTAURANT-RESERVATION-PARAMS':
            data.params.restaurant_id = param.restaurant_id;
            data.params.branch_id = ``;
            data.params.country_id = param.country_id;
            return {...state, data};

        default:
            return state
    }
}
