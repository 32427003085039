import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../common/table/Table";
import {
    setBranchReservationParams, setDefaultReservationParams, setRestaurantReservationParams,
    toggleReservationListFilter
} from "./ReservationList-Action";
import TableDataLoader from "../../common/loaders/TableDataLoader";
import {useParams, useRouteMatch} from "react-router";
import ReservationListItem from "./ReservationListItem";
import RestaurantHeader from "../../layout/Header/RestaurantHeader";
import NoDataAvailable from "../../common/NotFound/NoDataAvailable";
import {toggleFilterSidebar} from "../../layout/Main/Main-Actions";
import PaginationButtons from "../../common/buttons/PaginationButtons";
import useAppState from "../../../hooks/useAppState";
import {getReservationReset} from "../../../ajax/actions/reservations/reservation_get";
import getReservations from "../../../ajax/actions/reservations/reservations_get";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const Reservations = () => {

    const reservationsGet = useAppState( s => s.reservationsGet )
    const reservationList = useAppState( s => s.reservationList )
    const getRestaurantBranch = useAppState( s => s.getRestaurantBranch )
    const userDetails = useAppState( s => s.userDetails )
    const main = useAppState( s => s.main )

    let {fields, params} = reservationList && reservationList.data;
    let {url} = useRouteMatch();
    let {restaurant_id, branch_id} = params;
    let dispatch = useDispatch();
    let {filter_sidebar_open} = main.data;

    useTitle(Title.name + ` Reservations`);

    useEffect(() => {
        dispatch(setDefaultReservationParams())
        dispatch(getReservationReset());
        return () => {
            dispatch(getReservationReset())
            filter_sidebar_open && dispatch(toggleFilterSidebar())
        }
    }, [])

    useEffect(() => {
        let userDetail_success = userDetails && userDetails.success;
        if (userDetail_success) {
            dispatch(setRestaurantReservationParams(userDetail_success))
        }
    }, [userDetails])



    useEffect(() => {
        if (!branch_id) {
            restaurant_id && dispatch(getReservations(params))
        }
    }, [restaurant_id, branch_id])


    // console.log(` <| orderList |> `, orderList )
    let list = reservationsGet &&
        reservationsGet.success &&
        reservationsGet.success.data &&
        reservationsGet.success.data.data.data;
    const {success} = reservationsGet;
    /*let current_page = success && success.data && success.data.meta && success.data.meta.pagination.current_page;
    let last_page = success && success.data && success.data.meta &&  success.data.meta.pagination.total_pages;
    let total = success && success.data && success.data.meta && success.data.meta.pagination.total;*/
    let current_page = success && success.data && success.data.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data &&  success.data.data.last_page;
    let total = success && success.data && success.data.data && success.data.data.total;
    let pages = {current_page, last_page, total};
    return (
        <>
            <RestaurantHeader title="Reservation" icon="order"/>
            <div className="table-block table-responsive pl-50">
                <Table reducer={reservationsGet}
                       params={params}
                       action={toggleReservationListFilter}
                       columns={fields}>
                    <tbody>
                    {reservationsGet.processing ? <TableDataLoader colspan={10}/> :
                        list && list.length ? list.map(order => {
                                return (
                                    <ReservationListItem key={order.id}
                                                   reservation={order}
                                                   reducer={reservationsGet}/>
                                )
                            }
                        ) : <NoDataAvailable colspan={10}/>}
                    </tbody>
                </Table>
            </div>
            {list && list.length ? (
                <div className="fixed-footer">
                    <PaginationButtons pages={pages}
                                       fetchAction={toggleReservationListFilter}
                                       processing={reservationsGet && reservationsGet.processing}/>
                </div>
            ) : null}
        </>
    )
}

export default Reservations;