import {urlParamValue} from "../../../../utils/URLUtils";

const DefaultTimeslotListState = {
    params: {
        limit: urlParamValue(`limit`) || 15,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
    },
    fields: [
        {field: `day`, label: `Day`, sort_icon:false, sort: false, sort_type:'desc'},
        {field: `total_slots`, label: `Total Slots`, sort_icon:false, sort: false, sort_type:'desc'},
        {field: `total_pax`, label: `Total Pax`, sort: false, sort_type:'desc'},
        {field: `available_status`, label: `Available Status`, sort: false, sort_type:'desc'},
        {field: `toggle_Status`, label: `Availability`, sort: false, sort_type:'desc'},
        {field: `action`, label: `Action`, sort: false},
    ],
    list:[],
}

export const TimeslotListState = {data: DefaultTimeslotListState}