import {urlParamValue} from "../../../utils/URLUtils";

const DefaultReviewListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) || ``,
    },
    fields: [
        {field: `order_id`, label: `Order #`, sort_icon:true,sort: true, sort_type:'desc'},
        {field: `created_at`, label: `Created`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `restaurant_id`, label: `Restaurant Branch`, sort: true, sort_icon:true, sort_type:'desc'},
        {field: `rating`, label: `Rating`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`, sort: false},
        {field: `action`, label: `Action`, sort: false},
    ],
    review_modal_open:false,
    details:``,
    // fields:[`Branch ID`, `Name`, `Email Address`, `Phone No`, `Status`, `District`, `City`, `Action`]
}

export const ReviewListState = {data: DefaultReviewListState}