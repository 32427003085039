export default function getAddonCategoriesReducer
  (state = { error:null, success:null, processing:false }, action) {

  switch (action.type)
  {
    case 'GET_ADDON_CATEGORIES_SUCCESS':
      return {...state, ...{success: action.success }};

    case 'GET_ADDON_CATEGORIES_ERROR':
      return {...state, ...{ error: action.error }};

    case 'GET_ADDON_CATEGORIES_PROCESSING':
      return {...state, ...{ processing: action.processing }};

    default: return state;
  }
}