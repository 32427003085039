import {urlParamValue} from "../../../../../utils/URLUtils";

const DefaultTimeslotDayState = {
    params: {
        limit: urlParamValue(`limit`) || 15,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
    },
    fields: [
        {field: `time`, label: `Time`, sort_icon:false, sort: false, sort_type:'desc'},
        {field: `price`, label: `Price`, sort_icon:false, sort: false, sort_type:'desc'},
        {field: `total_pax`, label: `Total Pax`, sort: false, sort_type:'desc'},
        {field: `blocked_slot`, label: `Blocked Slot`, sort: false, sort_type:'desc'},
        {field: `available_status`, label: `Available Status`, sort: false, sort_type:'desc'},
        {field: `toggle_status`, label: `Toggle Status`, sort: false, sort_type:'desc'},
        {field: `action`, label: `Action`, sort: false},
    ],
    list:[],
    timeslot_modal_open:false,
    mode:``,
    updateItem:``,
    hour_min_options:[],
}

export const TimeslotDayState = {data: DefaultTimeslotDayState}