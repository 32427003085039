import {TimeslotListState} from './TimeslotList-State'
import {urlParamValue} from "../../../../utils/URLUtils";
import {fromRecord, inRecord} from "../../../../utils/DataUtils";

export default function timeslotListReducer(state = TimeslotListState, action) {

    let {data} = {...state};
    let { name, value, param, success, id, payload } = action;
    switch (action.type) {

        case 'TOGGLE-TIMESLOT-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )

            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'SET-DEFAULT-TIMESLOT-PARAMS':
            data.params = {
                limit: urlParamValue(`limit`) || 15,
                page: urlParamValue(`page`) || 1,
                sort_by: urlParamValue(`sort_by`) || 'desc',
                sort_column: urlParamValue(`sort_column`) || `id`,
                restaurant_id:urlParamValue(`restaurant_id`) || ``,
                country_id:urlParamValue(`country_id`) || ``,
                city_id: urlParamValue(`city_id`) || ``,
                branch_id: urlParamValue(`branch_id`) || ``,
            };
            return {...state, data};

        case 'SET-BRANCH-TIMESLOT-PARAMS':
            data.params.restaurant_id = param.parent_id;
            data.params.branch_id = param.id;
            data.params.country_id = param.country_id;
            return {...state, data};

        case 'GET_RESERVATION_TIMESLOTS_SUCCESS':
            data.list = success && success.data && success.data.data;
            return {...state, data};

        case 'CHANGE-STATUS-TIMESLOT':
            if( inRecord( data.list, 'id', '===', id ) ) {
                let filterd = fromRecord( data.list, 'id', '===', id );
                filterd['closed'] = filterd.closed !== true;
                data.list = data.list.filter( item => item.id !== id);
                data.list.push( filterd );
                // data.list = filterd
            }
            return {...state, data};

        case 'SET-RESTAURANT-TIMESLOT-PARAMS':
            data.params.restaurant_id = param.restaurant_id;
            data.params.branch_id = ``;
            data.params.country_id = param.country_id;
            return {...state, data};

        default:
            return state
    }
}
