import { useEffect } from "react";
import store from "../store";
import { screenResized } from "../components/layout/App/App-Actions";

const useScreenResize = () =>
{
  useEffect(() => {

    window.addEventListener(`resize`, resized)
    return () => {
      window.removeEventListener(`resize`, resized)
    }

  }, []);

  const resized = () => store.dispatch( screenResized() )
}

export default useScreenResize