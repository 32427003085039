import React, {useState, useEffect} from 'react';
import {names, ucFirst, unslufigy} from '../../../utils/StringUtils'
import Div from "../Div/Div";

const UploadImage = (props) => {
    const {value, name, label, errors, onChange, noLabel, labelIcon, required, id, touched, error, multiple, onRemoveImage, uploadType } = props;

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const hasError = error && touched;

    const Label = () => {
        if (label) return (
            <Div className={names(`label-text`)}>
                {label} {hasError ? error + '.' : null}
            </Div>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return (
                    <Div className={names(`label-text`)}>
                        {_label} {hasError ? error + '.' : null}
                    </Div>
                )
            }
        }
        return null
    }


    const removeFile = (name) =>
    {
        let files = {};
        Object.keys( value ).map(key => {
            if(value[key].name !== name ) {
                files[key] = value[key];
            }
        })

        onChange({ target: { name, value: files } })
    }

    const multipleImages = ( values ) => {
        if( multiple ) {
            // console.log(` <| values |> `, values)
            values && values.length && values.map( (item, i )=> {
                return (
                    <div key={i} className="gallery-preview">
                        <i id={i} className="delete-icon" onClick={ onRemoveImage } />
                        <img src={item} alt={`gallery-img-${i}`} />
                    </div>
                )
            } )
        }

    };
// console.log(` <| value img upoatType |> `,value )
    return (
        <div className={names(`form-group`, hasError && `has-error`)}>
            <Label/>
            <div className="input-file-wrapper">
                <input type="file"
                       className="form-control"
                       multiple={ multiple }
                       onChange={e => onChange({ target: { name, value: e.target.files, id, files: files.concat(Array.from(e.target.files))  } })}/>
                <div className="text-wrapper">
                    <span>Upload Image</span>
                </div>
            </div>
            { uploadType === 'featured' && value ?
                <div className="gallery-preview">
                    <img src={value} className="mt-2" alt={ value ? 'featured-image' : ''} width="250"/>
                    {value && <i className="icon-close" onClick={ onRemoveImage } /> }
                </div>
                : null}

            {uploadType === "multiple" && value ? value.map( (item, i )=> {
                return (
                    <div key={i} className="gallery-preview">
                        <img src={item} alt={`gallery-img-${i}`} width="150"/>
                        <i id={i} className="icon-close" onClick={ onRemoveImage } />
                    </div>
                )
            } ) : null }
            {/*{ multiple ? multipleImages( value ) :
                value ? <img src={value} className="mt-2" alt="featured-images" width="250"/> : null }*/}

            {/*{ !image ? uploadButton :  null }*/}

        </div>
    )
}
export default UploadImage;