export default function getRestaurantAdminDashboard(state = {success:'', error:'', processing:''},action)
{
 switch(action.type) {
   case 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_SUCCESS':
     return {...state, ...{success:action.success}}

   case 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_ERROR':
     return {...state, ...{error:action.error}}

   case 'GET_RESTAURANT_ADMIN_DASHBOARD_STATISTICS_PROCESSING':
     return {...state, ...{processing:action.processing}}
   default: return state;  
 }
}