import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import {setIsList,} from "../../branches/specific/BranchInfo-Action";
import FaultReportFilter from "./FaultReportFilter";
import FaultHeader from "./FaultHeader";
import getFaultReportStats from "../../../../ajax/actions/report/fault/fault_report_get";
import sarSvg from "../../../../assets/images/sar.svg";
import orderSvg from "../../../../assets/images/order.svg";

const BranchFaultReport = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {faultReportReducer, getFaultReportReducer, userDetails } = reduxState;
    let {params} = faultReportReducer && faultReportReducer.data;
    let branch_id = userDetails?.success?.branch_id;
    let {url} = useRouteMatch();
    params.branch_id = branch_id
    useEffect(() => {
        let details = {
            title: 'Fault Report',
            icon: 'report',
            action: false,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch(setIsList(false));
        dispatch( getFaultReportStats(params) )

    }, []);

    let data = getFaultReportReducer?.success?.data?.data;
    return (
        <>
            <div className="heading-block">
                <FaultHeader filterMode="branch"/>
            </div>
            <div className="plr-35">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={sarSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Amount</div>
                                    <div className="info-detail">{data && data?.currency}{data && data?.total_amount || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-green">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Fault Count</div>
                                    <div
                                        className="info-detail">{data && data.total_faults_count || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="box-colored box-colored-misc bg-light-orange">
                            <div className="d-flex align-items-end">
                                <img className="mr-4 svg-img" src={orderSvg} alt=""/>
                                <div className="single-info m-0">
                                    <div className="info-title">Total Order Count</div>
                                    <div className="info-detail">{data && data.total_orders_count || 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(BranchFaultReport);