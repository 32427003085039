import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../Header/BranchSubHeader-Action";
import {setIsList} from "./BranchInfo-Action";
import InfoStats from "./InfoStats";
import {ucFirst} from "../../../../utils/StringUtils";
import Tags from "../../../common/Tags/Tags";

const BranchInfo = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { branchInfoReducer } = reduxState;
    useEffect(() => {
        let details = {
            title: `Info`,
            icon: 'info',
            action: false,
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch(setIsList(false))
    }, []);

    // console.log(` <| branchInfoReducer |> `, branchInfoReducer)
    let branch = branchInfoReducer?.data?.details;
    let restaurant_name_en, restaurant_name_ar, address_en, address_ar = ``;
    let translation = branch && branch.translation;
    translation && translation.length && translation.map(t => {
        if (t.lang_code === 'en') {
            restaurant_name_en = t.name;
            address_en = t.address;
        } else if( t.lang_code === 'ar' ) {
            restaurant_name_ar = t.name;
            address_ar = t.address;
        }
    });

    return (
        <>
            <InfoStats />

            <div className="plr-35">
                <div className="row">
                    <div className="col-md-6">
                        <div className="box-outlined pb-10">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Restaurant Branch Name (EN)</div>
                                        <div className="info-detail">{ucFirst(restaurant_name_en)}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Restaurant Branch Name (AR)</div>
                                        <div className="info-detail">{restaurant_name_ar}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Restaurant Branch Address (EN)</div>
                                        <div className="info-detail">{ucFirst(address_en)}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Restaurant Branch Address (ar)</div>
                                        <div className="info-detail">{address_ar}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Email Address</div>
                                        <div className="info-detail">{branch && branch.email_address}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Mobile Number</div>
                                        <div className="info-detail">{branch && branch.mobile_no}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-info">
                                        <div className="info-title">Status</div>
                                        <div className="info-detail"><Tags status={branch && branch.status === 1 ? 'active' : 'inactive'}/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   {/* <div className="col-md-6">
                        <div className="box-outlined">
                            <div className="top-seller-list">
                                <h6>Last Month’s Top Selling Products</h6>
                                <ul className="list-unstyled">
                                    <li>Chicken Burger</li>
                                    <li>Chicken Chillies</li>
                                    <li>Buff Momo</li>
                                    <li>Sawarma</li>
                                    <li>Veg Sandwich</li>
                                </ul>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        </>
    )
}
export default BranchInfo;