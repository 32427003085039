import React, { useEffect, useState } from 'react';
import RestaurantHeader from '../../../layout/Header/RestaurantHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from '../../../../Config';
import { useSetState, useTitle } from 'react-use';
import getPartnerCategoryMap from '../../../../ajax/actions/partner/category/partner_category_map_get';
import getPartnerCategoryUnMap from '../../../../ajax/actions/partner/category/partner_category_unmap_get';
import * as Yup from "yup";
import { useFormik } from 'formik';
import createPartnerCategoryMap, { createPartnerCategoryMapReset } from '../../../../ajax/actions/partner/category/partner_category_map_post';
import createPartnerCategoryUnMap, { createPartnerCategoryUnMapReset } from '../../../../ajax/actions/partner/category/partner_category_unmap_post';
import { useParams } from 'react-router';
import MultiSelectField from '../../../common/form/MultiSelectField';
import './_partnerCategory.scss';
import CheckBoxFieldSingle from '../../../common/form/CheckBoxFieldSingle';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';
import GrowingLoader from '../../../common/loaders/GrowingLoader';
import { names } from '../../../../utils/StringUtils';
import CheckboxField from '../../../common/form/CheckboxField';
import {ParentCategoryState} from "./PartnerCategoryState"
import getRestaurantCategories from '../../../../ajax/actions/partner/category/restaurant_categories_get';
const PartnerCategory = () => {
  let dispatch = useDispatch();
  const reduxState = useSelector(state => state);
  const { userDetails, getFoodCategoriesOfCountryReducer, getPartnerCategoryMapReducer, getPartnerCategoryUnMapReducer,
  createPartnerCategoryMapReducer, createPartnerCategoryUnMapReducer, getRestaurantCategoriesReducer} = reduxState;
  
  useTitle(Title.name + ` Partner Category`);
  const { id } = useParams();
  const [foodCategory, setFoodCategory] = useState([]);
  const [mapped, setMapped] = useState([]);
  const [unmapped, setUnmapped] = useState([]);

  function fetchCatgoryMap() {
      dispatch( getPartnerCategoryMap() )
      dispatch( getPartnerCategoryUnMap() )
  }

  useEffect(() => {
    if (userDetails.success) {
      dispatch( getRestaurantCategories(userDetails.success.restaurant_id) )
      fetchCatgoryMap()
      
    }
  }, [userDetails.success]);

  useEffect(() => {
    if (getRestaurantCategoriesReducer.success) {
      
      const categories = getRestaurantCategoriesReducer.success.data.payload;
      const foodCategories = categories.reduce((acc, ite) => {
            return [ ...acc, { label:ite.name, value:ite.id } ]
        }, [])
        setFoodCategory(foodCategories);
    }
  },[getRestaurantCategoriesReducer.success])
  
  useEffect(() => {
    if (getPartnerCategoryMapReducer.success) {
      const categories = getPartnerCategoryMapReducer.success.data.data;
      setMapped(categories);
    }
  }, [getPartnerCategoryMapReducer])
  
  useEffect(() => {
    if (getPartnerCategoryUnMapReducer.success) {
      const categories = getPartnerCategoryUnMapReducer.success.data.data;
      setUnmapped(categories);
    }
  }, [getPartnerCategoryUnMapReducer])
  
  useEffect(() => {
    const { success } = createPartnerCategoryMapReducer;
    if (success) {
      dispatch(createPartnerCategoryMapReset())
      fetchCatgoryMap()
      // reset();
    }
  }, [createPartnerCategoryMapReducer])

   useEffect(() => {
    const { success } = createPartnerCategoryUnMapReducer;
    if (success) {
      dispatch(createPartnerCategoryUnMapReset())
      fetchCatgoryMap()
      // reset()
    }
  }, [createPartnerCategoryUnMapReducer])

  
  const {handleChange, handleSubmit, errors, touched, values, setFieldValue,setFieldError, setFieldTouched} = useFormik(
        {
            initialValues: ParentCategoryState.data,
            enableReinitialize: true,

            validationSchema: Yup.object().shape({
                category_id: Yup.number().typeError(' must be a number').required(' is required'),
                unmapped_categories: Yup.array().required(` is required`),               
            }),

            onSubmit: (values , {resetForm}) => {
              dispatch(createPartnerCategoryMap(values))
              resetForm({values:''})
                 
            }
    })
  
  const handleCategoryChange = (option) => {
    
    const { label, value } = option;
    setFieldValue( 'category_id', value )
    setFieldValue( 'selected_category', option )
  }

  const handleMappedChange = ( e ) => {
    
    const { value, name } = e.target;     
    let _values = { ...values };    
    if (_values[name].includes(value)) {
        _values[name] = _values[name].filter(item => item !== value);
    } else {
      _values[name].push(value);
    }      
    setFieldValue('unmapped_categories', _values[name])
  }
  
  const deleteConfirm = (id) => {
        swal("Are you sure you want to remove this category? ", {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id,
                }
            }
        }).then((value) => {
          
            if (value) dispatch(createPartnerCategoryUnMap( id))
        })
  };

  return (
    <>
      <RestaurantHeader title="Partner Category Map" icon="foodcat" />
      <div className="plr-35 w-1000">
        <form action="" onSubmit={handleSubmit}>
          <div className='row'>
            <div className="col-sm-12">
              <MultiSelectField
                name="category_id"
                options={foodCategory}
                value={values.selected_category}
                processing={getFoodCategoriesOfCountryReducer.processing}
                onChange={val => handleCategoryChange(val)}
                error={errors && errors.category_id}
                  touched={touched.category_id}
              />
            </div> 
          </div>
          <div className='category-wrapper'>
           
              <div className='form-group unmapped-wrapper'>
                  <label className={(errors && errors.unmapped_categories && touched.unmapped_categories) && `has-error `}>
                <div className={names(`label-text`, (errors && errors.unmapped_categories && touched.unmapped_categories) && `has-error`)}>
                  Unmapped Category { (errors && errors.unmapped_categories && touched.unmapped_categories) ? errors.unmapped_categories + '.' : null}
                </div>
              </label>
                <hr />
                 <ul>
                {unmapped.length ? unmapped.map((c, index) => {
                  return (
                    <li key={index}>
                      <CheckBoxFieldSingle 
                        name='unmapped_categories'
                        id={c.id}
                        label={c.category_name}
                        value={c.external_category_id}
                        checked={ values.unmapped_categories.includes( c.external_category_id ) }
                        onChange={handleMappedChange}
                         
                      />
                      
                    </li>
                    )
                  }) : <li>No any category available.</li> }
                </ul>
              </div>
            
              <div className='form-group mapped-wrapper'>
                <label className='control-label'>Mapped Category</label>
               <hr />
              <ul>
                {mapped.length ? mapped.map((c, index) => {
                  return (
                    <li key={index}>
                      <div className="mapped-wrapper-grid">
                        <label>{c.category_name} ( {c.category.name} )</label>
                         <div className="category-remove" onClick={() => deleteConfirm(c.external_category_id)} data-tip="Remove">
                            { (createPartnerCategoryUnMapReducer.processing && createPartnerCategoryUnMapReducer.id === c.external_category_id) ? <GrowingLoader /> : <i className="fa-trash" />}
                          </div>
                        
                      </div>
                    </li>
                    )
                  }) : <li>No any category available.</li> }
                </ul>
                </div>
              
            
               
          </div>
          <button type="submit"
                    className="btn-theme mt-10">{createPartnerCategoryMapReducer.processing ?
                  <GrowingLoader/> : `Submit`}
                </button>
        </form>
      </div>
      <ReactTooltip backgroundColor="#BF9F60"/>
    </>
  )
}
export default PartnerCategory