import axios from 'axios';
import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
  return { type: 'GET_FOODS_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_FOODS_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_FOODS_PROCESSING', processing };
}

function getFoods()
{
  return ( dispatch, getState ) =>
  {
    let { getRestaurantBranch, foodList, main } = getState();
    let branchID = getRestaurantBranch && getRestaurantBranch.success && getRestaurantBranch.success.data.data.id;
    let filter = foodList.data.params;
    let {user_role} = main.data;
    let config = {
      params: {
        page: filter.page || 1,
        limit: filter.limit || 10,
        q: filter.q || ``,
        sort_by: filter.sort_by || 'desc',
        sort_column: filter.sort_column || 'id',
        food_type: filter.food_type || '',
        category_id: filter.category_id || ''
      },
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'x-app-source': AppSource.appSource
      },
    }
    dispatch(_processing(true));
    dispatch(_success(true));
    dispatch(_error(true));
    let url = ``;
    if (user_role === 'restaurant-admin') {
      url = `vendor/v1/vendor/${ branchID }/item`
    } else if (user_role === `branch-admin`) {
      url = `vendor/v1/vendor/item`
    }
    // axios.get(apiBaseUrl(`restaurant/v1/restaurant/${ branchID }/food`), config)
    axios.get(apiBaseUrl(url), config)
      .then(function(res){
        dispatch(_success(res));

      }).catch(function(error){
        dispatch(_error(error));
        handleError( error )
      });
  }
}


export default getFoods;