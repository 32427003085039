import React from 'react';
import {tagsByColor, tagsByStatus} from "../../../utils/TagsByColorUtil";
import {ucFirst} from "../../../utils/StringUtils";
import {unslugifyWithSeparator} from "../../../utils/GeneralUtils";

const Tags = (props) =>
{
    function statusType()
    {
        let _status = ``;
        Object.keys( tagsByStatus ).map( key => {
            if ( tagsByStatus[key].strings.includes( props.status.toLowerCase() )){
                _status = tagsByStatus[key].status;
            }
        })
        return _status;
    }
    return (
        props.color ?
        <span className={`text-${props.color}`}>{ucFirst(unslugifyWithSeparator(props.status, '-'))}</span> :
        <span className={`text-${statusType()}`}>{ucFirst(unslugifyWithSeparator(props.status, '-'))}</span>
    )
}
export default Tags;