import React from 'react'
import GrowingLoader from "./GrowingLoader";

const TableDataLoader = ({colspan}) => {
    return (
        <tr className="text-center">
            <td colSpan={colspan}>
                <GrowingLoader />
            </td>
        </tr>
    )
};

export default TableDataLoader