import {toggleURLQuery} from "../../../utils/URLUtils";
import getReservations from "../../../ajax/actions/reservations/reservations_get";

export function toggleReservationListFilter(e, history) {

    return function ( dispatch, getState ) {
        let { reservationList } = getState();
        let { params } = reservationList && reservationList.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-RESERVATION-LIST-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            dispatch( getReservations( params ));
        }
    }
}

export function setDefaultReservationParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-RESERVATION-PARAMS', });
    }
}

export function setBranchReservationParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-BRANCH-RESERVATION-PARAMS', param:params });
    }
}

export function setRestaurantReservationParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-RESTAURANT-RESERVATION-PARAMS', param:params });
    }
}