import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../common/table/Table";
import getOrders, {getOrdersReset} from "../../../ajax/actions/Orders/orders_get";
import {setDefaultOrderParams, setRestaurantOrderParams, toggleOrderListFilter} from "./OrderList-Action";
import TableDataLoader from "../../common/loaders/TableDataLoader";
import {useParams, useRouteMatch} from "react-router";
import OrderListItem from "./OrderListItem";
import RestaurantHeader from "../../layout/Header/RestaurantHeader";
import NoDataAvailable from "../../common/NotFound/NoDataAvailable";
import {toggleFilterSidebar} from "../../layout/Main/Main-Actions";
import PaginationButtons from "../../common/buttons/PaginationButtons";
import {useTitle} from 'react-use';
const Orders = () => {

    const reduxState = useSelector(state => state);
    const {ordersGet, orderList, userDetails, main} = reduxState;
    let {fields, params} = orderList && orderList.data;
    let {url} = useRouteMatch();
    let {restaurant_id, branch_id} = params;
    let dispatch = useDispatch();
    let {filter_sidebar_open} = main.data;

    useTitle('Vendor Dashboard - Orders');

    useEffect(() => {
        dispatch(setDefaultOrderParams())
        dispatch(getOrdersReset());

        const interval = setInterval(() => {
           dispatch(getOrders(params))
        }, 60000);

        return () => {
            dispatch(getOrdersReset())
            filter_sidebar_open && dispatch(toggleFilterSidebar())
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        let userDetail_success = userDetails && userDetails.success;
        if (userDetail_success) {
            dispatch(setRestaurantOrderParams(userDetail_success))
        }
    }, [userDetails])

    useEffect(() => {
        if (!branch_id) {
            restaurant_id && dispatch(getOrders(params))
        }

    }, [restaurant_id, branch_id])


    // console.log(` <| orderList |> `, orderList )
    let list = ordersGet?.success?.data?.data;
    const {success} = ordersGet;
    let current_page = success?.data?.meta?.pagination?.current_page;
    let last_page = success?.data?.meta?.pagination?.total_pages;
    let total = success?.data?.meta?.pagination?.total;
    let pages = {current_page, last_page, total};
    return (
        <>
            <RestaurantHeader title="Orders" icon="order" header="order"/>
            <div className="table-block table-responsive pl-50">
                <Table reducer={ordersGet}
                       params={params}
                       action={toggleOrderListFilter}
                       columns={fields}>
                    <tbody>
                    {ordersGet.processing ? <TableDataLoader colspan={10}/> :
                        list && list.length ? list.map(order => {
                                return (
                                    <OrderListItem key={order.id}
                                                   order={order}
                                                   reducer={ordersGet}/>
                                )
                            }
                        ) : <NoDataAvailable colspan={10}/>}
                    </tbody>
                </Table>
            </div>
            {list && list.length ? (
                <div className="fixed-footer">
                    <PaginationButtons pages={pages}
                                       fetchAction={toggleOrderListFilter}
                                       processing={ordersGet && ordersGet.processing}/>
                </div>
            ) : null}
        </>
    )
}

export default Orders;