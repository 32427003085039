export const staticIconsUtils = [
    { name: `orders`, icon: 'order' },
    { name: `info`, icon: 'info' },
    { name: `addons-categories`, icon: 'addon_cat' },
    { name: `addons`, icon: 'addons' },
    { name: `foods`, icon: 'foods' },
    { name: `food-categories`, icon: 'foodcat' },
];


export const activeStatus = [
    {name:'Active', value:1},
    {name:'InActive', value:0},
]

export const foodTypeOptions = [
    {name:'General', value:"general"},
    {name:'Normal', value:"normal"},
    {name:'Breakfast', value:"breakfast"},
]

export const timeslotStatus = [
    {name:'Closed', value:0},
    {name:'Open', value:1},
]

export const yesNoStatus = [
    {name:'Yes', value:1},
    {name:'No', value:0},
]

export const limitOptions = [
    { total: 10, label: `10 Results` },
    { total: 25, label: `25 Results` },
    { total: 50, label: `50 Results` },
    { total: 100, label: `100 Results` },
    { total: 500, label: `500 Results` },
]

export const yesOrNoOptions = [{ id:1, name: `Yes`}, { id:0, name: `No`}]

export const reservationStatusOptions = [
    {value:'pending', label:'Pending'},
    {value:'cancelled', label:'Cancelled'},
    {value:'rejected', label:'Rejected'},
    {value:'accepted', label:'Accepted'},
]

export const orderStatusOptions = [
    {value:'pending', label:'Pending'},
    // {value:'processing', label:'Processing'},
    {value:'rejected', label:'Rejected'},
    {value:'accepted', label:'Accepted'},
]

export const fiveMinuteTimeFrame = () => {
    let mints = []
    for( let i = 15; i <= 40; i += 5 ) {
        mints.push( { value:i, label:i+' min'} )
    }
    return mints;
}

export const validImagType = [
    'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image'
]