import {urlParamValue} from "../../../utils/URLUtils";

const DefaultBranchListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) || ``,
    },
    fields: [
        {field: `id`, label: `Branch ID#`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `name`, label: `Name`, sort: false, sort_icon:false},
        {field: `email_address`, label: `Email Address`, sort: false},
        {field: `phone_no`, label: `Phone No`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`, sort: false},
        {field: `district`, label: `District`, sort: false},
        // {field: `city`, label: `City`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
    // fields:[`Branch ID`, `Name`, `Email Address`, `Phone No`, `Status`, `District`, `City`, `Action`]
}

export const BranchListState = {data: DefaultBranchListState}