import React, {useEffect} from "react";
import DateRangeFieldNew from "../../../common/form/DateRangeFieldNew";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {
    setBranchIDForFaultReport,
    toggleFaultReportDateChange,
    toggleFaultReportFilterSidebar,
} from "./FaultReport-Action";
import {useHistory} from "react-router";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import {apiBaseUrl} from "../../../../utils/URLUtils";
import useAppState from "../../../../hooks/useAppState";
import SelectField from "../../../common/form/SelectField";
import getFaultReportStats from "../../../../ajax/actions/report/fault/fault_report_get";

const FaultReportHeader = ({ filterMode }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const faultReportReducer = useAppState( s => s.faultReportReducer )
    const restaurantBranchList = useAppState(s => s.restaurantBranchList);
    const userDetails = useAppState( s => s.userDetails )
    const {params} = faultReportReducer && faultReportReducer.data;
    // const country_id = userDetails?.success?.country_id;
    // const restaurant_id = userDetails?.success?.restaurant_id;
    const role = userDetails?.success?.roles?.[0].name;
    const _branch_id = userDetails && userDetails?.success && userDetails?.success?.branch_id;

    const toggleFilter = () => {
        dispatch( toggleFaultReportFilterSidebar( filterMode ) )
    }
    const handleEvent = (event, picker) => {
        let start_date = moment(picker.startDate).format('YYYY-MM-DD');
        let end_date = moment(picker.endDate).format('YYYY-MM-DD');
        dispatch(toggleFaultReportDateChange( start_date, end_date, history ))
    }

    const dateApply = (event, picker ) => {
        if( role === "branch-admin" ){
            params.branch_id = _branch_id
        }
        dispatch( getFaultReportStats( params ) )
    }

    let downloadReportUrl = apiBaseUrl(`order/v1/fault/report/csv?start_date=${params.start_date}&end_date=${params.end_date}&access_token=${localStorage.getItem('Bearer')}`);
    if( params.branch_id ) {
        downloadReportUrl = apiBaseUrl(`order/v1/fault/report/csv?branch_id=${params.branch_id}&start_date=${params.start_date}&end_date=${params.end_date}&access_token=${localStorage.getItem('Bearer')}`);
    }
    if( role === 'branch-admin' ) {
        downloadReportUrl = apiBaseUrl(`order/v1/fault/report/csv?branch_id=${_branch_id}&start_date=${params.start_date}&end_date=${params.end_date}&access_token=${localStorage.getItem('Bearer')}`);
    }
    const branchList = restaurantBranchList && restaurantBranchList?.success && restaurantBranchList?.success?.data?.data;

    return (<>
        <div className="calendar-block">
            <DateRangeFieldNew handleEvent={handleEvent} enddate={params?.end_date} startdate={params?.start_date} onApply = {dateApply}/>
            <span className="icon-calendar"/>
        </div>
        { !_branch_id && (
            <div className="col-md-3">
                <SelectField name="branch_id"
                             options={branchList}
                             noLabel
                             onChange={e => dispatch(setBranchIDForFaultReport(e.target.value))}
                             _value="id"
                             _label="branch_name"
                             value={params.branch_id}
                />
            </div>
        ) }
        <div className="button-wrapper mr-5">
            <a href={downloadReportUrl} target="_blank" className="btn-theme" id="btn-filter">Download</a>
        </div>
        {/*<div className="button-wrapper">
            <a href="javascript:void(0)" onClick={toggleFilter} className="btn-theme" id="btn-filter">Filters</a>
        </div>*/}
    </>)
}

export default FaultReportHeader;