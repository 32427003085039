import React from 'react'

const NoDataAvailable = ({colspan}) => {
    return (
        <tr className="text-center">
            <td colSpan={colspan}>
                <small>No data available</small>
            </td>
        </tr>
    )
};
export default NoDataAvailable;