import React, {memo} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import Tags from "../../common/Tags/Tags";
import {ucFirst, ucFirstAll} from "../../../utils/StringUtils";
import {convert24To12Hrs, fullDate, justDate} from "../../../utils/DateTimeUtils";
import ReactTooltip from "react-tooltip";

const ReservationListItem = ({reservation,  reducer}) => {
    let {url} = useRouteMatch();
    const {id, reservation_date, booking_date, booking_time, branch_name, customer_full_name, restaurant_id, restaurant_branch_id,
        currency,restaurant_name, total, no_of_guests, payment_method,payment_status_update_date, driver_order_count,
        reserved_by, payment_status, device_type, created_at, customer_priority, reservation_status, city_name } = reservation;
    let vip = customer_priority === 10;
    let middle = customer_priority === 20;
    let general = customer_priority === 30;

    let nameClass = vip ? `vip-customer` : middle ? `middle-customer`: general ? `general-customer` : null;
    let description = vip ? `VIP Customer` : middle ? `middle-customer` : general ? `General Customer` : null;
    return (
        <tr>
            <td>{id}</td>
            <td>{fullDate(created_at)}</td>
            <td>
                {/*<Link to={`/restaurant/${main_restaurant_id}/branch/${branch_id}/dashboard`}>
                    {ucFirst(branch_name)}
                </Link>*/}
                <Link to={`/branches/${restaurant_branch_id}/info`}>
                    {ucFirst(restaurant_name)}
                </Link>
            </td>
            <td>
                <span
                data-tip={ vip ? description : null }
                data-for="tooltip-customer-name">
                        {ucFirstAll(customer_full_name)}
                </span>
            </td>

            <td>{justDate(booking_date)+' '+ convert24To12Hrs(booking_time)}</td>
            <td>{currency} {total}</td>
            <td>{no_of_guests}</td>
            <td>{city_name}</td>
            <td>{ucFirst(payment_method || '-')}</td>
            <td>{payment_status ? <Tags status={payment_status}/> : '-' }</td>
            <td>{ reservation_status ? <Tags status={reservation_status}/> : '-' }</td>
            <td>{ucFirst( reserved_by )}</td>

            <td className="buttons-block">
                {/*<div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">

                    </div>
                </div>*/}
                <Link to={`${url}/${id}/view`}>
                    <i className="fa-eye" data-tip="View"/>
                </Link>
            </td>
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>
    )
};

export default memo(ReservationListItem);