import {BranchInfoState} from './BranchInfo-State'

export default function branchInfoReducer(state = BranchInfoState, action) {

    let {data} = {...state};
    let { restaurant_data, flag, pages, processing, fetchAction  } = action;

    switch (action.type) {

        case 'SET-BRANCH-DETAILS':
            data.details = restaurant_data;
            return {...state, data};

        case 'SET-IS-LIST':
            data.is_list = flag;
            return {...state, data};

        case 'SET-PAGINATION-DATA':
            data.pagination_data = {
                pages,
                processing,
                fetchAction
            };
            return {...state, data};

        default:
            return state
    }
}
