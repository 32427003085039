import React, { memo, useState } from "react";
import Div from "../Div/Div";

const SwitchField = ({ label, checked }) =>
{
  const [ on, setOn ] = useState( checked )
  return (

    <Div className="custom-control custom-switch" onClick={() => setOn( !on )}>
      <input type="checkbox"
             className="custom-control-input"
             checked={ on }
             />
      <label className="custom-control-label" >{ label }</label>
    </Div>

  )
};

export default memo( SwitchField );