import {TimeslotDayState} from './TimeslotDay-State'
import {urlParamValue} from "../../../../../utils/URLUtils";
import {fromRecord, inRecord} from "../../../../../utils/DataUtils";

export default function timeslotDayReducer(state = TimeslotDayState, action) {

    let {data} = {...state};
    let { name, value, param, bool, mode, values, success, id } = action;
    switch (action.type) {

        case 'TOGGLE-TIMESLOT-DAY-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )

            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'SET-DEFAULT-TIMESLOT-DAY-PARAMS':
            data.params = {
                limit: urlParamValue(`limit`) || 15,
                page: urlParamValue(`page`) || 1,
                sort_by: urlParamValue(`sort_by`) || 'desc',
                sort_column: urlParamValue(`sort_column`) || `id`,
            };
            return {...state, data};

        case 'SET-BRANCH-TIMESLOT-DAY-PARAMS':
            data.params.restaurant_id = param.parent_id;
            data.params.branch_id = param.id;
            data.params.country_id = param.country_id;
            data.params.currency = param.currency;
            return {...state, data};

        case 'SET-RESTAURANT-TIMESLOT-DAY-PARAMS':
            data.params.restaurant_id = param.restaurant_id;
            data.params.branch_id = ``;
            data.params.country_id = param.country_id;
            return {...state, data};

        case 'SET-TIMESLOT-MODAL-OPEN':
            data.timeslot_modal_open = true;
            data.mode = mode;
            return {...state, data};

        case 'SET-TIMESLOT-MODAL-CLOSE':
            data.timeslot_modal_open = false;
            return {...state, data};

        case 'SET-TIMESLOT-UPDATE-ITEM':
            if( inRecord( data.list, 'id', '==', id )) {
                data.updateItem = fromRecord( data.list, 'id', '==', id  );
            }
            return {...state, data};

        case 'GET_RESERVATION_TIMESLOT_DAY_SUCCESS':
            data.list = success && success.data.data;
            return {...state, data};

        case 'GET_RESERVATION_TIMESLOT_HOURS_SUCCESS':
            data.hour_min_options = success && success.data.data;
            return {...state, data};

        default:
            return state
    }
}
