import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setBranchHeaderDetails } from "../branches/Header/BranchSubHeader-Action";
import { useRouteMatch } from "react-router";
import { setIsList } from "../branches/specific/BranchInfo-Action";
import RestaurantHeader from "../../layout/Header/RestaurantHeader";
import foodicsLogo from "../../../assets/images/foodics-logo.svg";
import deliverectLogo from "../../../assets/images/deliverect-logo.svg";
import { Title } from "../../../Config";
import { useTitle } from "react-use";
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { apiBaseUrl } from "../../../utils/URLUtils";
import axios from "axios";
import { loggedInUser, loginToken } from '../../../utils/LocalStorageUtils'
import VerifyCode from "../../../ajax/actions/integration/integration_post";

const Integration = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { userDetails, getFaultReportReducer } = reduxState;
    let { url } = useRouteMatch();
    const [isPartnerConnected, setIsPartnerConnected] = useState(false);
    const [isFoodicsConnected, setIsFoodicsConnected] = useState(false);
    const [isDeliverectConnected, setIsDeliverectConnected] = useState(false);

    const handleConnect = async (type) => {

        switch (type) {
            case 'foodics':
                const url = 'auth/v2/vendor/partner';
                // Fetch partners data from the API
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken()
                    },
                };

                axios.get(apiBaseUrl(url), config)
                    .then(res => {
                        var partnerList = res.data.payload.data;
                        var foodicsClient = partnerList.find(x => x.type === 'foodics')
                        // const client_id = res.data.client_id;
                        const client_id = foodicsClient.partner_app_detail.client_id;
                        // const authUrl = `https://console-sandbox.foodics.com/authorize?client_id=${client_id}&state=authorization_code`;
                        const authUrl = `https://console-sandbox.foodics.com/authorize?client_id=${client_id}&state=authorization_code`
                        // Open popup window
                        const popup = window.open(authUrl, 'FoodicsAuthPopup', 'width=600,height=600');

                        // Handle messages from popup window
                        window.addEventListener('message', (event) => {
                            if (event.origin === window.location.origin) {
                                // Handle the data received from the popup window
                                const { type, data } = event.data;
                                console.log(event.data)
                                if (type === 'oAuthSuccess') {
                                    // Handle the OAuth response (access token or authorization code)
                                    handleSuccess('Successfully connected');
                                    // You can now send this data to your server or perform other actions
                                }
                                else if (type === 'oAuthError') {
                                    handleError(data);
                                }
                            }
                        });
                    }).catch(error => {
                        console.error(error)
                    });
                break;

            case 'deliverect':
                try {
                    const result = await dispatch(VerifyCode({ source: 'external', type: 'deliverect' }));
                }
                catch(error) {
                }
                break;
            default:
                break;
        }


    };
    useTitle(Title.name + ` Integration`);

    useEffect(() => {
        let details = {
            title: 'Integration',
            icon: 'report',
            action: false,
            linkUrl: `${url}`
        }
        dispatch(setBranchHeaderDetails(details))
        dispatch(setIsList(false));
    }, []);

    useEffect(() => {

        const url = 'auth/v2/vendor/partner/integrations';
        // Fetch partners data from the API
        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            },
        };

        axios.get(apiBaseUrl(url), config)
            .then(res => {
                const partnersExist = res.data && res.data.payload && res.data.payload.length > 0;

                if (partnersExist) {
                    setIsPartnerConnected(true);
                    const foodicsPartner = res.data.payload.find(partner => partner.partner.name.toLowerCase() === 'foodics');
                    if (foodicsPartner) {
                        setIsFoodicsConnected(true);
                        console.log(isFoodicsConnected, 'isFoodicsConnected')
                    } else {
                        setIsFoodicsConnected(false);
                    }

                    const deliverectPartner = res.data.payload.find(partner => partner.partner.name.toLowerCase() === 'deliverect');
                    if (deliverectPartner) {
                        setIsDeliverectConnected(true);
                        console.log(isDeliverectConnected, 'isDeliverectConnected')
                    } else {
                        setIsDeliverectConnected(false);
                    }
                } else {
                    setIsPartnerConnected(false);
                }
            }).catch(error => {


            });
    }, []);


    useEffect(() => {

    }, [userDetails.success])

    let data = getFaultReportReducer?.success?.data?.data;

    // let monthly_report = apiBaseUrl(`order/v1/generate/monthly/v2?restaurant_id=${params.branch_id}&month=${salesReport.data.month}&year=${salesReport.data.year}&access_token=${localStorage.getItem('Bearer')}`);
    return (
        <>
            <RestaurantHeader title="Integration" icon="report" header="integration" />
            <div className="plr-35">
                <div className="row mb-5">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card" style={{ width: "18rem", margin: "0 auto", height: "18rem" }}>
                            <div className="card-body partner-card">
                                <img className="card-img-top" src={foodicsLogo} alt="foodics-logo" />
                                <h5 className="card-title">Foodics</h5>
                                <p className="card-text">Point of sale and restaurant management system · Stay in
                                    control of your operations at any time and from anywhere.</p>
                                <button
                                    className={`btn btn-primary ${isPartnerConnected ? 'disabled' : ''}`}
                                    onClick={()=>handleConnect('foodics')}
                                    disabled={isPartnerConnected}
                                >
                                    {isFoodicsConnected ? 'Connected' : 'Connect'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card" style={{ width: "18rem", margin: "0 auto", height: "18rem" }}>
                            <div className="card-body partner-card">
                                <img className="card-img-top" src={deliverectLogo} alt="deliverect-logo" />
                                <h5 className="card-title">Deliverect</h5>
                                <p className="card-text">Restaurants, ghost kitchens, and franchises worldwide rely on Deliverect to enhance their online delivery. Boost margins and staff productivity</p>
                                <button
                                    className={`btn btn-primary ${isPartnerConnected ? 'disabled' : ''}`}
                                    onClick={()=>handleConnect('deliverect')}
                                    disabled={isPartnerConnected}
                                >
                                    {isDeliverectConnected ? 'Connected' : 'Connect'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(Integration);