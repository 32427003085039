import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {toggleAddonCategoryFilterOpen, toggleAddonCategoryListFilter} from "./AddonCategoryList-Action";
import CommonHeader from "../../common-filter-header/CommonHeader";

const AddonCategoryHeader = ({filterMode}) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const reduxState = useSelector(state => state);
    const { branchSubHeaderReducer, addonCategoryList } = reduxState;
    let {params } = addonCategoryList && addonCategoryList.data;
    const toggleFilter = () => {
        dispatch(toggleAddonCategoryFilterOpen())
    }
    const toggleListFilter = (e) => {
        dispatch(toggleAddonCategoryListFilter(e, history))
    }
    return (
        <CommonHeader toggleFilter={toggleFilter}
                      param={params}
                      toggleListFilter={(e) => toggleListFilter(e)}

        />
    )
}

export default AddonCategoryHeader;