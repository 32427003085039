import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import store from '../../../store';
import { AppSource } from '../../../Config';

function _success(success){
    return { type: 'FETCH_ORDERS_SUCCESS', success }
}
function _error(error){
    return { type:'FETCH_ORDERS_ERROR', error }
}
function _processing(processing){
    return { type: 'FETCH_ORDERS_PROCESSING', processing }
}
/* limit, page, customer_name, sort_by, sort_column, country_id, city_id,
 start_date, end_date, restaurant_id, branch_id, device_type, user_id,
 payment_method, order_status, delivery_status, payment_status, admin_id,
 delivery_type, phone */

function getOrders(filters){
    return (dispatch, getState ) => {
        store.dispatch(_processing(true));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params: {
                limit: filters.limit || 3,
                page:  filters.page  || 1,
                // user_id: filters.user_id || null,
                sort_by:  filters.sort_by || 'desc',
                /*country_id: filters.country_id || ``,
                city_id: filters.city_id || ``,

                admin_id: filters.admin_id || null,
                */
                start_date: filters.start_date || null,
                end_date: filters.end_date || null,
                branch_id: filters.branch_id || null,
                // device_type: filters.device_type || null,
                sort_column: filters.sort_column || `id`,
               // order_status: filters.order_status || null,
                // restaurant_id: filters.restaurant_id || null,
                // delivery_status: filters.delivery_status || null,
                // payment_status: filters.payment_status || null,
                // customer_name:  filters.customer_name  || null,
                // payment_method: filters.payment_method || null,
                // delivery_type: filters.delivery_type || null,
                // phone: filters.phone || null,
                q:filters.q || '',
            }
        };
        let { main } = getState();
        let { user_role } = main.data;
        let url = ``;
        if( user_role === 'restaurant-admin' ) {
            url = `order/v1/vendor/orders`
        } else if( user_role === `branch-admin` ) {
            url = `order/v1/vendor/branch/orders`
        }
         // axios.get( apiBaseUrl(`order/v1/pagination/order`), config)
         axios.get( apiBaseUrl(url), config)
            .then(res => {
                store.dispatch(_processing(false));
                store.dispatch(_success(res));

            }).catch(error => {
                store.dispatch(_error(error));
                store.dispatch(_processing(false));
            });
    }
}
export function getOrdersReset()
{
    return dispatch => dispatch({ type: `GET_ORDERS_RESET` })
}

export default getOrders;