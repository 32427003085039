import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const ReservationItems = ({reservation, processing}) => {
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Reservation SUMMARY</h6>

            {processing ? <GrowingLoader/> :
                <div className="order-detail-table table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>Rate</th>
                            <th>No Of Guests</th>
                            <th>Subtotal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{reservation && reservation.booking_time}</td>
                            <td>{reservation && reservation.currency} {reservation && reservation.reservation_time_rate}</td>
                            <td>{reservation && reservation.no_of_guests}</td>
                            <td>{reservation && reservation.currency} {reservation && reservation.subtotal}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan="4">Subtotal: {reservation && reservation.subtotal}</td>
                        </tr>
                        <tr>
                            <td colSpan="4">Tax({reservation && reservation.tax_type}%): {reservation && reservation.currency} {reservation && reservation.tax}</td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <span>Grand Total: {reservation && reservation.currency} {reservation && reservation.total}</span>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            }
        </div>
    )
}

export default ReservationItems;