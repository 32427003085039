import axios from 'axios'
import {apiBaseUrl} from '../../../utils/URLUtils'
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from "../../../store";
import getFoods from "./foods_get";
import { AppSource } from '../../../Config';

function _success(success) {
    return {type: 'FOOD_DELETE_SUCCESS', success}
}

function _error(error) {
    return {type: 'FOOD_DELETE_ERROR', error}
}

function _processing(processing, id) {
    return {type: 'FOOD_DELETE_PROCESSING', processing, id}
}

function deleteFood(branchID, foodId) {
    return (dispatch, getState ) => {

        let {main} = getState();
        let {user_role} = main.data;

        dispatch(_processing(true, foodId))

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${branchID}/item/${foodId}`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/item/${foodId}`
        }

        axios({
            // url: apiBaseUrl(`restaurant/v1/restaurant/${branchID}/food/${foodId}`),
            url: apiBaseUrl(url),
            method: 'delete',
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                handleSuccess(res)
                store.dispatch( getFoods( ));
            }).catch(function (error) {
            dispatch(_error(error))
            handleError(error)
        })
    }
}


export default deleteFood