import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_RESERVATION_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_RESERVATION_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_RESERVATION_PROCESSING', processing }
}


function getReservation( id ) {

    return dispatch => {

        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
        };

       axios.get( apiBaseUrl(`reservation/v1/reservation/admin/${id}`), config)
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                dispatch(_processing(false));
            });
    }
}
export function getReservationReset()
{
    return dispatch => dispatch({ type: `GET_RESERVATION_RESET` })
}
export default getReservation;
