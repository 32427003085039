import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_RESERVATION_TIMESLOT_DAY_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_RESERVATION_TIMESLOT_DAY_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_RESERVATION_TIMESLOT_DAY_PROCESSING', processing }
}


function getReservationTimeslotDay(restaurant_id, day_id ) {

    return (dispatch) => {

        dispatch(_processing(true));

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/branch/${restaurant_id}/day/${day_id}/timeslot`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){

            dispatch(_error(error));
            dispatch(_processing(false));

        });
    }
}
export default getReservationTimeslotDay;
