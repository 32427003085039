import {FaultReportState} from './FaultReport-State'
import {beginningOfThisMonth, getCurrentDate} from "../../../../utils/DateTimeUtils";
import {urlParamValue} from "../../../../utils/URLUtils";

export default function faultReportReducer(state = FaultReportState, action) {

    let {data} = {...state};
    let { name, value, start_date, end_date, mode, branch_id, parentID, countryID } = action;
    switch (action.type) {

        case 'TOGGLE-FAULT-REPORT-FILTER':
            data.params[name] = value;
            return {...state, data};

        case 'ON-FAULT-REPORT-DATE-CHANGE':
            data[name] = value;
            // let _current_date = value.split(' ');
            // data.month = _current_date[0]
            // data.year = _current_date[1]
            return {...state, data};

        case 'TOGGLE-FAULT-REPORT-FILTER-SIDER':
            data.report_filter_mode = mode;
            data.report_filter_open = !data.report_filter_open;
            return {...state, data};

        case 'OPEN-CLOSE-FAULT-REPORT-FILTER-SIDER':
            data.report_filter_open = !data.report_filter_open;
            return {...state, data};

        case 'SET-BRANCH-ID-FOR-FAULT-REPORT':
            data.params.branch_id = branch_id;
            return {...state, data};

        case 'TOGGLE-FAULT-REPORT-DATE-CHANGE':
            data.params.start_date = start_date;
            data.params.end_date = end_date;
            return {...state, data};

        case 'SET-PARENT-RESTAURANT-ID-FOR-FAULT-REPORT':
            data.params.restaurant_id = parentID;
            return {...state, data};

        case 'SET-COUNTRY-ID-FOR-FAULT-REPORT':
            data.params.country_id = countryID;
            return {...state, data};

        case 'SET-DEFAULT-FAULT-REPORT-PARAMS':
            data = {
                params : {
                    start_date:  beginningOfThisMonth(),
                    end_date:getCurrentDate(),
                    branch_id: '',
                    // country_id: ``,
                    // city_id:``,
                },
                report_filter_open:true,
                report_filter_mode:`parent`
            };
            return {...state, data};

        default:
            return state
    }
}
