import React from "react";

const ReservationSpecialInstruction = ({ reservation, processing }) => {
    if(reservation && reservation.special_instructions)  {
    return (
        <div className="box-colored bg-light-gray">
            <h6>Special Instruction</h6>
             <p>{reservation && reservation.special_instructions }</p>
        </div>
    ) } else{ return null}
}

export default ReservationSpecialInstruction;