import {toggleURLQuery} from "../../../../../utils/URLUtils";

export function toggleTimeslotDayFilter(e, history) {

    return function ( dispatch, getState ) {
        let { reservationList } = getState();
        let { params } = reservationList && reservationList.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-RESERVATION-DAY-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            // dispatch( getReservationTimeslots( params ));
        }
    }
}

export function setDefaultTimeslotDayParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-TIMESLOT-DAY-PARAMS', });
    }
}

export function setBranchTimeslotDayParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-BRANCH-TIMESLOT-DAY-PARAMS', param:params });
    }
}

export function setRestaurantTimeslotDayParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-RESTAURANT-TIMESLOT-DAY-PARAMS', param:params });
    }
}
export function setTimeslotModalOpen( mode ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-TIMESLOT-MODAL-OPEN',  mode });
    }
}
export function setTimeslotModalClose( bool ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-TIMESLOT-MODAL-CLOSE', bool });
    }
}
export function setTimeslotUpdateItem( id ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-TIMESLOT-UPDATE-ITEM', id });
    }
}