import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { AppSource } from '../../../Config';

function _success(success) {
    return { type: 'GET_RESERVATION_SETTING_SUCCESS', success }
}

function _error(error) {
    return { type: 'GET_RESERVATION_SETTING_ERROR', error }
}

function _processing(processing) {
    return { type: 'GET_RESERVATION_SETTING_PROCESSING', processing }
}


function getReservationSetting( ) {

    return (dispatch, getState) => {
        let { getRestaurantBranch } = getState();
        let id = getRestaurantBranch.success && getRestaurantBranch.success?.data?.data?.id;
        dispatch(_success(false));
        dispatch(_error(false));
        dispatch(_processing(true));

        const getToken = localStorage.getItem('Bearer');

        axios({
            url: apiBaseUrl(`reservation/v1/branch/${id}`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){

                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){

                dispatch(_error(error));
                dispatch(_processing(false));

            });

    }
}

export default getReservationSetting;
