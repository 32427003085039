import getFoods from "../../../../ajax/actions/foods/foods_get";
import {toggleURLQuery} from "../../../../utils/URLUtils";

export function toggleFoodListFilter( e, history ) {
    return function ( dispatch, getState ) {
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-FOOD-LIST-FILTER', name, value});
        if( name === 'sort_column' || name === 'page' ) {
            dispatch( getFoods());
        }
    }
}

export function toggleFoodFilterOpen( ) {
    return function ( dispatch ) {
        dispatch({ type:'FOOD-FILTER-OPEN-CLOSE', });
    }
}

export function setDefaultFoodParams(  ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-FOOD-PARAMS', });
    }
}

export function setFoodSearchReset( history ) {
    return function ( dispatch,) {
        toggleURLQuery( 'q', `` , history)
        dispatch({type: 'SET-FOOD-SEARCH-RESET', });
    }
}