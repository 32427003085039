import { AddonCategoryFormState } from "./AddonCategoryForm-State";
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../utils/ErrorUtils";

export default function AddonCategoryFormReducer (state = AddonCategoryFormState, action )
{
  let { data } = { ...state };
  let { name, value, validationErrors, branchData, id, addonCategory } = action;
  switch( action.type )
  {
    case `ADDON-CATEGORY-FORM-CHANGE`:
      data[name] = value;
      return { ...state,  data };

    case `ADDON-CATEGORY-TRANSLATION-FORM-CHANGE`:
      data.translation[id][name] = value;
      return { ...state,  data };

    case 'CANCEL-ADDON-CATEGORY-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      return {...state, data};

    case 'SET-BRANCH-DATA-ADDON-CATEGORY':
      let _new_translation = {};
      branchData && branchData.translation && branchData.translation.map( lang => {
        _new_translation[lang.lang_code] = {
          name:""
        }
      });
      data.translation = _new_translation;
      data.parentID = branchData && branchData.parent_id;
      data.restaurantID = branchData && branchData.id;
      data.countryID = branchData && branchData.country_id;
      data.trans = branchData && branchData.translation;
      return {...state, data};

    case 'SET-ADDON-CATEGORY':
      let updtranslation = {};
      addonCategory.add_on_category_translation.map(( item)=> {
        updtranslation[ item.lang ] = {
          name: item.name,
        };

      });
      data.translation = updtranslation;
      data.status = addonCategory.status;
      data.min_addon = addonCategory.min_addon;
      data.max_addon = addonCategory.max_addon;
      return {...state, data};

    case 'SET-DEFAULT-ADDON-CATEGORY-FORM':
      data = {
        translation:{},
        status:``,
        min_addon:``,
        max_addon:``,
        trans:null,
        countryId:``,
        restaurantID:``,
        parentID:``,
        sort:1
      }
      return {...state, data};

    case 'ADDON-CATEGORY-FORM-SERVER-VALIDATION-ERRORS':
      Object.keys(validationErrors).map(key =>
          data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
      )
      // data.errorsPresent = true;
      // data.dispatchAPI = false;
      return {...state, data};

    case `ADDON-CATEGORY-FORM-SUBMIT`:
      data.submitted = true;
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state,  data };

    default: return state;
  }




}