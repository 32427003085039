import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useAppState from "../../../../hooks/useAppState";
import { useFormik } from "formik";
import * as Yup from "yup";
import createInternalReservation, {
  createInternalReservationReset,
} from "../../../../ajax/actions/reservations/internal-reservation/internal_reservation_post";
import { setIsList } from "../../branches/specific/BranchInfo-Action";
import { setBranchHeaderDetails } from "../../branches/Header/BranchSubHeader-Action";
import TextField from "../../../common/form/TextField";
import { activeStatus } from "../../../../utils/StaticDataUtils";
import SelectField from "../../../common/form/SelectField";
import TextAreaField from "../../../common/form/TextAreaField";
import getInternalReservationDate from "../../../../ajax/actions/reservations/internal-reservation/internal_reservation_date_get";
import getInternalReservationTime from "../../../../ajax/actions/reservations/internal-reservation/internal_reservation_time_get";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import NumberField from "../../../common/form/NumberField";
import { useTitle } from "react-use";
import { Title } from "../../../../Config";

const InternalReservation = () => {
  const dispatch = useDispatch();
  const getRestaurantBranch = useAppState((s) => s.getRestaurantBranch);
  const internalReservationCreate = useAppState(
    (s) => s.internalReservationCreate
  );
  const internalReservationDateGet = useAppState(
    (s) => s.internalReservationDateGet
  );
  const internalReservationTimeGet = useAppState(
    (s) => s.internalReservationTimeGet
  );
  const [branchID, setBranchID] = useState(``);
  const [maxGuests, setMaxGuests] = useState(``);
  const [intDates, setIntDates] = useState([]);
  const [intTimes, setIntTimes] = useState([]);

  useEffect(() => {
    let details = {
      title: `Crete Internal Reservation`,
      icon: "order",
      action: false,
    };
    dispatch(setIsList(false));
    dispatch(setBranchHeaderDetails(details));
  }, []);

  useTitle(Title.name + ` Internal Reservation`);

  useEffect(() => {
    if (getRestaurantBranch.success) {
      let branchID =
        getRestaurantBranch.success && getRestaurantBranch.success.data.data.id;
      setBranchID(branchID);
      getRestaurantBranch.success &&
        dispatch(getInternalReservationDate(branchID));
    }
  }, [getRestaurantBranch.success]);

  useEffect(() => {
    if (internalReservationDateGet.success) {
      let data =
        internalReservationDateGet.success &&
        internalReservationDateGet.success.data.data;
      setMaxGuests(data.max_guests);
      setIntDates(data.days);
    }
  }, [internalReservationDateGet.success]);

  useEffect(() => {
    if (internalReservationTimeGet.success) {
      let data =
        internalReservationTimeGet.success &&
        internalReservationTimeGet.success.data.data;
      setIntTimes(data);
    }

    if (internalReservationTimeGet.error) {
      setIntTimes([]);
    }
  }, [internalReservationTimeGet.success, internalReservationTimeGet.error]);
  const phoneRegExp =
    /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      branch_id: branchID,
      booking_date: ``,
      booking_time: ``,
      no_of_guests: ``,
      special_instructions: ``,
      customer_full_name: ``,
      customer_phone_number: ``,
    },
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      booking_date: Yup.string().required("is required"),
      booking_time: Yup.string().required("is required"),
      no_of_guests: Yup.number()
        .typeError(" must be a number")
        .required("is required")
        .max(maxGuests, " must be less than max guests"),
      customer_full_name: Yup.string().required("is required"),
      // customer_phone_number: Yup.string()
      //   .length(10, "Enter correct code")
      //   .matches(phoneRegExp, "Enter number only")
      //   .required("Required"),
      customer_phone_number: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Must be only digits")
        .min(10, "Must be exactly 5 digits")
        .max(10, "Must be exactly 5 digits"),
    }),

    onSubmit: (values) => {
      values.branch_id = branchID;
      dispatch(createInternalReservation(values));
    },
  });

  useEffect(() => {
    if (internalReservationCreate.success) {
      resetForm();
      dispatch(createInternalReservationReset());
    }
  }, [internalReservationCreate.success]);

  let _dates = [];
  let _times = [];
  intDates &&
    intDates.length &&
    intDates.map((date) => {
      _dates.push({ label: date, value: date });
    });
  intTimes &&
    intTimes.length &&
    intTimes.map((times) => {
      _times.push({ label: times.time, value: times.time });
    });

  const onChangeValues = (e) => {
    let { name, value } = e.target;
    setFieldValue(name, value);
    let data = {
      booking_date: name === "booking_date" ? value : values.booking_date,
      branch_id: branchID,
      no_of_guests: name === "no_of_guests" ? value : values.no_of_guests,
    };
    if (data.no_of_guests && data.booking_date) {
      dispatch(getInternalReservationTime(data));
    }
  };

  return (
    <div className="plr-35 w-1000">
      <form action="" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <SelectField
              name="booking_date"
              options={_dates}
              onChange={onChangeValues}
              label="Booking Date"
              value={values.booking_date}
              _label="label"
              _value="value"
              error={errors && errors.booking_date}
              touched={touched.booking_date}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="no_of_guests"
              label={`No of Guests (Max ${maxGuests} )`}
              placeholder="No of Guests"
              onChange={onChangeValues}
              value={values.no_of_guests}
              error={errors && errors.no_of_guests}
              touched={touched.no_of_guests}
            />
          </div>
          <div className="col-md-6">
            <SelectField
              name="booking_time"
              options={_times}
              onChange={handleChange}
              label="Booking Time"
              value={values.booking_time}
              _label="label"
              _value="value"
              error={errors && errors.booking_time}
              touched={touched.booking_time}
            />
          </div>

          <div className="col-md-6">
            <TextAreaField
              name="special_instructions"
              placeholder="Special Instruction"
              onChange={handleChange}
              value={values.special_instructions}
              error={errors && errors.special_instructions}
              touched={touched.special_instructions}
            />
          </div>

          <div className="col-md-6">
            <TextField
              name="customer_full_name"
              placeholder="Customer Full Name"
              onChange={handleChange}
              value={values.customer_full_name}
              error={errors && errors.customer_full_name}
              touched={touched.customer_full_name}
            />
          </div>

          <div className="col-md-6">
            <TextField
              name="customer_phone_number"
              placeholder="Customer Phone Number"
              onChange={handleChange}
              value={values.customer_phone_number}
              error={errors && errors.customer_phone_number}
              touched={touched.customer_phone_number}
            />
          </div>
          <div className="col-md-6">
            <button type="submit" className="btn-theme mt-10">
              {internalReservationCreate.processing ? (
                <GrowingLoader />
              ) : (
                `Submit`
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default InternalReservation;
