import {FoodsListState} from './FoodList-State'

export default function foodListReducer(state = FoodsListState, action) {

    let {data} = {...state};
    let {name, value, success } = action;

    switch (action.type) {

        case 'TOGGLE-FOOD-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }

                data.fields.map( field => {
                    if( field.field === value ) {
                        field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                    }
                } )
            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'FOOD-FILTER-OPEN-CLOSE':
            data.food_filter_open = !data.food_filter_open;
            return {...state, data};

        case 'SET-FOOD-SEARCH-RESET':
            data.params.q = ``;
            return {...state, data};

        case 'GET_FOOD_CATEGORIES_SORT_SUCCESS':
            data.food_categories_list = success?.data?.payload;
            return {...state, data};

        case 'SET-DEFAULT-FOOD-PARAMS':
            data.params = {
                limit: 10,
                page:  1,
                sort_by: 'desc',
                sort_column:  `id`,
                q: ``,
                food_type:``,//general
                category_id:``
            };
            return {...state, data};

        default:
            return state
    }
}
