export default function getReservationTimeslotsReducer
    (state = { error:null, success:null, processing:false }, action) {

    switch (action.type)
    {
        case 'GET_RESERVATION_TIMESLOTS_SUCCESS':
            return {...state, ...{success: action.success }};

        case 'GET_RESERVATION_TIMESLOTS_ERROR':
            return {...state, ...{ error: action.error }};

        case 'GET_RESERVATION_TIMESLOTS_PROCESSING':
            return {...state, ...{ processing: action.processing }};
        case 'GET_RESERVATION_TIMESLOTS_RESET':
            return { ...state, ...{
                    success: null,
                    error: null
                }
            };
        default: return state;
    }
}