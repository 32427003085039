import React from "react";
import {Link} from "react-router-dom";

const BranchHeader = () => {
    return (<div className="heading-block">

        <div className="title-block">
            <div className="title-block-icon">
                <span className="icon-branches"/>
            </div>
            <div className="title-block-content">
                <h2>Branches</h2>
                <span><Link to={'/'}>Home</Link></span>
                <span>Branches</span>
            </div>
        </div>
    </div>)
}

export default BranchHeader;