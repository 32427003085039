import axios from 'axios/index';
import {apiBaseUrl, urlParamValue} from '../../../../utils/URLUtils'
import { AppSource } from '../../../../Config';

function _success(success){
  return { type: 'FETCH_SHIPPING_METHODS_SUCCESS', success }
}
function _error(error){
  return { type:'FETCH_SHIPPING_METHODS_ERROR', error }
}
function _processing(processing){
  return { type: 'FETCH_SHIPPING_METHODS_PROCESSING', processing }
}

function getShippingMethods()
{
  return dispatch => {
    dispatch( _processing(true ));
    const config = {
      headers: {
        'x-app-source': AppSource.appSource
      }
    }
    axios.get( apiBaseUrl(`order/v1/public/shipping/method`), config)
      .then(res => {
        dispatch(_success(res));
        dispatch(_processing(false));

      }).catch(error => {
        dispatch(_error(error));
        dispatch(_processing(false));
        // handleError( error )

      })
  }
}

export default getShippingMethods;