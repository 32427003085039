import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";

const OrderSpecialInstruction = ({ orders, processing }) => {
    // let order = orders && orders.order;
    let order = orders;
    if(order && order.special_instruction)  {
    return (
        <div className="box-colored bg-light-gray">
            <h6>Special Instruction</h6>
            {processing ? <GrowingLoader/> : <p>{order && order.special_instruction }</p> }
        </div>
    ) } else{ return null}
}

export default OrderSpecialInstruction;