import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {ucFirstAll} from "../../../../utils/StringUtils";

const OrderCustomerDetails = ({orders, processing }) => {
    let order = orders && orders.customer;
    // let shipping_address = orders && orders.shipping_address;
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Customer Detail</h6>
            { processing ? <GrowingLoader /> :
             <>
                 <div className="single-info">
                     <div className="info-title">Full Name</div>
                     <div className="info-detail">{order && ucFirstAll(order.full_name)}</div>
                 </div>
                 {/*<div className="single-info">
                     <div className="info-title">Email</div>
                     <div className="info-detail">{order && order.email}</div>
                 </div>*/}
                 <div className="single-info">
                     <div className="info-title">Mobile Number</div>
                     <div className="info-detail">{order && order.mobile_no}</div>
                 </div>
                 <div className="single-info">
                     <div className="info-title">Country</div>
                     <div className="info-detail">{order && order.country}</div>
                 </div>
                 <div className="single-info">
                     <div className="info-title">Address</div>
                     <div className="info-detail">{order && ucFirstAll(order.address)}</div>
                 </div>
                 {/*<div className="single-info">
                     <div className="info-title">District</div>
                     <div className="info-detail">{shipping_address && shipping_address.district_name}</div>
                 </div>*/}

                 <div className="single-info">
                     <div className="info-title">City</div>
                     <div className="info-detail">{order && ucFirstAll(order.city)}</div>
                 </div>
             </>
            }
        </div>
    )
}

export default OrderCustomerDetails;