import React, {useEffect, memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBranchHeaderDetails} from "../../branches/Header/BranchSubHeader-Action";
import {useRouteMatch} from "react-router";
import FoodCategoriesDND from "./FoodCategoriesDND";
import sortFoodCategories from "../../../../ajax/actions/food-category/food_categories_sort_post";
import getFoodCategoriesSort from "../../../../ajax/actions/food-category/food_categories_sort_get";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {setIsList} from "../../branches/specific/BranchInfo-Action";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const FoodCategories = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {getRestaurantBranch, foodCategoriesSortGet, sortFoodCategoriesPost} = reduxState;
    let {url} = useRouteMatch();

    useTitle(Title.name + ` Food Category`);

    useEffect(() => {
        let details = {
            title: 'Food Categories',
            icon: 'foodcat',
            action: false,
            url: `${url}`
        }
        dispatch(setBranchHeaderDetails(details));
        dispatch( setIsList( false ) );
    }, [])

    useEffect(() => {
        sortFoodCategoriesPost.success && dispatch(getFoodCategoriesSort());
    }, [sortFoodCategoriesPost])

    useEffect(() => {
        getRestaurantBranch.success && dispatch(getFoodCategoriesSort());
    }, [getRestaurantBranch.success])

    function onDragEnd(results) {
        let branchID = getRestaurantBranch &&
            getRestaurantBranch.success &&
            getRestaurantBranch.success.data.data.id;
        if (!results) return;
        // dispatch( sortFoodCategories( results, results[0].branch_id ) )
        dispatch(sortFoodCategories(results, branchID))
    }

    let status = foodCategoriesSortGet?.success?.status;

    let list = foodCategoriesSortGet?.success?.data?.payload;

    let {processing} = sortFoodCategoriesPost;

    return (
        <>
            <div className={`col-sm-4 ${processing ? `item-disabled` : ``}`}>

                {foodCategoriesSortGet.processing ? <div className="text-center"> <GrowingLoader/> </div> :
                    status && list.length ?
                        <FoodCategoriesDND list={list} onDragEnd={res => onDragEnd(res)}/> :
                        <p>Food Categories not available</p>
                }
            </div>

        </>
    )
}

export default memo(FoodCategories);