import axios from 'axios';
import { apiBaseUrl } from '../../../utils/URLUtils'
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'GET_FOOD_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_FOOD_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_FOOD_PROCESSING', processing };
}

function getFood( branchId, foodId )
{
    return ( dispatch, getState ) =>
    {
        let {main} = getState();
        let {user_role} = main.data;
      let config = {
        headers: {
              'Authorization': 'Bearer ' + loginToken(),
             'x-app-source': AppSource.appSource
        },
      }
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${ branchId }/item/${foodId}`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/item/${foodId}`
        }
        // axios.get(apiBaseUrl(`restaurant/v1/admin/restaurant/${ branchId }/food/${foodId}`), config)
        axios.get(apiBaseUrl(url), config)
            .then(function(res){
                dispatch(_success(res));
            }).catch(function(error){
                dispatch(_error(error));
            });
    }
}
export function getFoodReset()
{
    return dispatch => dispatch({ type: `GET_FOOD_RESET` })
}

export default getFood;