import React from 'react'
import { isArray, isString } from "../../../utils/DataUtils";

const Div = ({ className, refer, children, onClick, id }) =>
{
  let _class  = ``;
  if ( className ) {
    if ( isString( className )) _class = className;
    if ( isArray( className )) className.map( item => _class += ` ${ item }`)
  }

  return (

    <div className={ className ? _class : null }
         onClick={ onClick ? onClick : null }
         id={id}
         ref={ refer }>
      { children }
    </div>

  )
};

export default Div