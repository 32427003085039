import React, { useState, useEffect} from 'react';
import BranchMenu from "../../../layout/Sidebar/BranchMenu";
import BranchSubHeader from "../Header/BranchSubHeader";
import getBranch from "../../../../ajax/actions/branch/restaurant_branch_get";
import {useDispatch, useSelector} from "react-redux";
import {useHistory,  useParams, useRouteMatch} from "react-router";
import {setRestaurantDetails} from "./BranchInfo-Action";
import PaginationButtons from "../../../common/buttons/PaginationButtons";
import {setFlagSpecificBranch, setMinimizeMenu} from "../../../layout/Main/Main-Actions";
import BranchAdminRoutes from "../../route/BranchAdminRoutes";
import {useTitle} from "react-use";
import {Title} from "../../../../Config";

const SpecificBranchDashboard = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {userDetails, getRestaurantBranch, branchInfoReducer} = reduxState;
    let parent_restaurantID = userDetails?.success?.restaurant_id;
    let {id} = useParams();
    let history = useHistory();
    let {url} = useRouteMatch();
    useTitle(Title.name + ` Branch`);
    useEffect( () => {
        dispatch(setFlagSpecificBranch(true));
        window.innerWidth > 1200 && dispatch( setMinimizeMenu( true ) );
        return () => {
            dispatch(setFlagSpecificBranch(false));
            dispatch(setMinimizeMenu(false))
        }
    }, [])

    useEffect(() => {
        // parent_restaurantID && dispatch(getBranch(id, parent_restaurantID))
        parent_restaurantID && dispatch(getBranch(id ))
    }, [parent_restaurantID]);

    useEffect(() => {
        dispatch(setRestaurantDetails())
    }, [getRestaurantBranch]);

    let is_list = branchInfoReducer && branchInfoReducer.data.is_list;
    let pagination_data = branchInfoReducer?.data?.pagination_data;
    let { pages, processing, fetchAction } = pagination_data;
    // console.log(` <| pagination_data |> `,pagination_data )
    return (
        <>
            <BranchSubHeader/>
            <div className="site-dashboard">
                <div className="row no-gutters">
                    <BranchMenu id={id} url={url}/>
                    <div className="col site-dashboard-wrapper">
                        <BranchAdminRoutes url={url} id={id} />
                    </div>
                </div>
            </div>
            { is_list ?
                <div className="fixed-footer branch-footer">
                    <PaginationButtons pages={  pages } processing={ processing } showFormerDots={true} fetchAction={fetchAction}/>
                </div> : null }
        </>
    )
}
export default SpecificBranchDashboard;