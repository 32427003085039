import { combineReducers } from 'redux'

import app from './components/layout/App/App-Reducers'
import routes from './components/modules/route/route-reducers'
import main from './components/layout/Main/Main-Reducers'

import userLogin from "./ajax/reducers/Oauth/login/login-post";
import userDetails from "./ajax/reducers/Oauth/user/user-detail-get";

import userUpdate from "./ajax/reducers/Oauth/user/user-put";
import accountForm from "./components/modules/account/AccountForm-Reducers"

import ordersGet from "./ajax/reducers/Orders/orders-get";
import orderGet from "./ajax/reducers/Orders/order-get";
import orderList from "./components/modules/order/OrderList-Reducer";

import restaurantGet from "./ajax/reducers/branch/restaurant-get";

import restaurantBranchList from "./ajax/reducers/branch/get-restaurant-branch-list";
import getRestaurantBranch from "./ajax/reducers/branch/restaurant-branch-get";
import branchList from "./components/modules/branches/BranchList-Reducer";
import branchInfoReducer from "./components/modules/branches/specific/BranchInfo-Reducer";
import branchSubHeaderReducer from "./components/modules/branches/Header/BranchSubHeader-Reducer";

import restaurantReviewsGet from "./ajax/reducers/review/restaurant-reviews-get";
import reviewList from "./components/modules/review/ReviewList-Reducer";

import getAddonsCategoriesReducer from "./ajax/reducers/addon-category/addon-categories-get";
import addonCategoryGet from "./ajax/reducers/addon-category/addon-category-get";
import addonCategoryCreate from "./ajax/reducers/addon-category/addon-category-post";
import addonCategoryUpdate from "./ajax/reducers/addon-category/addon-category-put";
import addonCategoryDelete from "./ajax/reducers/addon-category/addon-category-delete";
import addonCategorySortPost from "./ajax/reducers/addon-category/addon-category-sort-post";
import addonCategoryList from "./components/modules/addons-categories/list/AddonCategoryList-Reducer";
import addonCategoryForm from "./components/modules/addons-categories/form/AddonCategoryForm-Reducers";

import addonsGet from "./ajax/reducers/addon/addons-get";
import addonDelete from "./ajax/reducers/addon/addon-delete";
import addonCreate from "./ajax/reducers/addon/addon-post";
import addonUpdate from "./ajax/reducers/addon/addon-put";
import addonGet from "./ajax/reducers/addon/addon-get";
import addonList from "./components/modules/addons/list/AddonList-Reducer";
import addonForm from "./components/modules/addons/form/AddonForm-Reducers";

import foodsGet from "./ajax/reducers/foods/foods-get";
import foodsReviewGet from "./ajax/reducers/foods/foods-review-get";
import foodGet from "./ajax/reducers/foods/food-get";
import foodByCategoryGet from "./ajax/reducers/foods/foods-by-category-get";
import postSortFood from "./ajax/reducers/foods/sort-food-post";
import foodCreate from "./ajax/reducers/foods/food-post";
import foodUpdate from "./ajax/reducers/foods/food-put";
import foodDelete from "./ajax/reducers/foods/food-delete";
import foodList from "./components/modules/foods/list/FoodList-Reducer"
import foodForm from "./components/modules/foods/form/FoodForm-Reducers"

import foodCategoriesGet from "./ajax/reducers/food-category/food_categories-get";
import getFoodCategoriesOfCountryReducer from "./ajax/reducers/food-category/food_categories-of-country-get"
import foodCategoriesSortGet from "./ajax/reducers/food-category/food_categories-sort-get";
import sortFoodCategoriesPost from "./ajax/reducers/food-category/food-categories-sort-post";

import restaurantAdminDashboardStats from "./ajax/reducers/dashboard-statistics/restaurant-admin-dashboard-statistics-get";

import deliveryStatusesGet from "./ajax/reducers/Orders/delivery-status/delivery-statuses-get"

import orderStatusesGet from "./ajax/reducers/Orders/order-status/order-statuses-get";

import paymentStatusesGet from "./ajax/reducers/Orders/payment-status/payment-statuses-get";
import paymentMethodsGet from "./ajax/reducers/Orders/payment-method/payment-methods-get";
// import get_Users from './ajax/reducers/get_Users'
// import delete_User from './ajax/reducers/delete_User'

import salesReport from "./components/modules/report/sales/SalesReport-Reducer";
import salesReportGet from "./ajax/reducers/report/sales/sales-report-get";

import shippingMethodsGet from "./ajax/reducers/Orders/shipping-method/shipping-methods-get";

import restaurantBranchDashboardGet from "./ajax/reducers/dashboard-statistics/restaurant-branch-dashboard-get";

import reservationGet from "./ajax/reducers/reservations/reservation-get";
import reservationHistoryGet from "./ajax/reducers/reservations/reservation-history-get";
import reservationsGet from "./ajax/reducers/reservations/reservations-get";
import reservationTimeslotsGet from "./ajax/reducers/reservations/reservation-timeslots-get";
import reservationTimeslotDayGet from "./ajax/reducers/reservations/reservation-timeslot-day-get";
import reservationTimeslotHoursGet from "./ajax/reducers/reservations/reservation-timeslot-hours-get";
import reservationSettingGet from "./ajax/reducers/reservations/reservation-setting-get";
import reservationSettingUpdate from "./ajax/reducers/reservations/reservation-setting-post";
import reservationSettingCreate from "./ajax/reducers/reservations/reservation-setting-put";
import reservationDayTimeslotCreate from "./ajax/reducers/reservations/reservation-day-timeslot-post";
import reservationDayTimeslotUpdate from "./ajax/reducers/reservations/reservation-day-timeslot-put";
import reservationDayTimeslotStatusUpdate from "./ajax/reducers/reservations/reservation-day-timeslot-status-put";
import timeslotStatusUpdate from "./ajax/reducers/reservations/timeslot-status-put";
import reservationList from "./components/modules/reservations/ReservationList-Reducer";
import timeslotList from "./components/modules/reservations/timeslot/TimeslotList-Reducer";
import timeslotDay from "./components/modules/reservations/timeslot/day/TimeslotDay-Reducer";
import internalReservationTimeGet from "./ajax/reducers/reservations/internal-reservation/internal-reservation-time-get"
import internalReservationDateGet from "./ajax/reducers/reservations/internal-reservation/internal-reservation-date-get"
import internalReservationCreate from "./ajax/reducers/reservations/internal-reservation/internal-reservation-post"
import reservationScheduleGet from "./ajax/reducers/reservations/schedule/reservation-schedule-get";
import restaurantReviewReducerGet from "./ajax/reducers/review/restaurant-review-get";
import scheduleReducer from "./components/modules/reservations/schedule/Schedule-Reducer";
import updateReservationStatusReducer from "./ajax/reducers/reservations/reservation-status-put";
import updateOrderReducer from "./ajax/reducers/Orders/order-put";
import getOrderRejectStatusReducer from "./ajax/reducers/Orders/order-reject-status-get";
import getPendingOrdersReducer from "./ajax/reducers/Orders/orders-pending-get";
import faultReportReducer from "./components/modules/report/fault/FaultReport-Reducer";
import getFaultReportReducer from "./ajax/reducers/report/fault/fault-report-get";

import getPartnerCategoryUnMapReducer from './ajax/reducers/partner/category/partner-category-unmap-get';
import getPartnerCategoryMapReducer from './ajax/reducers/partner/category/partner-category-map-get';
import createPartnerCategoryMapReducer from './ajax/reducers/partner/category/partner-category-map-post';
import createPartnerCategoryUnMapReducer from './ajax/reducers/partner/category/partner-category-unmap-post';

import getPartnerIntegrationsReducer from './ajax/reducers/partner/integration/partner-integrations-get';
import createPartnerIntegrationReducer from './ajax/reducers/partner/integration/partner-integration-post';
import updatePartnerIntegrationReducer from './ajax/reducers/partner/integration/partner-integration-put';
import deletePartnerIntegrationReducer from './ajax/reducers/partner/integration/partner-integration-delete';
import indexPartnerIntegrationReducer from './ajax/reducers/partner/integration/partner-integration-index';
import partnerIntegrationListReducer from "./components/modules/partner/integration/list/PartnerIntegration-Reducer"
import PartnerIntegrationFormReducer from "./components/modules/partner/integration/form/PartnerIntegrationForm-Reducers"
import getPartnerWithTypeReducer from "./ajax/reducers/partner/integration/partner-with-type-get";
import getPartnerExternalBranchesReducer from "./ajax/reducers/partner/integration/partner-external-branches-get"
import getPartnerExternalGroupMenusReducer from "./ajax/reducers/partner/integration/partner-external-group-menus-get"
import getRestaurantCategoriesReducer from "./ajax/reducers/partner/category/restaurant-categories-get"

import getOrderPreparationTimeReducer from "./ajax/reducers/Orders/order-preparation-time-get"
export default combineReducers({

  app, routes, main,

  userLogin, userDetails,

  userUpdate, accountForm,

  orderList, ordersGet, orderGet, updateOrderReducer, getOrderRejectStatusReducer,

  restaurantGet,

  restaurantBranchList, branchList, getRestaurantBranch, branchInfoReducer, branchSubHeaderReducer,

  reviewList, restaurantReviewsGet, restaurantReviewReducerGet,

  addonCategoryForm, addonCategoryList, getAddonsCategoriesReducer, addonCategoryCreate, addonCategoryGet, addonCategoryUpdate, addonCategoryDelete, addonCategorySortPost,

  addonForm, addonList, addonsGet, addonDelete, addonCreate, addonUpdate, addonGet,

  foodForm, foodList,foodsGet, foodDelete, foodCreate, foodUpdate, foodGet, foodByCategoryGet, postSortFood,

  foodCategoriesGet, foodCategoriesSortGet, sortFoodCategoriesPost, getFoodCategoriesOfCountryReducer,

  restaurantAdminDashboardStats,

  deliveryStatusesGet,

  orderStatusesGet,

  paymentStatusesGet, paymentMethodsGet,

  salesReport, salesReportGet,

  shippingMethodsGet,

  restaurantBranchDashboardGet,

  reservationGet, reservationsGet, reservationList, reservationHistoryGet,reservationTimeslotsGet, updateReservationStatusReducer,
  reservationTimeslotDayGet, timeslotList, reservationSettingGet, reservationSettingUpdate,
  reservationSettingCreate, timeslotStatusUpdate, timeslotDay, reservationDayTimeslotCreate,
  reservationDayTimeslotUpdate, reservationDayTimeslotStatusUpdate, reservationTimeslotHoursGet,

  internalReservationTimeGet,internalReservationDateGet, internalReservationCreate, reservationScheduleGet,scheduleReducer,

  getPendingOrdersReducer,

  faultReportReducer, getFaultReportReducer,

  getPartnerCategoryUnMapReducer, getPartnerCategoryMapReducer, createPartnerCategoryMapReducer, createPartnerCategoryUnMapReducer,

  getPartnerIntegrationsReducer, createPartnerIntegrationReducer, updatePartnerIntegrationReducer, deletePartnerIntegrationReducer, indexPartnerIntegrationReducer, partnerIntegrationListReducer,
  PartnerIntegrationFormReducer, getPartnerWithTypeReducer, getPartnerExternalBranchesReducer, getPartnerExternalGroupMenusReducer,
  getRestaurantCategoriesReducer,
  getOrderPreparationTimeReducer, foodsReviewGet
})