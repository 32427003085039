import React from "react";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {ucFirstAll} from "../../../../utils/StringUtils";

const ReservationCustomerDetails = ({reservation, processing }) => {
    let order = reservation && reservation.user_detail;
    return (
        <div className="box-colored bg-light-gray pb-10">
            <h6>Customer Detail</h6>
            { processing ? <GrowingLoader /> : <>
                <div className="single-info">
                    <div className="info-title">Full Name</div>
                    <div className="info-detail">{reservation && ucFirstAll(reservation.customer_full_name)}</div>
                </div>
                <div className="single-info">
                    <div className="info-title">Email</div>
                    <div className="info-detail">{order && order.email}</div>
                </div>
                <div className="single-info">
                    <div className="info-title">Mobile Number</div>
                    <div className="info-detail">{reservation && reservation.customer_phone_number}</div>
                </div>

                {order && order.address_line_1 ? <div className="single-info">
                    <div className="info-title">Address</div>
                    <div className="info-detail">{order && order.address_line_1}</div>
                </div> : null }
                {order && order.city ? <div className="single-info">
                    <div className="info-title">City</div>
                    <div className="info-detail">{order && order.city}</div>
                </div> :null}
            </>}
        </div>
    )
}

export default ReservationCustomerDetails;