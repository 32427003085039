export function setAccountForm( details )  {
    return function (dispatch) {
        // console.log(` <| details |> `, details)
        dispatch({type: `SET-ACCOUNT-DETAILS`, user:details});
    }
}

export function changePasswordFormVisible(  )  {
    return function (dispatch) {
        dispatch({type: `SET-CHANGE-PASSWORD-VISIBLE`});
    }
}