export const toggleSidebar = () => dispatch => dispatch({ type: `TOGGLE-SIDEBAR` })
export const toggleFilterSidebar = mode  => dispatch => dispatch({ type: `TOGGLE-FILTER-SIDEBAR`, mode })
export const setFlagSpecificBranch = flag  => dispatch => dispatch({ type: `SET-FLAG-SPECIFIC-BRANCH`, flag })
export function setUserRole( ) {
    return function ( dispatch, getState ) {
        let { userDetails } = getState();
        let role = userDetails && userDetails.success && userDetails.success.roles;
        let role_name = role && role[0].name;
        dispatch({type: `SET-USER-ROLE`, role:role_name});
    }
}

export function setMinimizeMenu( bool ) {
    return function ( dispatch, getState ) {
        dispatch({type: `SET-MINIMIZE-MENU`, bool});
    }
}