export const FoodFormState = {
    data: {
        translation:{},
        status:``,
        price:``,
        calorie:``,
        veg: '',
        is_in_house:'',
        gallery: [],
        out_of_stock: '',
        trans:null,
        addon_categories:[],
        selected_addon_categories:[],
        food_categories:[],
        selected_food_categories:[],
        restaurantID:``,
        parentID:``,
        food_size:[],
        size_index:0,
        featured_image: '',
        selected_featured_image: '',
        size_translation:{},
        count_food_size:0,
        food_size_edit_mode:true,
        food_type:`normal`,
        manage_inventory:``,
        qty:``,
        inventory:``,
        discount:``,
        breakfast_menu:``,
        meta:{
            allergens:``,
            nutritional_info:``,
        },
        preparation_time:0
    },

}