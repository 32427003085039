import React from 'react';
import Modal from "react-modal"
import {useDispatch, useSelector} from "react-redux";
import {toggleModalOpenCLose} from "../ReviewList-Action";
import Tags from "../../../common/Tags/Tags";
import useAppState from "../../../../hooks/useAppState";
import {ucFirst, ucFirstAll} from "../../../../utils/StringUtils";

const customStyles = {
    content : {
        width: `700px`,
        border:'none',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',

    }
};

const ReviewDetails = () => {
    let dispatch = useDispatch();
    function modalOpenClose() {
        dispatch( toggleModalOpenCLose() )
    }
    const restaurantReviewReducerGet = useAppState(s => s.restaurantReviewReducerGet );
    const reviewList = useAppState(s => s.reviewList );
    let { review_modal_open, details } = reviewList && reviewList.data;
    return (
        <Modal
            isOpen={review_modal_open}
            style={customStyles}
            onRequestClose={modalOpenClose}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Review Detail</h5>
                        <button type="button" className="close" onClick={() => modalOpenClose()}>
                            <span aria-hidden="true" className="icon-close"/>
                        </button>
                    </div>
                    <div className="modal-body pb-0">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="single-info">
                                    <div className="info-title">Restaurant Name</div>
                                    <div className="info-detail">{details && ucFirstAll(details.branch_name)}</div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-info">
                                    <div className="info-title">Order Id</div>
                                    <div className="info-detail">{details && details.order_id}</div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-info">
                                    <div className="info-title">Rating</div>
                                    <div className="info-detail">{details && details.rating}</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-info">
                                    <div className="info-title">Food Review</div>
                                    <div className="info-detail">{details && details.review}</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-info">
                                    <div className="info-title">Status</div>
                                    <div className="info-detail text-danger">
                                        { details && <Tags status={details && details.status}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ReviewDetails;