import React from "react";
import {useDispatch} from "react-redux";
import {toggleScheduleFilterOpen} from "./Schedule-Action";

const ScheduleHeader = () => {
    let dispatch = useDispatch();
    const toggleFilter = () => {
        dispatch(toggleScheduleFilterOpen())
    }

    return (

        <div className="button-wrapper">
            <a href="javascript:void(0)"
               onClick={toggleFilter}
               className="btn-theme" id="btn-filter">Filters</a>
        </div>
    )
}

export default ScheduleHeader;