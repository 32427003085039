import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import TextField from "../../common/form/TextField";
import SearchTextField from "../../common/form/SearchTextField";

const CommonHeader = (params) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const reduxState = useSelector(state => state);
    const { branchSubHeaderReducer, foodList } = reduxState;
    let { linkUrl } = branchSubHeaderReducer.data;
    return (
        <>
            {/*<SearchTextField name="q"
                       placeholder="Search"
                       label="Search"
                       noLabel
                       onReset={ () => console.log(` <| reset |> `, ) }
                       value={params.param.q}
                       onChange={params.toggleListFilter}/>*/}
            <div className="ml-2 mr-2 button-wrapper">
                <Link to={`${linkUrl}/create`} className="btn-theme">Create New</Link>
            </div>
            <div className="button-wrapper">
                <a href="javascript:void(0)" onClick={params.toggleFilter} className="btn-theme" id="btn-filter">Filters</a>
            </div>
        </>
    )
}

export default CommonHeader;