export default function deletePartnerIntegrationReducer
    (state = { error:null, success:null, processing:false }, action) {

    switch (action.type)
    {
        case 'DELETE_PARTNER_INTEGRATION_SUCCESS':
            return {...state, ...{success: action.success }};

        case 'DELETE_PARTNER_INTEGRATION_ERROR':
            return {...state, ...{ error: action.error }};

        case 'DELETE_PARTNER_INTEGRATION_PROCESSING':
            return {...state, ...{ processing: action.processing }};

        default: return state;
    }
}