export default function sortFoodCategoriesReducer
  (state = { error:'', success:'', processing:false}, action)
{
    const { processing, success, error } = action;
    switch (action.type) {
        case 'SORT_FOOD_CATEGORIES_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'SORT_FOOD_CATEGORIES_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'SORT_FOOD_CATEGORIES_PROCESSING':
            return {...state, ...{ processing}};

        default: return state;
    }
}