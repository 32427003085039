import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../common/Tags/Tags";
import {fullDate12Hrs} from "../../../utils/DateTimeUtils";
import {useDispatch} from "react-redux";
import {toggleModalOpenCLose, viewReviewDetails} from "./ReviewList-Action";
import ReactTooltip from "react-tooltip";
import getRestaurantReview from "../../../ajax/actions/review/restaurant_review_get";

const ReviewListItem = ({review}) => {
    const {order_id, created_at_formatted, branch_name, rating, status, branch_id} = review;

    let dispatch = useDispatch();

    function modalOpenClose(review) {
        dispatch(toggleModalOpenCLose());
        dispatch( getRestaurantReview( review.id ) )
        // dispatch(viewReviewDetails(review))
    }

    return (
        <tr>
            <td><Link to={`/order/${order_id}/view`}>{order_id}</Link></td>
            <td>{fullDate12Hrs(created_at_formatted)}</td>
            <td><Link to={`/branches/${branch_id}/info`}>{branch_name}</Link></td>
            <td>{rating}</td>
            <td><Tags status={status}/></td>
            <td className="buttons-block">
                {/*<div className="dropdown">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a href="javascript:void(0)"
                           onClick={() => modalOpenClose(review)}> <i className="fa-eye" data-tip="View"/></a>
                    </div>
                </div>*/}
                <a href="javascript:void(0)"
                   onClick={() => modalOpenClose(review)}> <i className="fa-eye" data-tip="View"/></a>
            </td>
            <ReactTooltip backgroundColor="#BF9F60"/>
        </tr>

    )
};

export default memo(ReviewListItem);