import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import getRestaurantBranchList from "../../../ajax/actions/branch/get_restaurant_branch_list";
import BranchHeader from "./BranchHeader";
import Table from "../../common/table/Table";
import BranchListItem from "./BranchListItem";
import PaginationButtons from "../../common/buttons/PaginationButtons";
import {toggleBranchListFilter} from "./BranchList-Action";
import TableDataLoader from "../../common/loaders/TableDataLoader";
import {handleError} from "../../../utils/ErrorUtils";
import {useTitle} from "react-use";
import {Title} from "../../../Config";

const Branches = () => {
    const reduxState = useSelector(state => state);
    const {userDetails, restaurantBranchList, branchList} = reduxState;
    let parent_restaurantID = userDetails?.success?.restaurant_id;
    const {success} = restaurantBranchList;
    const list = success?.data?.data;
    let current_page = success?.data?.meta?.current_page;
    let last_page = success?.data?.meta?.last_page;
    let total = success?.data?.meta?.total;
    let pages = {current_page, last_page, total};

    let {fields, params} = branchList && branchList.data;

    useTitle(Title.name + ` Branches`);

    const dispatch = useDispatch();
    useEffect(() => {
        parent_restaurantID && dispatch(getRestaurantBranchList(parent_restaurantID))
    }, [parent_restaurantID])

    useEffect(() => {
        const {error} = restaurantBranchList;
        if (error) {
            handleError(error);
        }
    }, [restaurantBranchList.error]);

    return (
        <>
            <BranchHeader/>
            <div className="table-block table-responsive pl-50">
                <Table reducer={restaurantBranchList}
                       params={params}
                       action={toggleBranchListFilter}
                       columns={fields}>
                    <tbody>
                        {restaurantBranchList.processing ? <TableDataLoader colspan={7}/> : list && list.map(branch => {
                                return (
                                    <BranchListItem key={branch.id} branch={branch}/>
                                )
                            }
                        )}
                    </tbody>
                </Table>
            </div>
            {list && list.length ? (
                <div className="fixed-footer">
                    <PaginationButtons pages={pages}
                                       fetchAction={toggleBranchListFilter}
                                       showFormerDots
                                       processing={restaurantBranchList && restaurantBranchList.processing}/>
                </div>
            ) : null}
        </>
    )
}

export default Branches;