export default function deleteFoodReducer
    (state = { error:null, success:null, processing:false }, action) {
    const { type, processing, success, error, id } = action;
    switch (action.type)
    {
        case 'FOOD_DELETE_SUCCESS':
            return { ...state, ...{ success, processing: false, error: false }};

        case 'FOOD_DELETE_ERROR':
            return { ...state, ...{ error, processing: false }};

        case 'FOOD_DELETE_PROCESSING':
            return { ...state, ...{ processing, id  }};

        default: return state;
    }
}