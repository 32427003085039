import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import OrderHeader from "../../order/OrderHeader";
import SalesReportHeader from "../../report/sales/SalesHeader";
import {toggleFoodFilterOpen} from "../../foods/list/FoodList-Action";
import FoodHeader from "../../foods/list/FoodHeader";
import AddonHeader from "../../addons/list/AddonHeader";
import AddonCategoryHeader from "../../addons-categories/list/AddonCategoryHeader";
import CommonHeader from "../../common-filter-header/CommonHeader";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import ScheduleHeader from "../../reservations/schedule/ScheduleHeader";

const BranchSubHeader = () => {
    const reduxState = useSelector(state => state);
    const {branchInfoReducer, branchSubHeaderReducer, userDetails, getRestaurantBranch} = reduxState;

    let restaurant_name = ``;
    let translation = branchInfoReducer && branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.translation;
    let branch_id = branchInfoReducer && branchInfoReducer.data && branchInfoReducer.data.details && branchInfoReducer.data.details.id;
    translation && translation.length && translation.map(t => {
        if (t.lang_code === 'en') {
            restaurant_name = t.name
        }
    })

    let {url} = useRouteMatch();
    let role = userDetails && userDetails.success && userDetails.success.roles[0].name;
    // console.log(` <| role |> `, role )
    // console.log(` <| branchInfoReducer url |> `, branchInfoReducer )

    let { title, icon, action, slug, linkUrl, id, filter,processing } = branchSubHeaderReducer.data;
    if (role === 'branch-admin') {
        url = `/branches/${branch_id}`;
    }

    return (<div className="heading-block">

        <div className="title-block">
            <div className="title-block-icon">
                <span className={`icon-${icon}`}/>
            </div>
            <div className="title-block-content">
                <h2>{ getRestaurantBranch.processing ? <GrowingLoader /> : title || restaurant_name}</h2>
                {/*<span><Link to={`/branches`}>Branches</Link></span>*/}
                <span>Branches</span>
                <span><Link to={`${url}/info`}>{getRestaurantBranch.processing ? <GrowingLoader />: restaurant_name}</Link></span>
                {title ? <span>{id ? <Link to={urlTrailWithoutLastItem()}>{title}</Link> : title}</span> : null}
                {id ? <span># {id}</span> : null}
            </div>
        </div>

        {title === `Orders` ? (<OrderHeader orderFilterMode="branch"/>) : null}
        {title === `Sales Report` ? (<SalesReportHeader filterMode="branch"/>) : null}
        {/*{action ?
            <div className="mr-2 button-wrapper">
                <Link to={`${linkUrl}/create`} className="btn-theme">Create New</Link>
            </div>
            : null
        }*/}
        {title === `Foods` ? (<FoodHeader filterMode="branch"/>) : null}
        {title === `Reservation Schedule` ? (<ScheduleHeader filterMode="branch"/>) : null}
        {title === `Addons` ? (<AddonHeader filterMode="branch"/>) : null}
        {title === `Addons Category` ? (<AddonCategoryHeader filterMode="branch"/>) : null}
        {/*{ title === `Foods` || title === `Addons` || title === `Addons Category` ? <CommonHeader/> : null }*/}

    </div>)
}

export default BranchSubHeader;