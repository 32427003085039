import React, {useEffect} from "react";
import DateRangeFieldNew from "../../../common/form/DateRangeFieldNew";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {
    toggleSalesReportDateChange,
    toggleSalesReportFilterSidebar,
} from "./SalesReport-Action";
import {useHistory} from "react-router";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";
import {apiBaseUrl} from "../../../../utils/URLUtils";
import useAppState from "../../../../hooks/useAppState";

const SalesReportHeader = ({ filterMode }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const salesReport = useAppState( s => s.salesReport )
    const userDetails = useAppState( s => s.userDetails )
    const {params} = salesReport && salesReport.data;
    const country_id = userDetails?.success?.country_id;
    const restaurant_id = userDetails?.success?.restaurant_id;
    const role = userDetails?.success?.roles?.[0].name;

    const toggleFilter = () => {
        dispatch( toggleSalesReportFilterSidebar( filterMode ) )
    }
    const handleEvent = (event, picker) => {
        let start_date = moment(picker.startDate).format('YYYY-MM-DD');
        let end_date = moment(picker.endDate).format('YYYY-MM-DD');
        // console.log(` <| start date |> `,moment(picker.startDate).format('YYYY-MM-DD') )
        // console.log(` <| end date |> `,moment(picker.endDate).format('YYYY-MM-DD') )
        dispatch(toggleSalesReportDateChange( start_date, end_date, history ))
    }

    const dateApply = (event, picker ) => {
        dispatch( getSalesReport( params ) )
    }
    let downloadReportUrl = apiBaseUrl(`order/v1/export-csv/${params.country_id || country_id }?customer_info=${params.customer_info}&city_id=${ params.city_id }&branch_id=${params.branch_id}&restaurant_id=${restaurant_id}&start_date=${params.start_date}&end_date=${params.end_date}&order_status=${params.order_status}&delivery_method=${params.delivery_method}&delivery_status=${params.delivery_status}&payment_method=${params.payment_method}&payment_status=${params.payment_status}&access_token=${localStorage.getItem('Bearer')}`);
    if( role === 'branch-admin' ) {
        downloadReportUrl = apiBaseUrl(`order/v1/export-csv/${params.country_id || country_id }?customer_info=${params.customer_info}&city_id=${ params.city_id }&branch_id=${params.branch_id}&start_date=${params.start_date}&end_date=${params.end_date}&order_status=${params.order_status}&delivery_method=${params.delivery_method}&delivery_status=${params.delivery_status}&payment_method=${params.payment_method}&payment_status=${params.payment_status}&access_token=${localStorage.getItem('Bearer')}`);
    }

    return (<>
        <div className="calendar-block">
            {/*<input type="text" name="" className="form-control" id="datepick"/>*/}
            <DateRangeFieldNew handleEvent={handleEvent} enddate={params.end_date} startdate={params.start_date} onApply = {dateApply}/>
            <span className="icon-calendar"/>
        </div>
        <div className="button-wrapper mr-5">
            <a href={downloadReportUrl} target="_blank" className="btn-theme" id="btn-filter">Download</a>
        </div>
        <div className="button-wrapper">
            <a href="javascript:void(0)" onClick={toggleFilter} className="btn-theme" id="btn-filter">Filters</a>
        </div>
    </>)
}

export default SalesReportHeader;