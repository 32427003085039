import axios from 'axios';
import { apiBaseUrl, urlParamValue } from '../../../../utils/URLUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'GET_FAULT_REPORTS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_FAULT_REPORTS_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_FAULT_REPORTS_PROCESSING', processing };
}

export function getFaultReportStats( filters ) {
    return dispatch => {

        dispatch(_processing(true));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`),
                'x-app-source': AppSource.appSource
            },
            params: {
                // country_id:filters.country_id || 1,
                start_date: filters.start_date || null,
                end_date: filters.end_date || null,
                branch_id: filters.branch_id || null,
                // restaurant_id:filters.restaurant_id || null,
            }
        };
        if( config.params.branch_id ) {
            delete config.params.restaurant_id;
        }
        let url =apiBaseUrl(`order/v1/fault/report/stats`);
        axios.get(url, config)
            .then(function(response){
                dispatch(_processing(false));
                dispatch(_success(response));

            }).catch(function(error){
                dispatch(_processing(false));
                dispatch(_error(error));

            });
    }
}

export default getFaultReportStats;
