import React from 'react';
import { names, ucFirst, unslufigy } from "../../../utils/StringUtils";
import Div from "../Div/Div";
import "./custom-form.css"
const TextAreaField = ({ name, value, onChange, id, label, touched, noLabel,
                         disabled, className, error, placeholder }) =>
{
  const hasError = error && touched;

  const setPlaceholder = () =>
  {
    if ( placeholder ) return placeholder
    if ( label ) return label
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
        return _label;
      }
    }
  }

  const Label = () =>
  {
    if ( label ) return (
      <Div className={ names(`label-text`) }>
        { label } { hasError ? error + '.' : null }
      </Div>
    );
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
        return (
          <Div className={ names(`label-text`) }>
            { _label } { hasError ? error + '.' : null }
          </Div>
        )
      }
    }
    return null
  }

  return (

    <Div className={ names(`form-group`, hasError && `has-error`) }>

      { !noLabel ? <label><Label /></label> : null }

      <textarea name={ name }
                value={ value }
                id={ id || name }
                onChange={ onChange }
                disabled={ !!disabled }
                placeholder={ setPlaceholder() }
                className={ names(`form-control`, className ) }
      />

    </Div>
  )

}
export default TextAreaField;