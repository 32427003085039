import axios from 'axios/index';
import { apiBaseUrl } from '../../../../utils/URLUtils'
import { loginToken } from '../../../../utils/LocalStorageUtils'
import { handleError } from '../../../../utils/ErrorUtils'
import { AppSource } from '../../../../Config';

function _success(success)
{
    return { type: 'GET_PARTNER_CATEGORY_UNMAP_SUCCESS', success };
}
function _error(error)
{
    return { type: 'GET_PARTNER_CATEGORY_UNMAP_ERROR', error };
}
function _processing(processing)
{
    return { type: 'GET_PARTNER_CATEGORY_UNMAP_PROCESSING', processing };
}

function getPartnerCategoryUnMap( )
{
    return ( dispatch ) =>
    {
      dispatch(_processing(true));
      dispatch(_success(null));
      dispatch(_error(null));

      let config = {
        headers: {
              'Authorization': 'Bearer ' + loginToken(),
            'x-app-source': AppSource.appSource
        },
      }
        axios.get(apiBaseUrl(`vendor/v3/vendor/partner/categories/unmapped`), config)
            .then(function(res){
                dispatch(_success(res));
            }).catch(function(error){
                handleError(error);
                dispatch(_error(error));
            });
    }
}
export function getPartnerCategoryUnMapReset()
{
    return dispatch => dispatch({ type: `GET_PARTNER_CATEGORY_UNMAP_RESET` })
}

export default getPartnerCategoryUnMap;