import {ReviewListState} from './ReviewList-State'

export default function reviewListReducer(state = ReviewListState, action) {

    let {data} = {...state};
    let {name, value, review, success} = action;

    switch (action.type) {

        case 'TOGGLE-REVIEW-LIST-FILTER':
            data.params[name] = value;
            if (name === `sort_column`) {
                if (data.params.sort_column === value) {
                    data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
                }
            }

            data.fields.map( field => {
                if( field.field === value ) {
                    field['sort_type'] = field['sort_type'] === `desc` ? `asc` : `desc`
                }
            } )

            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data};

        case 'TOGGLE-REVIEW-MODAL':
            data.review_modal_open = !data.review_modal_open;
            return {...state, data};

        case 'VIEW-REVIEW-DETAILS':
            data.details = review;
            return {...state, data};

        case 'GET_RESTAURANT_REVIEW_SUCCESS':
            data.details = success && success.data && success.data.data;
            return {...state, data};

        default:
            return state
    }
}
