import {clearBuildUrls, toggleURLQuery, urlParamValue} from "../../../../utils/URLUtils";
import getSalesReport from "../../../../ajax/actions/report/sales/sales_report_get";

export function toggleSalesReportFilter(e, history) {

    return function ( dispatch, getState ) {
        let { salesReport } = getState();
        let { params } = salesReport && salesReport.data;
        let {name, value, sort} = e.target;
        if( name === 'sort_column'  ) {
            toggleURLQuery( name, value , history)
            toggleURLQuery( 'sort_by', sort , history)
        }else{
            toggleURLQuery( name, value , history)
        }
        dispatch({type: 'TOGGLE-SALES-REPORT-FILTER', name, value});
    }
}

export function onSalesReportDateChange(e) {

    return function ( dispatch ) {

        let {name, value} = e.target;
        dispatch({type: 'ON-SALES-REPORT-DATE-CHANGE', name, value});
    }
}

export function toggleSalesReportDateChange(start_date, end_date, history) {
    return function( dispatch, getState  ) {
        let { salesReport, restaurantBranchList } = getState();
        let { params } = salesReport && salesReport.data;
        toggleURLQuery( 'start_date', start_date, history );
        toggleURLQuery( 'end_date', end_date, history );
        // let { name, value } = e.target;
        let country_id = restaurantBranchList && restaurantBranchList.success && restaurantBranchList.success.data.data[0].country_id;
        dispatch({type:'TOGGLE-SALES-REPORT-DATE-CHANGE', start_date, end_date});
        dispatch( getSalesReport( params,country_id  ) )
    }
}

export function setDefaultSalesReportParams( ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-DEFAULT-SALES-REPORT-PARAMS', });
    }
}

export function setBranchIDForReport( branch_id ) {
    return function ( dispatch,getState ) {
        let { salesReport,restaurantBranchList } = getState();
        let { params } = salesReport && salesReport.data;
        let country_id = restaurantBranchList && restaurantBranchList.success && restaurantBranchList.success.data.data[0].country_id;
        dispatch({type: 'SET-BRANCH-ID-FOR-REPORT',branch_id });
        dispatch( getSalesReport( params,country_id  ) )
    }
}

export function setBranchIDForMonthlyReport( branch_id ) {
    return function ( dispatch ) {
        dispatch({type: 'SET-BRANCH-ID-FOR-MONTHLY-REPORT',branch_id });
    }
}

// export function setCountryID( countryID ) {
//     return function ( dispatch,) {
//         dispatch({type: 'SET-COUNTRY-ID',countryID });
//     }
// }
export function setParentRestaurantIDForReport( parentID ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-PARENT-RESTAURANT-ID-FOR-REPORT',parentID });
    }
}

export function toggleSalesReportFilterSidebar( mode ) {
    // console.log(` <| mode |> `,mode )
    return function ( dispatch,) {
        dispatch({type: 'TOGGLE-SALES-REPORT-FILTER-SIDER', mode });
    }
}
export function setCountryIDForReport( countryID ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-COUNTRY-ID-FOR-REPORT', countryID });
    }
}

export function openCloseSalesReportFilterSidebar(  ) {
    return function ( dispatch,) {
        dispatch({type: 'OPEN-CLOSE-SALES-REPORT-FILTER-SIDER', });
    }
}

export function setBranchOrderParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-BRANCH-ORDER-PARAMS', param:params });
    }
}

export function setRestaurantOrderParams( params ) {
    return function ( dispatch,) {
        dispatch({type: 'SET-RESTAURANT-ORDER-PARAMS', param:params });
    }
}